import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
function Articles12() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The Limitations of Digitization Against Cost Efficiency - Powerabode</title>
                <meta
                    name="description"
                    content="Digitisation is key, but good old human intelligence is still key in defining the strategy. Artificial Intelligence is quickly gaining pace, but..."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles12-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Strategic Sourcing is not Sourcing Strategy"
                    boxTitleLabel="Article >"
                    title="sourcing-strategy"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        The Limits of Digitisation
                                    </h1>
                                    <h3 className="section-sub-title mt-1 text-center">
                                        Set your route before you start your journey. Set your strategy
                                        before procurement.
                                    </h3>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Strategic sourcing is the action of buying. Software can be of great help in this process.</p>
                                                        <p>
                                                            Sourcing strategy is the thought behind the buying. Part can be heavily supported by digital
                                                            means; a large part however requires expert involvement.
                                                        </p>
                                                        <p>
                                                            Digitisation in procurement is becoming the new normal. Various procurement tools available
                                                            in the market aim at digitising the strategic sourcing part.
                                                        </p>
                                                        <p>
                                                            In all the focus on digitisation, less attention is given to a very decisive element that requires
                                                            human (expert) input; formulating the actual Sourcing Strategy. This incorporates Business
                                                            planning, asset requirements, category strategy, the functional details, output requirement,
                                                            should costing, delivery parameters, asset lifetime, KPIs etc.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>
                                                            <span className="theme-font">powerabode</span> brings cutting edge Category and Contracting (Sourcing) Strategy to the Energy
                                                            Industry. Business plans and delivery requires human input. Experience, and foresights defines
                                                            the Strategy and how to make it work in the field. The reality of today is that Strategy
                                                            development cannot (yet) be automated.
                                                        </p>
                                                        <h5 className="heading">
                                                            True Case Study on Strategy Development:
                                                        </h5>
                                                        <p>
                                                            An Onshore Gas Asset’s business objective was to shift from one off lowest cost Capex to ‘a
                                                            profitable production for next 20 years with Low and Sustainable Production Cost and Operating
                                                            Cost (OPEX)’. Previous projects delivered for lowest cost CAPEX resulted in substantially high
                                                            OPEX. This led to a marginalised profit for the production from that gas field.
                                                        </p>

                                                        <h5 className="heading">
                                                            The Approach:
                                                        </h5>
                                                        <p>
                                                            As part of the strategic dialogue, a Contracting Strategy workshop was designed to identify key
                                                            items that drove Operating Cost. 18 points were identified.
                                                        </p>
                                                        <p>
                                                            Costing models were simulated to conduct visual sensitivity check. All parameters were played
                                                            with at multiple variances and results were used to prepare an Evaluation Model. This Evaluation
                                                            Model helped to make the right procurement decision.
                                                        </p>
                                                    <p>
                                                            The Extended Contract Management Team (ECMT) was in the room, representing every
                                                            department that influenced the Contract throughout its lifetime.
                                                    </p>

                                                        <h5 className="heading">
                                                            Result:
                                                        </h5>
                                                        <p>
                                                            A Contracting Strategy designed to deliver ‘profitable production for next 20 years with Low and
                                                            Sustainable Production Cost and Operating Cost (OPEX).
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            Strategy included the following:
                                                        </h5>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">A dual layered evaluation model</li>
                                                            <li className="list-item">A pay for performance remuneration model</li>
                                                            <li className="list-item">Contract Management Plan; to deliver savings and value as per tender forecast</li>
                                                        </ul>

                                                        <p>
                                                            The Contract was awarded based on Lowest Total Cost and Total Value of Ownership (TCO and TVO).
                                                        </p>
                                                        <p>
                                                            Our Program The Road to Cost Leadership is a joint journey we travel with the client.Collaboration, hands-on frontline experience, the transfer of knowledge. A bespoke program will
                                                            prepare your teams to deliver cost efficiency, using the Cost Intelligence principles.
                                                        </p>
                                                        <p>
                                                            For delivery our Supply Chain by SLA services all contribute to deliver cost efficiency. It is the
                                                            home for category management, contracting & procurement and supply chain challenges. For
                                                            Operations, Maintenance and Projects.
                                                        </p>
                                                        <p>
                                                            <span className="theme-font">powerabode</span> Academy allows your own people to also further their skills in delivering cost
                                                            efficiency. We train the full extended contract management team, but also project management,
                                                            category management and supply chain & procurement specialists.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles12;