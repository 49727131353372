import React from 'react';
import { Image } from 'react-bootstrap';
import "./whatsappStyle.css";
import WhatsAppLogo from '../../Images/whatsapp/WhatsApp.png';
import ReactWhatsapp from 'react-whatsapp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { useState } from "react";
import Logo from '../../Images/logo/p-logo.png';

function WhatsAppChat(props) {
    const [whatsAppWrap, setWhatsAppWrap] = useState(false);

    // Local real time - START 
    const locale = 'en';
    const [today, setDate] = useState(new Date()); 
    React.useEffect(() => {
        const timer = setInterval(() => { 
            setDate(new Date());
        }, 60 * 1000);
        return () => {
            clearInterval(timer); 
        }
    }, []);
    const time = today.toLocaleTimeString(locale, { hour: 'numeric', hour12: true, minute: 'numeric' });
    // Local real time - END

    return (
        <div className='whatsApp-chatBot-wrapper'>
            <div className="whatsApp-btn-wrapper" onClick={() => setWhatsAppWrap(!whatsAppWrap)}>
                <Image
                    className='whatsApp-logo'
                    src={WhatsAppLogo}
                    alt=''
                />
            </div>
            {whatsAppWrap && (
                <div className="whatsApp-chatBot-content">
                    <div className="wcc-header d-flex gap-2">
                        <div className="wcc-h-img">
                            <Image
                                src={Logo}
                                alt=""
                            />
                        </div>
                        <div className="wcc-h-text">
                            <h5 className='text-white'><span className="theme-font text-white">powerabode.com</span></h5>
                            <p>Typically replies within an hour</p>
                        </div>
                        <div className='wcc-h-close' onClick={() => setWhatsAppWrap(false)}>
                            <FontAwesomeIcon icon={faXmarkCircle} />
                        </div>
                    </div>

                    <div className="wcc-body">
                        <div className="wcc-b-box-left arrow-left">
                            <p className='mb-0'>
                                <b className='mb-3'><span className="theme-font">powerabode.com</span></b> <br />
                                Hi, there 👋
                                <br /><br />
                                How can I help you?
                            </p>
                            <span className="time text-right d-block">{time}</span>
                        </div>
                    </div>
                    
                    <div className="wcc-footer">
                        <ReactWhatsapp 
                            className="wcc-start-chat"
                            number="+971551571994"
                        >
                            <FontAwesomeIcon icon={faWhatsapp}/> Start Chat  
                        </ReactWhatsapp>
                    </div>
                </div>
            )}
        </div>
    );
}

export default WhatsAppChat;