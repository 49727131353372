import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./button.css";
function ButtonCircle_sm(props) {
    return (
        <div className={`theme-circle-btn  tcb-sm ${props.DynamicBtnColor} ${props.slaLogoStatus === 'true' ? 'sla-signature' : null}`}>
            <Link to={props.url ? props.url : "/"}>
                {props.num1 ? <h3 className="text-theme-blue">{props.num1}</h3> : null} 
                {props.titleFirst ? <p className="screen-text text-theme-blue font-weight-500">{props.titleFirst} <br /> {props.titleSecond}</p> : null}
                {props.image ? 
                    <Image
                        className="w-100 h-auto"
                        src={props.image}
                        alt="images"
                    />
                : null}
            </Link>
        </div>
    );
}

export default ButtonCircle_sm;