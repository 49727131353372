import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Image } from "react-bootstrap";
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import photo from "../../Images/articles/ICV.png";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import logo6 from "../../Images/Bg/layouts1/academy.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles14() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>How to Deliver a Reliable Improvement to a Local Content – Expert View - Powerabode</title>
                <meta
                    name="description"
                    content="NOC’s have a close connect to the economic development of their country. Our services empower and progress the commercial control on Capex and Opex, but also weave in local content. NOC’s are supported on their journey to become independent operators. We have strong…"
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles14-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="The synergy of local content development"
                    boxTitleLabel="Article >"
                    title="local-content-development"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Using best practices to deliver a consistent improvement to local content.
                                    </h1>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={photo}
                                                    alt="Local Content and ICV; How & Why"
                                                    title="In Country Value development by experts"
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Best Practice as a Starting Point.
                                            </h3>
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            The Brief
                                                        </h5>
                                                        <p>
                                                            Local Content development directly contributes to security of supply. It is no longer ‘only a
                                                            governmental requirement’, it is simply a sensible business choice.
                                                        </p>
                                                        <p>
                                                            Traditionally the energy industry relied on a limited number of international specialist suppliers and
                                                            contractors. But as technologies commoditised, it stimulates locally available standardised products/
                                                            services. Major suppliers and contractors have been willing to invest in local development plans
                                                            where business volumes justify that investment.
                                                        </p>
                                                        <p>
                                                            Approaches developed in the capital and technology-intensive energy industry can now be adapted
                                                            and used across broader industrial sectors.
                                                        </p>

                                                        <h5 className="heading">
                                                            The Solution
                                                        </h5>
                                                        <p>
                                                            To develop Local Content/ In Country Value, one must understand the marketplace and keep a long
                                                            term view of supply and demand. This varies category by category – some sectors will already be
                                                            well covered (labour, civil engineering, IT services, electrical) due to overlaps with non-energy
                                                            sectors; what may be needed is development of the Contractors to meet the HSE and technical
                                                            standards specific to the Client’s requirement.
                                                        </p>
                                                        <p>
                                                            It is critical to segment the demand in designated categories. Then market information to be
                                                            captured and shared in a structured way. Outreach is needed to the Contracting and investment
                                                            community so that potential investors have a realistic view of the business volumes.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            Two examples and the results
                                                        </h5>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">
                                                                Wellhead maintenance – a major supplier was willing to establish a new workshop so that more
                                                                maintenance could be carried out locally and train local wellhead engineers in country based on a 5
                                                                year commitment to buy their well heads.
                                                            </li>
                                                            <li className="list-item">
                                                                Field engineering – traditionally well tie-ins and pipelines had been outsourced to major
                                                                international engineering houses, who would subcontract a local design institute to capture in-
                                                                country requirements and other aspects for getting design approval. By contracting the design
                                                                institute directly, it was possible to expand their scope to new design of all in field pipelines and tie-
                                                                ins including obtaining design approval. This gave cost and schedule improvements and helped the
                                                                local design institute develop its team of engineers to provide the service in the market long term.
                                                            </li>
                                                        </ul>

                                                        <h5 className="heading">
                                                            Why <span className="theme-font">powerabode</span>?
                                                        </h5>
                                                        <p>
                                                            Our ICV expert involvement will look at your Local Content/ICV rules and commitments. We can set
                                                            up a bespoke audit and assurance program for your suppliers. Monitoring your ICV targets.
                                                        </p>
                                                        <p>
                                                            We make specific business cases for each category, using industry best practices.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="articles-list-wrap  mt-5 px-4">
                                                <h3 className="section-sub-title mt-1 text-left">
                                                    View Through Category Lenses
                                                </h3>
                                                <ul className="check-icon-list">
                                                    <li className="list-item   d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Licence to operate: Improved local content leads to better local job prospects.</span>
                                                    </li>
                                                    <li className="list-item   d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>
                                                            Security of supply: Creates a more robust supply chain and minimises the impact of external shocks such as COVID/ restricted movement etc
                                                        </span>
                                                    </li>
                                                    <li className="list-item   d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>
                                                            New technology: Brings new technologies that can strengthen the industrial base cross sector
                                                        </span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                

                                    
                                    <div className="w-100 mt-5">
                                        <div className="section-header mb-5">
                                            <h1 className="section-title-two text-center">
                                                Visit these services for more detail:
                                            </h1>
                                        </div>
                                        <div className="d-flex justify-content-center gap-3 mt-4 flex-wrap">
                                            <ButtonCircle
                                                url="/systems-delivery/academy" 
                                                DynamicBtnColor="btn-color-4 mb-3"
                                                image={logo6}
                                            />
                                            <ButtonCircle
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircle
                                                url="/systems-delivery/contractmanagementtool"
                                                DynamicBtnColor="btn-color-5 mb-3"
                                                image={logo5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles14;