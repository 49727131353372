/* eslint-disable eqeqeq */
import "../Style/supply-chain-slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState } from "react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";


import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const SliderData = [
    {
        id: "1",
        url: "/category-strategy",
        image: require('../../Images/banner/sla-slider/CategoryStrategy.webp'),
        name: "Category Strategy & Contract Strategy",
        alt:"Strategy-slider",
        title: "Compas to success"
    },
    {
        id: "2",
        url: "/tendering-outsourcing",
        image: require('../../Images/banner/sla-slider/TenderingandOutsourcing.webp'),
        name: "Tendering & Outsourcing",
        alt: "Commercial-assurance-slider",
        title: "Compliance and governance overview"
    },
    {
        id: "3",
        url: "/post-award-contract-management",
        image: require('../../Images/banner/sla-slider/PostAwardContract.webp'),
        name: "Post Award Contract Management",
        alt: "Cost-recovery-slider",
        title: "Why Cost Recovery requires expertise?"
    },
    {
        id: "4",
        url: "/materials-management",
        image: require('../../Images/banner/sla-slider/MaterialsManagement.webp'),
        name: "Materials Management",
        alt: "Materials-management-slider",
        title: "How off the shelf materials management system is the basis for success?"
    },
    {
        id: "5",
        url: "/commercial-assurance",
        image: require('../../Images/banner/sla-slider/Commercial-Assurance.webp'),
        name: "Commercial Assurance",
        alt: "Post-award-contract-management-slider",
        title: "Post-award-contract-management-slider"
    },
    {
        id: "6",
        url: "/cost-recovery",
        image: require('../../Images/banner/sla-slider/CostRecovery.webp'),
        name: "Cost Recovery",
        alt: "Tendering-outsourcing-slider",
        title: "Understanding the business is key. Why outsourcing tendering can be the base for succes and competitive opex and capex investment in energy assets."
    },
]

function SupplyChainSlider(props) {
    const [swiperText0, setSwiperText0] = useState(false);
    const [swiperText1, setSwiperText1] = useState(false);
    const [swiperText2, setSwiperText2] = useState(false);
    const [swiperText3, setSwiperText3] = useState(false);
    const [swiperText4, setSwiperText4] = useState(false);
    const [swiperText5, setSwiperText5] = useState(false);

    const CheckActiveItemIndex = (e) => {
        if (e.realIndex == '0') { setSwiperText0(true); } else { setSwiperText0(false); }
        if (e.realIndex == '1') { setSwiperText1(true); } else { setSwiperText1(false); }
        if (e.realIndex == '2') { setSwiperText2(true); } else { setSwiperText2(false); }
        if (e.realIndex == '3') { setSwiperText3(true); } else { setSwiperText3(false); }
        if (e.realIndex == '4') { setSwiperText4(true); } else { setSwiperText4(false); }
        if (e.realIndex == '5') { setSwiperText5(true); } else{setSwiperText5(false);}
        
        // console.log(e.realIndex)
    }
    return (
        <section className="supply-chain-sla-slider mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-header">
                            <h1 className="section-title-two">
                                The Supply Chain by <span className="text-color-primary">SLA Services</span>
                            </h1>
                        </div>

                        <div className="section-content mt-4">
                            <Swiper
                                // slidesPerView={3}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                                onSlideChange={(e) => CheckActiveItemIndex(e)}
                                breakpoints={{
                                    900: {
                                        slidesPerView: 3,
                                    },
                                    800: {
                                        slidesPerView: 2,
                                    },
                                    500: {
                                        slidesPerView: 1,
                                    }
                                }}
                            >
                                {SliderData.map((item, index) => (
                                    <SwiperSlide key={index}>
                                        <Link to={item.url}>
                                            <Image
                                                className="w-100"
                                                src={item.image}
                                                alt={item.alt}
                                                title={item.title}
                                            />
                                            <div className="swiper-text">
                                                <h4>
                                                    {item.name}
                                                </h4>
                                            </div>
                                        </Link>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                            <br />

                            <div className="slider-per-content px-4 text-center">
                                {swiperText0 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title" >Category Strategy & <span className="text-color-theme">Contract Strategy</span></h3>
                                        <p>Reducing cost is an art. Our Experts are not classic ‘consultants’. They had long careers in Category Management and Front-line Operations at various IOCs formulating many Category and Contracting strategies. </p>
                                    </div>
                                )}
                                {swiperText1 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title">Tendering & <span className="text-color-theme">Outsourcing</span></h3>
                                        <p>Procurement is done in-house by most of our Clients.With involvement of our experts, tools and systems the results improve significantly. </p>
                                    </div>
                                )}
                                {swiperText2 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title">Post Award <span className="text-color-theme">Contract Management</span></h3>
                                        <p>Underrated and little loved. But delivery is heavily impacted by Post-Award Contract Management. Being on top of it. Discipline. Also understanding the scope and the performance drivers. Working with all stakeholders.</p>
                                    </div>
                                )}
                                {swiperText3 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title">Materials <span className="text-color-theme">Management</span></h3>
                                        <p>The right goods at the right place, at the right time. Today, but also in 5 years. Is Materials Management a cost, or can it make you money?</p>
                                    </div>
                                )}
                                {swiperText4 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title">Commercial <span className="text-color-theme">Assurance</span></h3>
                                        <p>
                                            We identify a wide range of commercial risks and assess & control those out of your contracts.
                                            It increases transparency, enhances performance and increases overall control.
                                            We are very much an assurance company.
                                        </p>
                                    </div>
                                )}
                                {swiperText5 && (
                                    <div className="single-slider-wrap">
                                        <h3>Cost <span className="text-color-theme">Recovery</span></h3>
                                        <p>It always surprises us. Cost recovery is carried out by people that understand numbers, but don’t understand the field and the contracts. Or, even worse, people that understand the contract, but not the numbers. Not with us. We offer a process with high impact. </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default SupplyChainSlider;