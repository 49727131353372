import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import logo1 from "../../Images/Bg/layouts1/CVI-Logo.png";
import logo3 from "../../Images/Bg/layouts1/opex_capex.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";


function Articles10() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Why Business Plan is important for People of All Levels? - Powerabode</title>
                <meta
                    name="description"
                    content="Not everyone knows the businessplan. This information is confidential or under evaluation. But, in the operations latest information is key for opex and capex decisions. How to deal with misalignment?..."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles10-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Misalignment with the business plan"
                    boxTitleLabel="Article >"
                    title="businessplanbusinessplan"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Understanding business plan is important for business at all level.
                                    </h1>
                                    <h3 className="section-sub-title mt-1 text-center">
                                        Even ‘business as usual’ can be impacted by the latest business plan
                                    </h3>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Energy companies’ corporate business plans are usually set for 3-5 years, with a detailed plan for the first year. The detailed plan is then customised to country level and on Asset Level. Each country may have multiple Assets, so it can be a complex process, not always picking up all details.</p>
                                                        <p>When it comes to the execution, it is not uncommon that disconnect appears between the frontline organisation and the Asset itself. Active involvement of an ECMT (Extended Contract Management Team) may reduce the risk of such disconnect. If not sufficient attention is given to avoid the disconnect, the consequence will be growing cost inefficiencies. The following case study summarizes a typical example of a disconnect between the business plan and the frontline organisation, and its cost impact. It also explains the remedy, and subsequent change in strategy in order to remain cost efficient.</p>
                                                        <p><b>Let us look at the following true case study:</b></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            The Brief:
                                                        </h5>
                                                        <p>Valve replacement for an onshore gas field underwent a Cost challenged from the Contract’s Board. The case for change was based on frequency of breakdown causing unplanned production deferment and high maintenance cost.</p>
                                                        <p>The Project Manager wanted to source a different brand of valve which had nearly ‘no maintenance’ requirement and 20% more operating life. The Contract Engineer calculated a robust OPEX projection for 5-10 years despite the initial CAPEX being more expensive compared to the incumbent brand. The Contract Strategy was endorsed by the Board subject to passing a Cost challenge.</p>

                                                        <h5 className="heading">
                                                            The Issue:
                                                        </h5>
                                                        <p>Initial assessment of the Contract Strategy showed full compliance with the Maintenance Strategy but a misalignment with Asset’s Business Plan. The facility was situated in a field which was up for decommissioning in 3 years! This key piece of information was overlooked during the Contract Strategy development. It made the entire strategy, based on a 10 yr projection, void. The client still required a solution for only the next 3 years (not 10 years!), but tender was not an option anymore.</p>
                                                        <h5 className="heading">
                                                            The Solution:
                                                        </h5>
                                                        <p>Stakeholder analysis was done and ECMT was identified: Project Manager, Contract Engineer, Technical Authority, Asset Commercial Lead, Asset Economics Lead, HSE, Cost Planning Engineers, Materials Management, Procurement (buyer).</p>
                                                        <p>A Strategic dialog of initiated to identify 3 years viable solution. Alternative solutions were explored for 2 days. Such dialogs put no cap on ideas no matter how ‘wild’ they were.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p><b>In total 11 possibilities were explored in 4 categories:</b></p>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">Technical operating envelops</li>
                                                            <li className="list-item">Commercial options</li>
                                                            <li className="list-item">New technology deployment i.e. 3D printing</li>
                                                            <li className="list-item">Complete out of the box solutions, creating precedence</li>
                                                        </ul>

                                                        <h5 className="heading">
                                                            The Result:
                                                        </h5>
                                                        <p>After significant debate, the optimum solution was selected.</p>

                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">As department head, the Maintenance Manager fronted a proposal to the Contracts Board to procure, install and operate a swap-set till the end of that field life.</li>
                                                            <li className="list-item">Technical Authority approved the swap-set and its spare parts.</li>
                                                            <li className="list-item">Contract Engineer negotiated procurement price, maintenance support for 3 years and lead time for delivery.</li>
                                                            <li className="list-item">The Cost proposal of the revised Strategy was 76% lower compared to the original Project Price (CAPEX+OPEX).</li>
                                                        </ul>

                                                        <p><b>Conclusion:</b> Understanding Business Plan is important for Business at all levels. We feel strongly that the leadership of an Asset should share Asset business plans with their teams.</p>

                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div className="w-100 mt-5">
                                        <h3 className="section-sub-title mt-1 text-center">
                                            This is what we do. And we do this for a reason
                                        </h3>
                                        <div className="d-flex justify-content-center gap-3 mt-4 flex-wrap">
                                            <ButtonCircle
                                                url="/category-strategy"
                                                titleFirst="Contract Strategy"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircle
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                            <ButtonCircle
                                                url="/capex-opex-cost-program" 
                                                DynamicBtnColor="btn-color-2 mb-3"
                                                image={logo3}
                                            />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles10;