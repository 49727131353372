import { Image } from "react-bootstrap";
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import photo from "../../Images/Bg/CVI-Logo.png";
import photo1 from "../../Images/Bg/bg-3.webp";
import photo2 from "../../Images/Bg/SAVINGS-02.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles11() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The Triangle of Cost & Value Philosophy in Energy Sectors - Powerabode</title>
                <meta
                    name="description"
                    content="Identifying a saving potential is a small step. The bigger thing is to bring it to your bottomline. We use the Integrated Supply Chain, the structure of our Cost Intelligence Philosophy and the people that use the contract to…"
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles11-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Unlocking Value to your bottom line"
                    boxTitleLabel="Article >"
                    title="Unlocking-value-savings-bottom-line"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        The triangle of our Cost & Value Philosophy
                                    </h1>

                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={photo}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Integrated Supply Chain (ISC), Extended Contract Management
                                                team (ECMT) and Cost and Value Intelligence (CVI)
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <div className="info-text-articles-11 position-relative">
                                                            <p>
                                                                Our Supply Chain by SLA, the program to reduce opex/capex 'The Road to Cost Leadership’ and
                                                                the training modules in <span className="theme-font">powerabode</span> academy are the direct result of the underlying philosophy of
                                                                Cost and Value Intelligence. They all unlock savings and value. The philosophy itself has three cornerstones.
                                                                This article explains their background.
                                                            </p>
                                                            <p>
                                                                Energy companies work like eco-systems. The Integrated Supply Chain (ISC) represents the
                                                                interdependency and synergy between various disciplines, departments, processes, and systems
                                                                within that company’s eco-system. We recognise the Integrated Supply Chain as the first
                                                                cornerstone in our Philosophy of C&V Intelligence.
                                                            </p>
                                                            <div className="mx-auto w-100 text-center my-3">
                                                                <Image
                                                                    height="300px" width="auto"
                                                                    src={photo1}
                                                                    alt=""
                                                                />
                                                            </div>
                                                        </div>
                                                        <p>
                                                            Work is executed with the involvement of multidisciplinary teams. These teams and
                                                            departments directly and indirectly influence all Contracts. We recognise that a selection of
                                                            them form the Extended Contract Management Team (ECMT) for any specific contract. They
                                                            are primary influencers of efficiency and cost; The Extended Contract Management Teams are the
                                                            second cornerstone in the <span className="theme-font">powerabode</span> Philosophy of C&V Intelligence.
                                                        </p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <div className="info-text-articles-11 position-relative">
                                                            <p>
                                                                Potential cost savings and value improvement in an Energy Asset fall in three categories; Efficiency (40%), Specification (30%) and
                                                                Rates (20%). The final 10% is locked into organisational inefficiencies; they are deemed
                                                                ‘unrecoverable’. Rates are the only element companies traditionally focus on, negotiating
                                                                ‘discounts’. In an effort to achieve cost efficiency that is not sufficient. All three categories need a
                                                                coordinated effort. Only then the full potential savings will be achieved together with significant value improvement as an upside. C&V Intelligence focus on
                                                                these categories makes the 3rd cornerstone of our philosophy.
                                                            </p>
                                                            <p>
                                                                The <span className="theme-font">powerabode</span> C&V Intelligence Philosophy is deep rooted into frontline operations and projects.
                                                                Therefore, our involvement through SLA services, or with our cost leadership program
                                                                will directly impact performance. Training will further contribute to sustainable development of skills
                                                                and ISC operations.
                                                            </p>
                                                            <h5 className="heading">
                                                                Why is the Philosophy of Cost and Value Intelligence unique?
                                                            </h5>
                                                            <p>
                                                                Simply, because traditionally companies focus on negotiating ‘rates’. This is only 20% of the total
                                                                savings potential. Following the Philosophy of Cost and Value Intelligence, we map the full integrated supply
                                                                chain, use the extended contract management team to get intelligence, and subsequently realise
                                                                90% of the savings potential. Instead of the maximum of 20% which is the traditional approach.
                                                            </p>
                                                            <p>
                                                                Cost are a science. Our program The Road to Cost Leadership brings you the full savings potential.
                                                                And equips your organisation for long terms value delivery.
                                                            </p>
                                                            <p>
                                                                We are happy to discuss in detail and demonstrate how this is relevant in your organisation.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-auto w-100 text-center mt-5">
                                                <Image
                                                    height="200px" width="auto"
                                                    src={photo2}
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles11;