import React from 'react';
import { useEffect } from "react";

function Policy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-12">
                    <h1 className="section-title-two mt-5">
                        Cookies Policy & Privacy Policy
                        <span className='float-md-end d-block'>December 2020</span>
                    </h1>
                    <div className="ht-box-icon">
                        <div className="icon-box-wrap">
                            <div className="content text-left">
                                <h3 className="section-sub-title ">Cookie Policy</h3>
                                <p>A cookie is a small file which asks permission to be placed on your computer’s hard drive. Once you agree, the file is added and the cookie helps analyse web traffic or lets you know when you visit a particular site. Cookies allow web applications to respond to you as an individual. The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.</p>
                                <p>We use traffic log cookies to identify which pages are being used. This helps us analyse data about web page traffic and improve our website in order to tailor it to customer needs. We only use this information for statistical analysis purposes and then the data is removed from the system.</p>
                                <p>Overall, cookies help us provide you with a better website, by enabling us to monitor which pages you find useful and which you do not. A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.</p>
                                <p>
                                    You can choose to accept or decline cookies. Most browsers are initially set up to accept cookies. You can reset your browser to refuse all cookies or indicate when a cookie is being sent. However, some parts of the website may not function properly. For information about how to disable cookies please visit the <a href="www.aboutcookies.org" className='text-color-theme'> www.aboutcookies.org </a> external site, opens in a new window) website.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="ht-box-icon">
                        <div className="icon-box-wrap">
                            <div className="content text-left">
                                <h3 className="section-sub-title">Privacy Policy</h3>
                                <p>This Privacy Notice explains how <span className="theme-font">powerabode</span> DMCC (‘the Company‘) handles and uses information (both hard copy and electronic) it collects about you. This notice is non-contractual and can be amended at any time.</p>
                                <p>The Company is a “data controller”. This means that the Company is responsible for deciding how to hold and use personal information about you.</p>
                                <p>
                                    The Company will comply with the data protection principles set out in the law when handling your personal information. Overall responsibility for monitoring compliance with data protection sits with the Managing Director. Communication on data protection issues send to
                                    <span className='text-color-theme'> hr@powerabode.com.</span>
                                </p>

                                <h5 className="heading">1. What type of information does the Company hold?</h5>
                                <ul className="circle-list">
                                    <li className="list-item">
                                        <span>“Personal data or information”: this means any information about an individual from which that person can be identified. It does not include data where the individual’s identity has been removed (anonymous data).</span>
                                    </li>
                                    <li className="list-item">
                                        <span>“Special category data”: this means data about an individual of a more sensitive nature and requires a higher level of protection.</span>
                                    </li>
                                </ul>

                                <h5 className="heading">2. On what basis does the Company process your personal information?</h5>
                                <p>The Company will only use your personal information when permitted by the law. Most commonly, the Company will rely on the following legal bases when using your personal information:</p>
                                <ul className="circle-list">
                                    <li className="list-item">
                                        <span>Where it needs to perform the contract it has entered into with you (*)..</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Where it needs to comply with a legal obligation (**).</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Where it is necessary for the Company’s legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests (***).</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Where you have provided consent (****).</span>
                                    </li>
                                </ul>
                                <p>We have indicated by asterisks in section 3 below the legal bases on which the Company processes your personal information. Some of the legal bases for processing will overlap and there may be several legal bases which justify the Company’s use of your personal information.</p>
                                
                                
                                <h5 className="heading">3. What personal information does the Company process?</h5>
                                <p>The Company processes personal information, including basic personal information such as your name, title, job title, employer name, business contact details, business mailing address, job title and department (* and ** and ***and ****).</p>

                                <h5 className="heading">4. How is your information used by the Company?</h5>
                                <p>The Company will use your personal information for the following purposes (this list is not exhaustive):</p>
                                <ul className="circle-list">
                                    <li className="list-item">
                                        <span>Delivering <span className="theme-font">powerabode</span> training, program and services;</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Undertaking business development activities; and</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Ensuring compliance with legal requirements and obligations to third parties.</span>
                                    </li>
                                </ul>
                                    <br />
                                <h5 className="heading">5. What happens if you fail to provide personal information?</h5>
                                <p>Where the provision of your personal data is required for the Company to enter into or carry out the performance of a contract with you or undertake business development activities, refusal to provide this personal information will prevent the Company from being able to offer its services.</p>


                                <h5 className="heading">6. Will you be subject to automated decision making?</h5>
                                <p>Automated decision-making takes place when an electronic system uses personal information to make a decision without human intervention. You will not be subject to decisions that will have a significant impact on you based solely on automated decision-making unless the Company has a lawful basis for doing so and it has notified you.</p>

                                <h5 className="heading">7. Who will your personal information be disclosed to?</h5>
                                <p>The Company will occasionally share data with trusted third parties, for example, IT service providers or payment processors. The Company only permits third parties to process your personal data for specified purposes and in accordance with its instructions. The Company requires third parties to respect the security of your data, to take appropriate security measures and to treat it in accordance with the law.</p>

                                <h5 className="heading">8. Will your personal data be transferred to third parties outside of the European Economic Area?</h5>
                                <p>The Company may transfer personal data about you outside of the EEA. No personal information will be transferred outside of the EEA, unless the Company is certain that an adequate level of protection exists in relation to the processing of that information (e.g. EU Model Clauses). The company follows GDPR protocol and standards for its global operations.</p>

                                <h5 className="heading">9. How long will the Company use your information for?</h5>
                                <p>The Company will only retain your personal information for as long as is necessary to fulfil the purposes it collected the personal information for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>

                            
                                <h5 className="heading">10. What are your rights relating to your personal information?</h5>
                                <p>Under certain circumstances, by law you have the right to:</p>
                                <ul className="circle-list">
                                    <li className="list-item">
                                        <span>Request access to your personal information (commonly known as a “data subject access request”).</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Request correction of the personal information that the Company holds about you.</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Request the erasure of your personal information.</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Request the restriction of and objection to processing of your personal information.</span>
                                    </li>
                                     <li className="list-item">
                                        <span>Request the transfer of your personal information to a third party.</span>
                                    </li>
                                    <li className="list-item">
                                        <span>Request the withdrawal of your consent for processing.</span>
                                    </li>
                                </ul>
                                <p>Some of these rights are not automatic, and the Company reserves the right to discuss with you why it might not comply with a request. If you want to exercise any of the above rights, please contact the Managing Director. Communications on this topic, in writing at <span className="text-color-theme">hr@powerabode.com</span></p>
                            
                                <h5 className="heading">11. Who can you contact if you have concerns about the Company’s use of your personal information?</h5>
                                <p>If you have any concerns or queries about the Company’s use of your personal data, please contact the Managing Director in writing at <span className="text-color-theme">hr@powerabode.com</span> . reference ‘data protection’</p>
                                <p>BY USING OUR WEBSITES, YOU SIGNIFY YOUR ACCEPTANCE OF THIS PRIVACY POLICY AND OUR COOKIE POLICY AND OF US PROCESSING YOUR PERSONAL DATA IN ACCORDANCE WITH THE TERMS OF THIS PRIVACY POLICY AND COOKIE POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY OR COOKIE POLICY, YOU SHOULD NOT USE OUR WEBSITES. YOUR CONTINUED USE OF OUR WEBSITES FOLLOWING THE POSTING OF CHANGES TO THESE POLICIES WILL MEAN THAT YOU ALSO ACCEPT THOSE CHANGES.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Policy;