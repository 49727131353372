import { Image } from "react-bootstrap";
import "../Style/sector-box.css"
function SectorBox(props) {
    return (
        <div className="ht-box-icon p-0">
            <div className="icon-box-wrap position-relative overflow-hidden p-0">
                <Image
                    height="auto" width="100%"
                    src={props.backgroundImage}
                    alt={props.alt}
                    title={props.bgTitle}
                />
                <div className={`ht-text ${props.backgroundColor} position-absolute top-0 left-0 w-100 h-100 d-flex justify-content-center align-items-center p-4 flex-column`}>
                    <Image
                        height="auto" width="60px"
                        src={props.boxIcon}
                        className="mb-3"
                    />
                    <h3 className="text-white">
                        {props.boxTitle}
                    </h3>
                </div>
            </div>
        </div>
    );
}

export default SectorBox;