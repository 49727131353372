// import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import BellIcon from "../../Images/Supply-chain-sla/bell.png";
import Icon2 from "../../Images/Supply-chain-sla/icon2.png";
import Icon3 from "../../Images/Supply-chain-sla/icon3.png";
// import ResultBg from "../../Images/banner/case-studies-2.png";
import SupplyChainSlider from '../../Components/Sections/SupplyChainSlider';
import CoverPhoto1 from "../../Images/Supply-chain-sla/Supply-Chain-by-SLA-Lower.webp";

import Background from "../../Images/articles/single/12.jpg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

// case study assets
import "../../Components/Style/case-studies.css";
import CaseStudiesPhoto1 from "../../Images/articles/single/12.1.jpeg";
import CaseStudiesPhoto2 from "../../Images/articles/single/6.1.jpeg";
import CaseStudiesPhoto3 from "../../Images/articles/single/11.1.jpeg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import HeroSectionLayout1 from '../../Components/Sections/HeroSectionLayout1';
import { Helmet } from 'react-helmet-async';

function CategoryStrategy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Thinking Category & Contract Strategy – Power Abode</title>
                <meta
                    name="description"
                    content="Thinking category & contract strategy both are important for market research, demand and supply assessment, and contract models. Let’s see how."
                />;
            </Helmet>
            <div className="bg-white">
                
                <HeroSectionLayout1
                    className="overflow-hidden"
                    pageTitleName="Category Strategy & Contract Strategy"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="category-strategy-bg"
                    boxTitleValue="Category Strategy "
                    boxTitleValue2=" & Contract Strategy"
                    boxDesValue="Reducing cost is an art. Our Experts are not classic ‘consultants’. They had long careers in Category Management and Front-line Operations at various IOCs formulating many Category and Contracting strategies. powerabode have deep understanding of strategy and the value it can bring. We know it is the fundament to sustainable performance."
                    title="Category-management-and-frontline-operations | powerabode IOC Award Winning Team by International Oil Company"
                />

                <div className="section sla-service-wrapper mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two">Building and implementing Category <span className="text-color-theme">& Contract Strategies</span></h1>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-2 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-215">
                                                    <div className="content text-left text-center">
                                                        <Image
                                                            height="auto" width="60px"
                                                            src={BellIcon}
                                                            alt="Identifying-risks"
                                                            title='Identify risks in supply chain by taking COVID learnings into account'
                                                        />
                                                        <p className='mt-3'>
                                                            Post Covid we realised that some risks are real and some risks are new
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-2 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-215">
                                                    <div className="content text-left text-center">
                                                        <Image
                                                            height="auto" width="60px"
                                                            src={Icon2}
                                                            alt="Reassessed-Category-Strategies-post-covid-19"
                                                            title="Reassessed-Category-Strategies-post-covid-19"
                                                        />
                                                        <p className='mt-3'>
                                                            Covid 19 impact requires us to reassess our Category Strategies
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-2 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-215">
                                                    <div className="content text-left text-center">
                                                        <Image
                                                            height="auto" width="60px"
                                                            src={Icon3}
                                                            alt="New-energies-energy-transition"
                                                            title="Energy Transition to zero emissions"
                                                        />
                                                        <p className='mt-3'>
                                                            New energies require new Strategy. Energy transition is not about copying the traditional supply chain.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <div className="row">
                                        <div className="col-md-6 pr-0">
                                            <div className="sla-img-l-box position-relative mt-5">
                                                <Image
                                                    style={{maxHeight: "500px", objectFit: "cover"}}
                                                    width="100%" height="auto"
                                                    src={ResultBg}
                                                    alt=''
                                                />
                                                <div className="sla-img-absolute-txt">
                                                    <h1 className='section-title-two text-white'>
                                                        The Result
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <div className="sla-txt-r-box mt-5 bg-dark-400 p-4 h-100 d-flex justify-content-center align-content-center flex-column">
                                                <ul className="check-icon-list">
                                                    <li className="list-item text-white d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Designed strategy delivers Business Plan</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Roadmap for Near- to long term sustainable cost reduction</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Successful outsourcing by detailed modelling</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>We make function & line drive delivery of strategy and savings</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-about-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div 
                                    className="sla-content-wrap" 
                                    style={{ background: `url("${Background}")` }}
                                    title="Category-Strategy-&-Contract-Strategy"
                                >
                                    <div className="sla-cw-text">
                                        <h1 className='section-title-two text-white'>
                                            The Result
                                        </h1>
                                        <ul className="check-icon-list mt-3">
                                            <li className="list-item text-white d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Designed strategy delivers Business Plan</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Roadmap for Near- to long term sustainable cost reduction</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Successful outsourcing by detailed modelling</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>We make function & line drive delivery of strategy and savings</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sla-cw-footer-text">
                                        <h1 className="section-title-two text-white">
                                            Category Strategy & Contract Strategy
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            

                <div className="sla-service-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative ">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme font-style-italy">
                                            ‘If you always do what you always did, you will always get what you always got.’ <br />
                                            <b>-Henry Ford</b>
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="section-title-two">
                                                Our <span className="text-color-primary"> Scope :</span>
                                            </h1>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{marginTop: '0'}}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Hold Strategic Dialog</h5>
                                                        <p className='mt-3'>
                                                            Defining key objectives, involvement from the right stakeholders, probing out the right information, as well as capturing the drivers of the contract strategy. Our Workshop program delivers!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Co-Design Contract Strategy</h5>
                                                        <p className='mt-3'>
                                                            Linking business plan to field contract performance. The right KPI to drive top quartile delivery at the right cost. This is our angle to developing Contract Strategy. Let us develop your contract strategies to drive performance.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center" >
                                                        <h5 className="heading">Market Research</h5>
                                                        <p className='mt-3'>
                                                            We will conduct extensive market research to develop your strategy; beyond your vendor list, we pursue innnovation.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title  text-right">
                                        <span className="text-color-theme ">
                                            A category strategy is about creating synergy and leverage.
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Demand & Supply Assessment</h5>
                                                        <p className='mt-3'>
                                                            Data is at the core of your strategy development. How to use the data? How about the intepretation? Assess historic and future demand. Latest Supplier developments.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Contract Models</h5>
                                                        <p className='mt-3'>
                                                            ‘Horses for Courses’. Our strength is bringing contract models to the table that safeguard delivery and cost efficiency.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Co-Design Category Strategy</h5>
                                                        <p className='mt-3'>
                                                            Developing Category Management Strategy based on value chain integration. We work closely with clients’ technical department to identify target areas for extracting value. Either by formulating new or by enhancing existing category strategy.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            Do you know your market? Engage. A successful outcome is defined by its’  strategy.
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SupplyChainSlider/>

                <div className="container-fluid mt-5 position-relative d-none d-md-block">
                    <Image
                        width="100%" height="auto"
                        src={CoverPhoto1}
                        alt=""
                    />
                    <div className="box-txt-top-left-absolute">
                        <h1 className='section-title-two sla-page-title-two'>
                            Related Articles and Case Studies
                        </h1>
                    </div>
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <div className="case-studies-section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title d-block d-md-none">
                                        <h1 className="section-title-two">
                                            Top <span className="text-color-theme">Articles </span>
                                            &
                                            <span className="text-color-theme"> Case Studies</span>
                                        </h1>
                                    </div>
                                    <div className="section-content mt-4">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-4 col-12">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-12"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Strategic Sourcing is not a Sourcing Strategy</h5>
                                                            <p className="text-white">Digitisation is key, but good old human intelligence is still key in defining the strategy. Artificial Intelligence is quickly gaining pace, but...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-12">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-6"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Drilling Cost Transformation</h5>
                                                            <p className="text-white">Strategy and sourcing define the outcome. We advice and built your delivery model, including the tendering model...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-md-4 col-12">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-11"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Unlocking Value to your bottom line</h5>
                                                            <p className="text-white">Identifying a saving potential is a small step. The bigger thing is to bring it to your bottomline. We use the Integrated Supply Chain, the structure of our Cost Intelligence Philosophy and the people that use the contract to…</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CategoryStrategy;