import Faq from "../Components/Sections/Faq";
import HorizontalAccordion from "../Components/Sections/HorizontalAccordion";
import { useEffect } from "react";
import IconBox from "../Components/Sections/iconBox";
import CommercialEcosystemLayout1 from "../Components/Sections/CommercialEcosystemLayout1";
import HeroSlider from "../Components/Sections/HeroSlider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import { Image } from "react-bootstrap";
import TopSummit from '../Images/Bg/top-summit.webp';
import { Helmet } from "react-helmet-async";

function HomeDemo() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Value Improvement & Cost Optimisation for Energy Assets – Power Abode</title>
                <meta
                    name="description"
                    content="Power Abode is an expert in the supply chain and procurement space of large energy assets."
                />;
            </Helmet>
            <div className="bg-white">
                
                <HeroSlider/>
            
                <IconBox />

                <section className="company-about-section pt-md-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header d-none position-relative text-center">
                                    <h1 className="section-title-two"> 
                                        <span className="text-color-theme">Welkom!</span> 
                                    </h1>
                                    <h3 className="section-sub-title mt-1">
                                        We help Oil & Gas Companies reach Cost Efficiency.
                                    </h3>
                                    <p className="text-center">‘Our methods, systems & services improve Governance & Compliance. World-class stakeholder management, & bringing best practice standards to both Fossil & Renewable energy assets.’</p>
                                    <div className="text-right">
                                        <h3 className="section-sub-title mt-1 bottom-border-quote">
                                            <i>- Jeroen Van Nuland</i>
                                        </h3>
                                        <p className="text-right">Chairman</p>
                                    </div>
                                </div>

                                <div className="content mt-3">
                                    <div className="row">
                                        <div className="col-md-7 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap m-0">
                                                    <div className="content text-left">
                                                        <h1 className="section-title-two">Delivering Value Improvement  <span className="text-color-theme" >and Cost Optimisation.</span></h1>
                                                        <h3 className="section-sub-title mt-1">The impact of supply chain & procurement on the bottom line is grossly underestimated.</h3>
                                                        <h3 className="section-sub-title mt-1">The angle of the integrated supply chain and procurement; this underpins all products and services  of powerabode.</h3>
                                                        <h3 className="section-sub-title mt-1">
                                                            Owners and stakeholders of large energy assets recognise the powerabode ecosystem optimising delivery.
                                                        </h3>

                                                        <h3 className="section-sub-title mt-1">
                                                            Challenges like energy transition, achieving sustainable and competitive cost levels and the
                                                            resilience of your supply chain. With the changing energy landscape, it takes experts to deal with these
                                                            challenges. The solutions have been created to support you on this journey.
                                                        </h3>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-5 col-12">
                                            <div className="about-right-photo">
                                                <Image
                                                    src={TopSummit}
                                                    alt="powerabode-OPEX-CAPEX-tender - evaluation-remuneration – TCO IOC’s NOC’s"
                                                    title="Effective remuneration, evaluation, maintenance tenders OPEX & CAPEX is unique of IOC’s and NOC’s"
                                                    className="w-100 rounded-4"
                                                />
                                                <div className="about-quote-homepage">
                                                    <h1 className="aq-text ">
                                                        <FontAwesomeIcon className="quote-icon-left" icon={faQuoteLeft} />
                                                        The Cost Program
                                                    </h1>
                                                    <h1 className="aq-text quote-two">
                                                        Is a Game Changer
                                                        <FontAwesomeIcon className="quote-icon-right" icon={faQuoteRight} />
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <hr className="p-0 m-0"/> */}

                <CommercialEcosystemLayout1/>

                <Faq />

                <HorizontalAccordion />

            </div>
        </>
    );
}

export default HomeDemo;