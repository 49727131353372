import Button from 'react-bootstrap/Button';
import "../Style/contact-from.css";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { FloatingLabel, Form } from "react-bootstrap";

import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Alert from 'react-bootstrap/Alert';

function ContactFrom() {
    const [successAlert, SetSuccessAlert] = useState(false);
    const [sendingAlert, SetSendingAlert] = useState(false);

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        SetSendingAlert(true)
        emailjs.sendForm(
            'service_lb45k9y', 
            'template_yud9j36', 
            form.current, 
            'G9-v86CKH08S6gS8B'
        ).then((result) => {
                console.log(result.text);
                SetSendingAlert(false)
                SetSuccessAlert(true)
                setTimeout(() => {
                    SetSuccessAlert(false)
                }, 5000);
            }, (error) => {
                console.log(error.text);
                console.log('not send mail');
            });
        e.target.reset();

    };
    return (
        <div className="contacts-from-wrapper pt-5 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                       <div className="cfw-wrap">
                            <div className="section-header position-relative">
                                <h1 className="section-title-two">Get in touch or book a session!</h1>
                            </div>

                            <div className="section-content-wrap mt-4">
                                <Form ref={form} onSubmit={sendEmail}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Name *"
                                                className="mb-3"
                                            >
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Type here name.."
                                                    name="user_name" 
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Email *"
                                                className="mb-3"
                                            >
                                                <Form.Control 
                                                    type="email" 
                                                    placeholder="name@example.com"
                                                    name="user_email" 
                                                />
                                            </FloatingLabel>
                                            <FloatingLabel
                                                controlId="floatingInput"
                                                label="Subject *"
                                                className="mb-3"
                                            >
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Type your subject.." 
                                                    name="user_subject"
                                                />
                                            </FloatingLabel>
                                        </div>
                                        <div className="col-md-6">
                                            <FloatingLabel controlId="floatingTextarea2" label="Message *">
                                                <Form.Control
                                                    as="textarea"
                                                    placeholder="Leave a comment here"
                                                    style={{ height: '206px' }}
                                                    name="message"
                                                />
                                            </FloatingLabel>
                                        </div>
                                        <div className="col-md-12">
                                            {sendingAlert && (
                                                <Alert variant='success' className='mt-md-2 text-center'>
                                                    Sending....
                                                </Alert>
                                            )}
                                            {successAlert && (
                                                <Alert variant='success' className='mt-md-2 text-center'>
                                                    Your Message Was Sent <b>Successfully</b>.
                                                </Alert>
                                            )}
                                            <div className="text-center mt-3 bg-primary-blue-childBtn">
                                                <Button 
                                                    variant="primary"
                                                    className="btn btn--gradient-theme text-white py-3 px-5"
                                                    type="submit"
                                                >
                                                    Send Now <FontAwesomeIcon icon={faPaperPlane} />
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                                {/* <Form ref={form} onSubmit={sendEmail}> */}
                                    
                                    {/* <label>Name</label>
                                    <input type="text" name="user_name" />
                                    <label>Email</label>
                                    <input type="email" name="user_email" />
                                    <label>Message</label>
                                    <textarea name="message" />
                                    <input type="submit" value="Send" /> */}
                                {/* </Form> */}
                                
                            </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactFrom;