import React from 'react';
import "../Style/iconbox.css";
import { Link } from 'react-router-dom';
// import { Image } from 'react-bootstrap'; 
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
const AllSector = [
    {
        url: '/category-strategy',
        color: 'bg-color-set-1',
        title: 'Category Strategy & Contract Strategy',
    },
    {
        url: '/post-award',
        color: 'bg-color-set-2',
        title: 'Post Award Contract Management',
    },
    {
        url: '/commercial-assurance',
        color: 'bg-color-set-3',
        title: 'Commercial Assurance',
    },
    {
        url: '/cost-recovery',
        color: 'bg-color-set-4',
        title: 'Cost Recovery',
    },
    {
        url: '/tendering-outsourcing',
        color: 'bg-color-set-5',
        title: 'Tendering & Outsourcing',
    },
    {
        url: '/materials-management',
        color: 'bg-color-set-6',
        title: 'Materials Management',
    }
]
function IconBoxSla(props) {
    return (
        <>
            <section className="iconBox-wrapper text-center position-relative overflow-hidden d-none d-md-block">
                <div className="container-fluid p-0">
                    <div className="row service-v2">
                        {AllSector.map((item, index) => (
                            <div className="col- col   p-0" key={index}>
                                <Link to={item.url} className='w-100' style={{ minHeight: '212px' }}>
                                    <div className="icon-box-wrap service-single text-center with-icon layout2" >
                                        <div className='icon-box-title'>
                                            <h5>
                                                {item.title}
                                            </h5>
                                        </div>
                                        {/* <p className='icon-box-txt'>Lorem ipsum dolor amet consectetur</p> */}
                                    </div>
                                </Link>
                            </div>
                        ))}
                    
                    </div>
                </div>
            </section>

            <section className="iconBox-wrapper iconBox-slider-wrapper text-center position-relative overflow-hidden d-block d-md-none">
                    <div className="container-fluid p-0">
                        <div className="row service-v2">
                            <div className="col-md-12">
                                <Swiper
                                    // slidesPerView={3}
                                    spaceBetween={0}
                                    centeredSlides={true}
                                    loop={true}
                                    loopFillGroupWithBlank={true}
                                    pagination={{
                                        clickable: true,
                                    }}
                                    navigation={false}
                                    autoplay={{
                                        delay: 2500,
                                        disableOnInteraction: false,
                                    }}
                                    modules={[Autoplay, Pagination, Navigation]}
                                    className="mySwiper"
                                    breakpoints={{
                                        900: {
                                            slidesPerView: 1,
                                        },
                                        800: {
                                            slidesPerView: 1,
                                        },
                                        500: {
                                            slidesPerView: 1,
                                        }
                                    }}
                                >
                                    {AllSector.map((item, index) => (
                                        <SwiperSlide >
                                            <div key={index}>
                                                <Link to={item.url} className='w-100' style={{ minHeight: '212px' }}>
                                                    <div className="icon-box-wrap service-single text-center with-icon layout2" >
                                                        <div className='icon-box-title'>
                                                            <h5>
                                                                {item.title}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    ))} 
                                </Swiper>   
                            </div>
                        </div>
                    </div>
                </section>
        </>
    );
}

export default IconBoxSla;