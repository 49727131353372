import { Image } from "react-bootstrap";
import "../Style/hero-section.css";
const Images = [
    { icon: require('../../Images/icons/blue/group.png') },
    { icon: require('../../Images/icons/blue/group1.png') },
    { icon: require('../../Images/icons/blue/group2.png') },
    { icon: require('../../Images/icons/blue/group3.png') },
    { icon: require('../../Images/icons/blue/group4.png') },
    { icon: require('../../Images/icons/blue/group5.png') },
    { icon: require('../../Images/icons/blue/group6.png') },
    { icon: require('../../Images/icons/blue/group7.png') },
    { icon: require('../../Images/icons/blue/group8.png') }
]
function HeroSection(props) {
    return (
        <>
            {/* Hero Section */}
            <section className={`hero-section-wrap section-space--ptb_120 position-relative ${props.className}`} title={props.title}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {props.boxTitleValue ? 
                                <div className="hero-content py-4 px-4" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="1000">
                                    <h2>
                                        {props.boxTitleValue} 
                                        {props.boxTitleValue2 ? 
                                            <span className="text-color-primary">
                                                {props.boxTitleValue2}
                                            </span>
                                        : null}
                                    </h2>
                                    <p>
                                        {props.brandName ? 
                                            <span className="text-color-theme mr-5 font-weight--regular">{props.brandName}</span>
                                        : null}
                                        {props.boxDesValue}
                                    </p>
                                </div>
                            : null} 
                            <div className="hero-info-box py-4 px-4 text-center d-none d-xl-block">
                                {props.pageTitleValue ? 
                                    <h4 className="text-color-theme py-4">
                                        {props.pageTitleValue}
                                    </h4>
                                : null}

                                <ul className="mb-2">
                                    {Images.map((item, index) => (
                                        <li className="d-inline-block" key={index}>
                                            <Image
                                                height="25px" className="px-1"
                                                src={item.icon}
                                                alt=""
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <p className="text-center">Operations | Maintenance | Production </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {props.textValue1 || 
                props.textValue2 ||
                    props.textValue3 ?
                <section className="position-relative pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="hero-description">
                                    {props.textValue1 && (<p>{props.textValue1}</p>)}
                                    {props.textValue2 && (<p>{props.textValue2}</p>)}
                                    {props.textValue3 && (<p>{props.textValue3}</p>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            : null}                                   
        </>
    );
}

export default HeroSection;