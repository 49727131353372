import "../Style/call-of-action.css";
import Logo from "../../Images/logo/powerabode_black.png";
import { Button, Form, Image, InputGroup } from "react-bootstrap";
import HagueFlag from '../../Images/flag/thehague.svg';
import DubaiFlag from '../../Images/flag/UAE.svg';
import EmailPhoto from "../../Images/icons/call-of-action-1.png";
import FollowUs from "../../Images/icons/call-of-action-2.png";
import Subcribe from "../../Images/icons/call-of-action-3.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
import { faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Mailto = ({ email, subject, body, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`}>{children}</a>
    );
  };
function CallOfAction() {
    const [successAlert, SetSuccessAlert] = useState(false);
    const [sendingAlert, SetSendingAlert] = useState(false);
    
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        SetSendingAlert(true)
        emailjs.sendForm(
            'service_lb45k9y',
            'template_jtgrhs7',
            form.current,
            'G9-v86CKH08S6gS8B'
        ).then((result) => {
            console.log(result.text);
            SetSendingAlert(false)
            SetSuccessAlert(true)
            setTimeout(() => {
                SetSuccessAlert(false)
            }, 5000);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();

    };
    return (
        <div className="call-of-action-wrapper py-5">
            <div className="container">
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6  col-12">
                        <div className="coa-wrap text-left call-of-action-logo">
                            <Image
                                className="brand-logo-footer mt-3"
                                src={Logo}
                                alt="brand logo"
                            />
                            <div className="flag-theme d-flex gap-1 flex-wrap  mt-3">
                                <div className="single-flag-footer gap-1">
                                    <span>THE HAGUE</span>
                                    <Image
                                        src={HagueFlag}
                                        alt=""
                                    />
                                </div>
                                <div className="single-flag-footer gap-1">
                                    <span>DUBAI</span>
                                    <Image
                                        src={DubaiFlag}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6  col-6">
                        <div className="coa-wrap">
                            <div className=" d-flex justify-content-center justify-content-md-start items-center gap-2">
                                <Image
                                   className="subcribe-img-size d-none d-md-block"
                                    src={Subcribe}
                                    alt=""
                                />
                                <div className="coa-flex-text">
                                    <h4 className="text-color-theme m-0">SUBSCRIBE</h4>
                                    <span className="m-0 p-0">To our Newsletter</span>
                                </div>
                            </div>
                           <div className="mt-3">
                                <Form ref={form} onSubmit={sendEmail}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="Subscribe"
                                            aria-label="Subscribe"
                                            aria-describedby="basic-addon2"
                                            name="user_email"
                                        />
                                        <Button 
                                            type="submit"
                                            variant="outline-secondary" 
                                            id="button-addon2"
                                            className="bg-primary-blue text-white"
                                        >
                                            <FontAwesomeIcon icon={faPaperPlane} />
                                        </Button>
                                    </InputGroup>

                                </Form>
                                {sendingAlert && (
                                    <span className='text-success mt-md-2'>
                                        Sending....
                                    </span>
                                )}
                               
                                {successAlert && (
                                    <span  className='text-success mt-md-2'>
                                        Your Message Was Sent <b>Successfully</b>.
                                    </span>
                                )}
                           </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6  col-6">
                        <div className="coa-wrap">
                            <div className="follow-us-coa d-flex justify-content-center justify-content-md-start  items-center gap-2">
                                <Image
                                    className="d-none d-md-block"
                                    height={50} width={50}
                                    src={EmailPhoto}
                                    alt=""
                                />
                                <div className="coa-flex-text">
                                    <h4 className="text-color-theme m-0">FOLLOW US</h4>
                                    <span className="m-0 p-0">On social media</span>
                                    <ul className="d-flex gap-2 footer-social-wrap mt-3">
                                        {/* <li>
                                            <Link 
                                                to="https://wa.me/2348100000000"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <FontAwesomeIcon icon={faFacebookF}/>
                                            </Link>
                                        </li> */}
                                        <li>
                                            <Button 
                                                className="p-0"
                                                variant="link"
                                                target="_blank" 
                                                href="https://www.linkedin.com/company/powerabode/">
                                                <FontAwesomeIcon icon={faLinkedinIn} />
                                            </Button>
                                        </li>
                                        <li>
                                            <Button 
                                                className="p-0"
                                                variant="link"
                                                target="_blank" 
                                                href="https://twitter.com/powerabode_com">
                                                <FontAwesomeIcon icon={faTwitter} />
                                            </Button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6  col-12">
                        <div className="coa-wrap">
                            <div className="d-flex justify-content-center justify-content-md-start items-center gap-2">
                                <Image
                                    height={50} width={50}
                                    src={FollowUs}
                                    alt=""
                                    className="d-none d-md-block"
                                />
                                <div className="coa-flex-text">
                                    <h4 className="text-color-theme m-0">EMAIL</h4>
                                    <span className="m-0 p-0">Contact Us</span>
                                </div>
                            </div>
                            <div className="mt-3 footer-email-style text-left">
                                {/* <Link 
                                    className="text-black"
                                    to="#"
                                    // onClick={(e) => {
                                    //     window.location.href = mailto;
                                    //     e.preventDefault();
                                    // }}
                                    mailto="mailto:no-reply@example.com"
                                >
                                    <span className="text-color-theme">Email: </span> 
                                    info@powerabode.com
                                </Link> */}
                                <span className="text-color-theme">Email: </span>
                                <Mailto 
                                   className="text-black"
                                   email="info@powerabode.com"
                                   subject="" 
                                   body=""
                                >
                                    info@powerabode.com
                                </Mailto>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    );
}

export default CallOfAction;