import React from 'react';
import { useEffect } from "react";
import HeroSection from '../Components/Sections/HeroSection';
import { Image } from 'react-bootstrap';
import PowerabodeAcademyLogo from "../Images/banner/cm/PowerAbodeAcademy.webp";
import ISCTraining from "../Images/banner/cm/ISCtraining.png";
import skillsetTrainings from "../Images/banner/cm/skillsetTrainings.png";
import CaseStudies from '../Components/Sections/CaseStudies';
import AcademyBg from "../Images/banner/cm/AcademyLower.webp";
import { Helmet } from 'react-helmet-async';
function Academy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Powerabode Academy – Powerabode</title>
                <meta
                    name="description"
                    content="For Project Managers, Contract Engineers, Field Supervisors, Technical Authorities, Finance, HSE, Cost Planning, Buyers, Work Preparatory, Job Holders etc"
                />
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="academy-bg"
                    title="powerabode Academy delivers 8 commercial skillset trainings and 1 Integrated Supply Chain Training."
                    pageTitleValue="Academy"
                    boxTitleValue="Skillset Development for Cost & Commercial Impact "
                    boxDesValue="For Project Managers, Contract Engineers, Field Supervisors, Technical Authorities, Finance, HSE, Cost Planning, Buyers, Work Preparatory, Job Holders etc"
                />

                <div className="academy-about-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h1 className="section-title-two">
                                        Local Content Development; <span className="theme-primary-color">Training is the key</span>
                                    </h1>
                                </div>
                                <div className="section-content academy-box-one">
                                    <div className="row">
                                        <div className="col-md-4 col-12 col-sm-6">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-306">
                                                    <div className="content text-left">
                                                        <h5 className="heading">For Whom?</h5>
                                                        <p><span className="theme-font">powerabode</span> academy and its training programs are designed for the ECMT (extended contact management teams) and its members.</p>
                                                        <p>Strong focus on Cost Efficiency and commercial results.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 col-sm-6">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-306">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Why the ECMT?</h5>
                                                        <p>The ECMT is in the heart of the integrated supply chain (ISC).They use & influence contracts everyday.Why the ECMT?</p>
                                                        
                                                        <ul className="circle-list">
                                                            <li className="list-item">
                                                                <span>Create sence of urgency</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Create ownership of savings process</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Has influence & control on commercial performance</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Ideal platform to achieve cost efficiency</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 col-sm-6">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-306">
                                                    <div className="content text-left">
                                                        <h5 className="heading">What is ISC?</h5>
                                                        <p>Integrated Supply Chain (ISC) is all about interdependence and synergy between Client and its contracts. </p>
                                                        <p>Recognising and mastering these between multidisciplinary teams, is the key to cost efficiency and operational effectiveness.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="academy-logo-wrap mt-4 text-center">
                                                <Image
                                                    width="100%" height="auto"
                                                    src={PowerabodeAcademyLogo}
                                                    alt="powerabode-academy-logo"
                                                    title="powerabode-academy-logo"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 col-sm-6">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Benefits?</h5>
                                                        <p>Transforms ECMT into a strong and effective delivery team.</p>
                                                        <p>Focuses on Cost Efficiency.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Image
                                                    height="auto" width="100%"
                                                    src={ISCTraining}
                                                    alt='supply-chain-training, In-country Value, Local Content, ICV, LC'
                                                    title="Integrated Supply Chain, blended learning, Training, Coaching In-country Value, Local Content, ICV, LC"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 col-sm-6">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">How?</h5>

                                                        <p className='mb-1'>ISC Training:</p>
                                                        <ul className="circle-list">
                                                            <li className="list-item">
                                                                <span>10 Modules</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>2x5 Days or 3x3 Days</span>
                                                            </li>
                                                        </ul>

                                                        <p className='mt-2 mb-1'>8 Skillset Trainings (1-2 Days):</p>
                                                        <ul className="circle-list">
                                                            <li className="list-item">
                                                                <span>Value Stream Mapping</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Should Cost Modelling</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Tender Evaluation</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Evaluation & Remuneration Models</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span> Cost & Value focused thinking</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Total Cost & Value of ownership</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Strategic Sourcing vs Sourcing Strategy</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Contract Management</span>
                                                            </li>
                                                            <li className="list-item">
                                                                <span>Contract Interface Matrix</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-12 col-sm-6">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Speciality</h5>
                                                        <p>Own Contract of ECMT is used to customise the training</p>
                                                        <p>Trainers and coaches are Ex-IOC Frontline experienced Direct transfer of learning in Contracts</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <Image
                                                    height="auto" width="100%"
                                                    src={skillsetTrainings}
                                                    alt='powerabode-commercial skillset-training, blended learning, Local content, In-country value, ICV'
                                                    title='8 Supply Chain Core commercial Skill Development Trainings, blended learning, Local content, In-country value, ICV'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5">
                    <Image
                        width="100%" height="auto"
                        src={AcademyBg}
                        alt="powerabode-faculty"
                        title="powerabode-faculty"
                    />
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <CaseStudies />
                </div>
            </div>
        </>
    );
}

export default Academy;