// import { Badge } from "react-bootstrap";
import "../Style/hero-section-sm.css";
const Images = [
    { icon: require('../../Images/icons/Group.png') },
    { icon: require('../../Images/icons/Group(1).png') },
    { icon: require('../../Images/icons/Group(2).png') },
    { icon: require('../../Images/icons/Group(3).png') },
    { icon: require('../../Images/icons/Group(4).png') },
    { icon: require('../../Images/icons/Group(5).png') },
    { icon: require('../../Images/icons/Group(6).png') },
    { icon: require('../../Images/icons/Group(7).png') },
    { icon: require('../../Images/icons/Group(8).png') },
]
function HeroSectionSm(props) {
    return (
        <div>
            {/* Hero Section */}
            <section className={`hero-section-sm-wrap section-space--ptb_120 position-relative ${props.className}`} tilte={props.title}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {props.boxTitleValue ?
                                <div className="hero-content-sm py-4 px-4">
                                    <h2 className="text-center">
                                        {props.boxTitleValue}
                                    </h2>
                                   
                                </div>
                            : null}
                            {props.boxTitleLabel ? 
                                <div className="hero-label-wrap">
                                    <span className="px-3 py-3 badge rounded-pill bg-primary-blue">
                                        {props.boxTitleLabel}
                                    </span>
                                </div>
                            : null}
                            <div className="hero-info-box py-4 px-4 text-center d-none d-xl-block">
                                {props.pageTitleValue ?
                                    <h4 className="text-color-theme py-4">
                                        {props.pageTitleValue}
                                    </h4>
                                : null}

                                <ul className="mb-2">
                                    {Images.map((item, index) => (
                                        <li className="d-inline-block" key={index}>
                                            <img src={item.icon} alt="" />
                                        </li>
                                    ))}
                                </ul>
                                <p className="text-center">Operations | Maintenance | Production </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default HeroSectionSm;