import { NavLink } from "react-router-dom";
import logo from "../Images/logo/powerabode-logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faArrowRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import "./Navbar.css";
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import MobileNav from "./MobileNav";
function Header() {
  const [headerClassName, setHeaderClassName] = useState('');

  const handleScroll = (headerClassName) => {
    if (headerClassName !== 'is-sticky' && window.pageYOffset >= 100) {
      setHeaderClassName('is-sticky');
    } else if (headerClassName === 'is-sticky' && window.pageYOffset < 100) {
      setHeaderClassName('');
    }
  }

  React.useEffect(() => {
    window.onscroll = () => handleScroll(headerClassName);
  }, [headerClassName]); 


  let activeClassName = "active-menu";

  return (
    <>
      <div className={`header-bottom-wrap bg-white ${headerClassName}`}>
        <div className="container-fluid">
          <div className="row w-100">
            <div className="col-lg-12">
              <div className="header default-menu-style position-relative">
                {/* brand logo */}
                <div className="header__logo">
                  <NavLink to="/">
                    <img src={logo} className="img-fluid" alt="" />
                  </NavLink>
                </div>
                {/*  header midle box  */}
                <div className="header-midle-box">
                  <div className="header-bottom-wrap d-md-block d-none">
                    <div className="header-bottom-inner">
                      <div className="header-bottom-left-wrap">
                        {/*  navigation menu */}
                        <div className="header__navigation d-none d-xl-block">
                          <nav className="navigation-menu primary--menu">
                            <ul>
                              {/* <li className="menu-item">
                                <NavLink
                                  to="/homepage-demo"
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : undefined
                                  }
                                >
                                  <span>Home Demo</span>
                                </NavLink>
                              </li> */}
                              <li className={`has-children `} >
                                <NavLink 
                                  to="/cost-value-philosophy"
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : undefined
                                  }
                                >
                                  <span>
                                    Cost and Value Philosophy
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      className="font-10"
                                    />
                                  </span>
                                </NavLink>
                                {/* <!-- megamenu --> */}

                                <div className="megamenu megamenu--mega">
                                  <div className="container">
                                    <div className="row">
                                      <div className="col-md-8">
                                        <div className="megamenu-content-left">
                                          <h6 className="megamenu-l-title mb-2 pb-2">
                                            Cost and Value Philosophy
                                          </h6>
                                          <p className="megamenu-l-des">
                                            How does the Cost & Value Philosophy Work? We explain in this section. Also on the page with articles and business cases you can study an recognise its application.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mengamenu-content-right">
                                          <ul className="megamenu-submenu">
                                            <li>
                                              <NavLink to="/cost-value-philosophy">
                                                <FontAwesomeIcon icon={faArrowRight} />
                                                Cost and Value Philosophy
                                              </NavLink>
                                            </li>
                                            <li>
                                              <NavLink to="/blog-articles-on-business-cases-and-backgrounds">
                                                <FontAwesomeIcon icon={faArrowRight} />
                                                Articles & Business Cases
                                              </NavLink>
                                            </li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="menu-item">
                                <NavLink 
                                  to="/about-us"
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : undefined
                                  }
                                >
                                  <span>About Us & Themes</span>
                                </NavLink>
                              </li>
                              <li className="menu-item">
                                <NavLink
                                  to="/capex-opex-cost-program"
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : undefined
                                  }
                                >
                                  <span>Capex/Opex Program</span>
                                </NavLink>
                              </li>
                              <li className="has-children">
                                <NavLink 
                                  to="/supply-chain-by-sla"
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : undefined
                                  }
                                >
                                  <span>
                                    Supply Chain by SLA
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      className="font-10"
                                    />
                                  </span>
                                </NavLink>
                                {/* mega menu  */}
                                <div className="megamenu megamenu--mega">
                                  <div className="container">
                                    <div className="row w-100">
                                      <div className="col-md-8">
                                        <div className="megamenu-content-left">
                                          <h6 className="megamenu-l-title mb-2 pb-2">
                                            Supply Chain by Service Level Agreement
                                          </h6>
                                          <p className="megamenu-l-des">Operations, Maintenance or Production. Even Projects. Supply Chain by SLA provides dozens of services in 6 key areas related to your contracts. They all contribute to cost efficiency.​</p>
                                          <p className="megamenu-l-des">
                                            Outsource some or all of your Supply Chain and Procurement activities by Service Level Agreement.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mengamenu-content-right d-block text-left">
                                          <h6 className="megamenu-l-title mb-2 pb-2"> 
                                            <Link to="/supply-chain-by-sla" style={{ color: '#00ABB8'}}>
                                              <FontAwesomeIcon icon={faArrowRight} />  Supply Chain by SLA
                                            </Link>
                                          </h6>
                                          <ul className="megamenu-submenu ms-list-15">
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/category-strategy"><span> <FontAwesomeIcon icon={faArrowRight} />  Category Strategy & Contract Strategy</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/post-award-contract-management"><span> <FontAwesomeIcon icon={faArrowRight} />  Post Award Contract Management</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/commercial-assurance"><span> <FontAwesomeIcon icon={faArrowRight} />  Commercial Assurance</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/cost-recovery"><span> <FontAwesomeIcon icon={faArrowRight} />  Cost Recovery</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/tendering-outsourcing"><span> <FontAwesomeIcon icon={faArrowRight} />  Tendering & Outsourcing</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/materials-management"><span> <FontAwesomeIcon icon={faArrowRight} />  Materials Management</span></NavLink></li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="has-children">
                                <NavLink to="#">
                                  <span>
                                    Systems & Delivery
                                    <FontAwesomeIcon
                                      icon={faChevronDown}
                                      className="font-10"
                                    />
                                  </span>
                                </NavLink>
                                {/* <!-- multilevel submenu --> */}
                                <div className="megamenu megamenu--mega">
                                  <div className="container">
                                    <div className="row w-100">
                                      <div className="col-md-8">
                                        <div className="megamenu-content-left" >
                                          <h6 className="megamenu-l-title mb-2 pb-2">
                                            Codex & Delivery
                                          </h6>
                                          <p className="megamenu-l-des">
                                            Codex is a contracting procurement & supply chain management system and services that transforms 3 <sup>rd</sup>
                                            party goods and services into finished product in commercially competitive controlled and compliant manner.
                                          </p>
                                          <p className="megamenu-l-des">
                                            cm+ is Contract + Context And focused on the users of the contract. Enabling them to execute the contract as it was intended.
                                          </p>
                                          <p className="megamenu-l-des">
                                            <span className="theme-font">powerabode</span> Academy focuses on the extended contract user group that use & influence the contract regularly. The trainings are designed to inject commercial DNA into the team.
                                          </p>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="mengamenu-content-right" >
                                          <ul className="megamenu-submenu">
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/systems-delivery/codex-supplychainmanagmentsystem"><span>  <FontAwesomeIcon icon={faArrowRight} /> Codex</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/systems-delivery/contractmanagementtool"><span>  <FontAwesomeIcon icon={faArrowRight} />  cm+</span></NavLink></li>
                                            <li><NavLink className={({ isActive }) => isActive ? activeClassName : undefined} to="/systems-delivery/academy"><span>  <FontAwesomeIcon icon={faArrowRight} />  Academy</span></NavLink></li>
                                          </ul>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <li className="menu-item">
                                <NavLink 
                                  to="/deal-design"
                                  className={({ isActive }) =>
                                    isActive ? activeClassName : undefined
                                  }
                                >
                                  <span>Deal Design</span>
                                </NavLink>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- header right box --> */}
                <div className="header-right-box">
                  <div className="header-right-inner" id="hidden-icon-wrapper">
                    {/* <!-- header-search-form --> */}
                    <div className="header-search-form default-search">
                      <form action="#" className="search-form-top">
                        <input
                          className="search-field"
                          type="text"
                          placeholder="Search…"
                        />
                        <button className="search-submit">
                          <FontAwesomeIcon icon={faSearch} className="search-btn-icon text-color-theme"/>
                        </button>
                      </form>
                    </div>
                  </div>

                  {/* <!-- mobile menu --> */}
                  <MobileNav/>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
