import React from 'react';
import { useEffect } from "react";
import { Image } from 'react-bootstrap';
import HeroSection from '../Components/Sections/HeroSection';
import DigitalLogo from '../Images/Bg/layouts1/cm+.png';
import CmPlusLogo1 from '../Images/cm/1.png';
import CmPlusLogo2 from '../Images/cm/2.png';
import CmPlusLogo3 from '../Images/cm/3.png';
import LightBulb from '../Images/cm/bulb.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
function Cm() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Contract Management System – Power Abode</title>
                <meta
                    name="description"
                    content="CM+ is Contract + Context. And focussed on the users of the contract. Enabling them to execute the contract as it was intended. The application can be best summarized as a individual users manual for a contract."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="cm-bg"
                    title="Contract Management specifically defined for key contract users as per their job requirement."
                    pageTitleValue="CM+"
                    boxTitleValue="CM+ "
                    boxTitleValue2=" for Contract Management, Operational Excellence & Compliance"
                    boxDesValue="CM+ is Contract + Context. And focussed on the users of the contract. Enabling them to execute the contract as it was intended. The application can be best summarized as a individual users manual for a contract."
                />

                <section className="cm-about-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="position-relative">
                                    <h1 className="section-title-two">Why CM+?</h1>
                                    <p>
                                        We often hear; people don’t read the contract!
                                        Reason for this can be that the contract was not shared with them, due to confidentiality. Or the contract is too legalistic, not explanatory enough, while the user of the contract is way too busy.
                                    </p>
                                </div>
                                <div className="position-relative mt-4">
                                    <h1 className="section-title-two">Value driver!</h1>
                                    <p>
                                        We created the solution. It brings a massive reduction in out of scope spend. It drives value and savings between 20 and 30%. And, above all, improves contract execution!
                                    </p>
                                </div>
                                <div className="position-relative mt-4">
                                    <h1 className="section-title-two">Individual solution per user</h1>
                                    <p>
                                        For CM+ we produce 10 ‘chapters’. Every individual user of a contract gets his own set of relevant chapters. Some get 3, others get 7, etc. Always summarised, and with instructions, clarifications and guidance. Short and Sweet. CM+ is a key driver behind cost efficiency.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className='cm-info-graph-wrapper mt-5 position-relative overflow-hidden'>
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-4 col-md-5 col-sm-6 col-12">
                                <div className="digital-platform-logo">
                                    <Image
                                        height="auto" width="300px"
                                        src={DigitalLogo}
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="col-xl-8 col-md-7 col-sm-6 col-12">
                                <div className="cm-image-one">
                                    <Image
                                        className='w-100 h-100'
                                        src={CmPlusLogo1}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="cm-wrap-2">
                            <div className="row">
                                <div className="col-xl-7 col-md-6 col-sm-6 col-12">
                                    <div className="cm-list-wrap sla-cw-text" style={{maxWidth: '100%',width: '100%'}}>
                                        <h1 className="section-title-two text-white">Why CM+?</h1>
                                        <ul className="check-icon-list">
                                            <li className="list-item text-white  d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>People don't read the contract, but they will use CM+</span>
                                            </li>
                                            <li className="list-item text-white  d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Implementation supported by experts</span>
                                            </li>
                                            <li className="list-item text-white  d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Enables users of the contract</span>
                                            </li>
                                            <li className="list-item text-white  d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Results in cost efficiency</span>
                                            </li>
                                            <li className="list-item text-white  d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Strong focus on governance & compliance</span>
                                            </li>
                                        </ul>
                                        <h1 className="section-title-two text-white text-center">Contract + Context</h1>
                                    </div>
                                </div>
                                <div className="col-xl-5 col-md-6 col-sm-6 col-12">
                                    <div className="cm-buld-wrap text-center">
                                        <Image
                                            src={LightBulb}
                                            alt="Why- CM+? Job specific information"
                                            title="Why CM+? Designed with job specific information for frontline contract users."
                                        />    
                                    </div>                    
                                </div>
                            </div>
                        </div>

                        <div className="cm-wrap-3 position-relative">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="cm-w-one text-center position-relative">
                                        <Image
                                            className='w-100'
                                            src={CmPlusLogo3}
                                            alt="What-is-cm+? a tool that provides individual contract users with a manual of how to manage their activity for a specific contract."
                                            title="What-is-cm+? a tool that provides individual contract users with a manual of how to manage their activity for a specific contract."
                                        />   
                                        <div className="cm-w-one-title d-md-block d-none">
                                            <h1 className="section-title-two">What Does <br /> cm+ do?</h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="section-cm-wrap-4">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cm-w-two">
                                    <Image
                                        className='w-100 h-auto'
                                        src={CmPlusLogo2}
                                        alt="powerabode-digital-platform"
                                        title="powerabode supply chain and procurement management system is portal based myAbode."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Cm;