import React from 'react';
import ButtonCircle from '../Widget/ButtonCircle';
const WhyElementName = [
    { title: 'Fast Delivery', id: '1', class: 'color-primary' },
    { title: 'Your Cost is Our Business', id: '2', class: 'color-secondary' },
    { title: 'Recognised Quality (ISO Certified)', id: '3', class: 'color-primary' },
    { title: 'Frontline Experts, ex IOC', id: '4', class: 'color-secondary' },
    { title: 'Delivery, Remote Shoulder to Shoulder', id: '5', class: 'color-secondary' },
    { title: 'Off the Shelf Codex Made bespoke', id: '6', class: 'color-primary' },
    { title: 'Next Level Compliance & Governance', id: '7', class: 'color-secondary' },
    { title: 'Integrated Commercial Ecosystem', id: '8', class: 'color-primary' },
]
function WhyPowerabode() {
    return (
        <div>
            <section className="why-wrapper mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-header text-center">
                                <h1 className="section-title-two">
                                    Why <span className="theme-font">powerabode</span>?
                                </h1>
                            </div>

                            <div className="section-content ">
                                <div className="all-services-wrap">
                                    <div className="row">
                                        {WhyElementName.map((item, index) => (
                                            <div className="col-md-3 col-6" key={index}>
                                                <div className={`why-apps-point mt-5 d-flex justify-content-center pointer-event-none ${item.class}`} id={item.id}>
                                                    <ButtonCircle
                                                        titleFirst={item.title}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default WhyPowerabode;