import React from 'react';
import { useEffect } from "react";
// import HeroSection from '../../Components/Sections/HeroSection';
import Masonry from 'react-masonry-css'
import "../../Style/articles.css";
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import HeroSectionLayout1 from '../../Components/Sections/HeroSectionLayout1';
import { Helmet } from 'react-helmet-async';
function ArticlesLanding() {
    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        900: 2,
        500: 1
    };

    const ArticlesElement = [
        {
            quotesID: 1,
            quotes: 'true',
            quotesTxt: 'Show me the money',
            quotesAuth: 'Jerry Maguire',
            alt:"",
            imgTitle: ""
        },
        {
            link: '/articles-business-cases/solutions-to-influence-cost',
            label: "Philosophy",
            image: require('../../Images/articles/single/1.1.jpeg'),
            title: "Hidden in Plain sight ",
            text: "Crazy as it may sound; there’s always an elephant in the room.. Central in our way of work are the users of the contract, the ECMT. They are underestimated as a platform.",
            alt: "Solutions-to-influence-cost",
            imgTitle: "Hidden in plain Sight"
        },
        {
            quotesID: 2,
            quotes: 'true',
            quotesTxt: 'Plan the work – work the Plan',
            quotesAuth: null
        },
        {
            link: '/articles-business-cases/key-performance-indicators',
            label: "Article",
            image: require('../../Images/articles/single/2.1.jpeg'),
            title: "Conflict or Conflicting KPI’s?",
            text: "Working as a team. That’s what we do. But how do we know we are all aligned? Conflicting KPI’s cause massive inefficiencies. Who needs enemies if you have friends?",
            alt: "key-performance-indicators-kpi",
            imgTitle: "Conflict or Conflicting KPI’s?"
        },
        {
            quotesID: 3,
            quotes: 'true',
            quotesTxt: 'Our Experts know your business',
            quotesAuth: null
        },
        {
            link: '/articles-business-cases/project-management-bottle-necks',
            label: "Article",
            image: require('../../Images/articles/single/3.1.jpeg'),
            title: "Deliver as Planned",
            text: "Sometimes ‘surprises’ are actually predictable. The top 5 of ‘predictable’ surprises",
            alt: "Project-management-bottle-necks",
            imgTitle: "Deliver as Planned"
        },
        {
            quotesID: 4,
            borderBox: 'true',
            borderTitle: 'Everything is Energy.',
            borderText: ' Use it Wisely!'
        },
        {
            link: '/articles-business-cases/budget-constraints-delivery',
            label: "Case Study",
            image: require('../../Images/articles/single/4.1.jpeg'),
            title: "Deliver despite budget constraints",
            text: "Every supply chain has waste, we fix it. Complex analyses using our CI Philosophy, double digit improvement to your budget and opex, value optimisation",
            alt: "budget-constraints-delivery",
            imgTitle: "Deliver despite budget constraints"
        },
        {
            link: '/articles-business-cases/focus-on-rates',
            label: "Thinking",
            image: require('../../Images/articles/single/5.1.jpeg'),
            title: "Does focus on rates make sense?",
            text: "Cost Leadership will remain one of top 3 focus areas of Energy Industry for next decade. We excel in this area",
            alt: "Iceberg: With cost and value, there is more than meets the eye.",
            imgTitle: "it takes experts to assess and transfer this into competitive landscape. Opex Capex optimisation."
        },
       
        {
            link: '/articles-business-cases/discount-price-reduction',
            label: "Case Study",
            image: require('../../Images/articles/single/6.1.jpeg'),
            title: "Drilling Cost Transformation",
            text: "Strategy and sourcing define the outcome. We advice and built your delivery model, including the tendering model",
            alt: "discount-price-reduction",
            imgTitle: "Does focus on rates make sense"
        },
        
        {
            link: '/articles-business-cases/supply-Chain-Procurement-Management-System',
            label: "Case Study",
            image: require('../../Images/articles/single/7.1.jpeg'),
            title: "Getting the supply chain management and procurement system right",
            text: "Off the shelf we developed a Codex system to support your delivery. Front line experts will adapt it for you, with a view",
            alt: "Supply-Chain-Procurement-Management-System",
            imgTitle: "Getting Supply Chain Management and procurement system right"
        },

        {
            link: '/articles-business-cases/performance-dna-procurement-supply-chain-department',
            label: "Article",
            image: require('../../Images/articles/single/8.1.jpeg'),
            title: "How the DNA of your procurement department sets its performance",
            text: "Is your function led by operations? By finance? Projects? Legal? They bring their DNA. We transform it to one driven by commercial DNA, getting cost and value efficiency",
            alt: "Performance-dna-procurement- supplychain-department",
            imgTitle: "How the DNA of your Procurement Department Sets its Performance"
        },
        {
            quotesID: 5,
            quotes: 'true',
            quotesTxt: 'Producing Cost and Carbon Efficient barrels is our role in the future energy landscape',
            quotesAuth: 'HE dr Sultan Ahmed Al-Jaber, ceo ADNOC'
        },
        {
            link: '/articles-business-cases/materials-management',
            label: "Article",
            image: require('../../Images/articles/single/9.1.jpeg'),
            title: "Materials Management; a showstopper or a money maker?",
            text: "As an asset gets older, how to keep performing? Increased pressure on maintenance, legacy parts",
            alt: "Materials-Management",
            imgTitle: "Materials Management: A showstopper or Money Maker?"
        },
        {
            link: '/articles-business-cases/businessplan',
            label: "Case Study",
            image: require('../../Images/articles/single/10.1.jpeg'),
            title: "Misalignment with the businessplan",
            text: "Not everyone knows the businessplan. This information is confidential or under evaluation. But, in the operations latest information is key for opex and capex decisions. How to deal with misalignment?",
            alt: "businessplan",
            imgTitle: "Misalignment with the business plan"
        },
        {
            label: "Philosophy",
            link: '/articles-business-cases/unlocking-value-savings-bottom-line',
            image: require('../../Images/articles/single/11.1.jpeg'),
            title: "Unlocking Value to your bottom line",
            text: "Identifying a saving potential is a small step. The bigger thing is to bring it to your bottomline. We use the Integrated Supply Chain, the structure of our Cost Intelligence Philosophy and the people that use the contract to",
            alt: "Misalignment with the business plan",
            imgTitle: "Unlocking Value to your bottom line"
        },
        {
            link: '/articles-business-cases/sourcing-strategy',
            label: "Case Study",
            image: require('../../Images/articles/single/12.1.jpeg'),
            title: "Strategic Sourcing is not a Sourcing Strategy",
            text: "Digitisation is key, but good old human intelligence is still key in defining the strategy. Artificial Intelligence is quickly gaining pace, but",
            alt: "sourcing-strategy",
            imgTitle: "Strategic Sourcing is not Sourcing Strategy"
        },
        {
            link: '/articles-business-cases/the-art-of-the-deal-outsourcing-supply-chain',
            label: "Article",
            image: require('../../Images/articles/single/13.1.jpeg'),
            title: "The art of the deal",
            text: "Tendering and outsourcing are where a big impact is created for future performance. Call it the art of the deal. One of our SLA service",
            alt: "the-art-of-the-deal-outsourcing-supply-chain",
            imgTitle: "The Art of the Deal – Tendering and Outsourcing"
        },
       
        {
            link: '/articles-business-cases/local-content-development',
            label: "Case Study",
            image: require('../../Images/articles/single/14.1.jpeg'),
            title: "The synergy of local content development",
            text: "NOC’s have a close connect to the economic development of their country. Our services empower and progress the commercial control on Capex and Opex, but also weave in local content",
            alt: "local-content-development",
            imgTitle: "The synergy of local content development"
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Articles and Business Cases – Power Abode</title>
                <meta
                    name="description"
                    content="Here we share several articles on business cases and backgrounds that will help you with cost-effective delivery. Power Abode helps to start."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionLayout1
                    className="overflow-hidden"
                    title="Series of blogs, articles and business cases in support of outsourcing of supply chain and providing background to achieving cost efficiency in maintenance, operations and production of oil and gas assets. "
                    pageTitleName="Articles, Business Cases and Background"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="articles-landing-hero-bg"
                    boxTitleValue="Articles, Business Cases "
                    boxTitleValue2=" & Background"
                />

            <section className="all-articles-section mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="all-articles-wrapper">
                                <Masonry
                                    breakpointCols={breakpointColumnsObj}
                                    className="my-masonry-grid"
                                    columnClassName="my-masonry-grid_column"
                                >
                                    {ArticlesElement.map((item, index) => (
                                        <Card key={index} id={index} className="single-articles-wrap">
                                            {item.image && (
                                                <Link to={item.link}  className="position-relative">
                                                    <Card.Img 
                                                        variant="top" 
                                                        src={item.image}
                                                        alt=""
                                                        title="" 
                                                    />
                                                    <Card.Body>
                                                        <Card.Title>{item.title}</Card.Title>
                                                        <Card.Text>
                                                            {item.text} <span className='text-info font-bold' style={{ fontSize: '20px'}}>{'>>>'}</span>
                                                        </Card.Text>
                                                    </Card.Body>
                                                    <div className="articles-label px-3 py-3 badge rounded-pill bg-primary-blue">
                                                        <span>{item.label}</span>
                                                    </div>
                                                </Link>
                                            )}
                                            {item.quotes && (
                                                <div id={item.quotesID} className='quotes-wrapper theme-bg-secondary text-center d-flex justify-content-center align-content-center flex-column'>
                                                    <p className='text-white'>
                                                        <q>{item.quotesTxt}</q>
                                                    </p>
                                                    {item.quotesAuth ? 
                                                        <span className='text-white'>
                                                            - {item.quotesAuth}
                                                        </span>
                                                    : null}
                                                </div>
                                            )}

                                            {item.borderBox && (
                                                <div className="border-l-wrapper" id={item.quotesID}>
                                                    <Card.Title className='text-white'>
                                                        {item.borderTitle}
                                                    </Card.Title>
                                                    <Card.Text className='text-white'>
                                                        {item.borderText}
                                                    </Card.Text>
                                                </div>
                                            )}
                                            
                                        </Card>
                                    ))}
                                </Masonry>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </div>
        </>
    );
}

export default ArticlesLanding;