import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from "react";
import { Image } from 'react-bootstrap';
import HeroSection from '../../Components/Sections/HeroSection';
import SupplyChainSlider from '../../Components/Sections/SupplyChainSlider';
import ResultBg from "../../Images/banner/hozizontal-slider/codex.webp";
import CoverPhoto1 from "../../Images/Supply-chain-sla/Supply-Chain-by-SLA-Lower.webp";

import Background from "../../Images/sla/5.webp";

// case study assets
import "../../Components/Style/case-studies.css";
import CaseStudiesPhoto1 from "../../Images/articles/single/9.1.jpeg";
import CaseStudiesPhoto2 from "../../Images/articles/single/13.1.jpeg";
import CaseStudiesPhoto3 from "../../Images/articles/single/11.1.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

function MaterialsManagement() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Materials Management - Powerabode</title>
                <meta
                    name="description"
                    content="An accurate material management system makes your project dynamic & cost-efficient. Our experts give you a complete material management solution."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="materials-bg"
                    pageTitleValue="Materials Management"
                    boxTitleValue="Materials "
                    boxTitleValue2=" Management"
                    boxDesValue="The right goods at the right place, at the right time. Today, but also in 5 years. Is Materials Management a cost, or can it make you money?"
                    title="Materials-management, outsourced by Service Level Agreement, Maximum plant uptime. No unplanned corrective maintenance. Predictive preventive planned maintenance. -- Maximum plant uptime. No unplanned corrective maintenance. Always predictive preventive planned maintenance."
                />

                <div className="section sla-service-wrapper overflow-hidden d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6 pr-0">
                                            <div className="sla-img-l-box position-relative">
                                                <Image
                                                    style={{ maxHeight: "500px", objectFit: "cover" }}
                                                    width="100%" height="auto"
                                                    src={ResultBg}
                                                    alt=''
                                                />
                                                <div className="sla-img-absolute-txt">
                                                    <h1 className='section-title-two text-white'>
                                                        The Result
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <div className="sla-txt-r-box bg-dark-400 p-4 h-100 d-flex justify-content-center align-content-center flex-column">
                                                <ul className="check-icon-list">
                                                    <li className="list-item text-white d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Unlock Cash & free up working capital</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Inventory optimisation</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Map asset & inventory; secure supply</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Fully trained ECMT for Materials & Inventory</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-about-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div 
                                    className="sla-content-wrap" 
                                    style={{ background: `url("${Background}")` }}
                                    title="What are the 7 step program of Materials Management?"
                                >
                                    <div className="sla-cw-text">
                                        <h1 className='section-title-two text-white'>
                                            The Result
                                        </h1>
                                        <ul className="check-icon-list mt-3">
                                            <li className="list-item text-white d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Unlock Cash & free up working capital</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Inventory optimisation</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Map asset & inventory; secure supply</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Fully trained ECMT for Materials & Inventory</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sla-cw-footer-text">
                                        <h1 className="section-title-two text-white">
                                            Materials Management
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sla-service-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            
                            <div className="col-md-12">
                                <div className="section-header position-relative ">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            Materials in the right place, at the right time
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="section-title-two">
                                                Our <span className="text-color-primary"> Scope :</span>
                                            </h1>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ minHeight: '298px', marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">7 Step program</h5>
                                                        <p className='mt-3'>
                                                            A transformational journey of your
                                                            Materials Management. At pre-award or at post award stage, it is equally
                                                            important for operational/ commercial
                                                            performance. From cost to maintenance execution, it can be a Show Stopper. 
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center" >
                                                        <h5 className="heading">Inventory Optimisation</h5>
                                                        <p className='mt-3'>
                                                            Unlock cash and prevent it from getting locked again! Our inventory optimisation links your operational  , integrated activity plan, contracts in ERP, materials availability, lead time, logistics plan, stocking level, inventory forecast, inventory management and management
                                                            of change. <span className="theme-font">powerabode</span> sets up and
                                                            implements your entire MM Optimisation.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ minHeight: '298px', marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">System & assurance</h5>
                                                        <p className='mt-3'>
                                                            We built you a fully functional system,
                                                            best practice based. How to operate
                                                            materials management in the field
                                                            including guidelines, processes,
                                                            procedures, work instructions and forms.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            Focus on Supply Chain Resilience
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Data</h5>
                                                        <p className='mt-3'>
                                                            From data clean up, to capturing, to
                                                            analysis. MM is not just about a
                                                            warehouse. Let us open the black box. It is actually very exciting!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Expediting</h5>
                                                        <p className='mt-3'>
                                                            Qualified experts and processes for
                                                            expediting international complex
                                                            projects.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4  col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Training</h5>
                                                        <p className='mt-3'>
                                                            <span className="theme-font">powerabode</span> Academy is developing a set of training modules regarding MM for 2021.
                                                            Sign up now to keep informed!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-2">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            What Covid learnings have you implemented?
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SupplyChainSlider />

                <div className="container-fluid mt-5 position-relative">
                    <Image
                        width="100%" height="auto"
                        src={CoverPhoto1}
                        alt=""
                    />
                    <div className="box-txt-top-left-absolute">
                        <h1 className='section-title-two'>
                            Related Articles and Case Studies
                        </h1>
                    </div>
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <div className="case-studies-section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h1 className="section-title-two">
                                            Top <span className="text-color-theme">Articles </span>
                                            &
                                            <span className="text-color-theme"> Case Studies</span>
                                        </h1>
                                    </div>
                                    <div className="section-content mt-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-9"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Materials Management; a showstopper or a money maker?</h5>
                                                            <p className="text-white">As an asset gets older, how to keep performing? Increased pressure on maintenance, legacy parts...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-13"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">The art of the deal</h5>
                                                            <p className="text-white">Tendering and outsourcing are where a big impact is created for future performance. Call it the art of the deal. One of our SLA service...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-11"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Unlocking Value to your bottom line</h5>
                                                            <p className="text-white">Identifying a saving potential is a small step. The bigger thing is to bring it to your bottomline. We use the Integrated Supply Chain, the structure of our Cost Intelligence Philosophy and the people that use the contract to...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MaterialsManagement;