import { Image } from "react-bootstrap";
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import photo from "../../Images/articles/TenderingAndOutsourcing.png";
import photo1 from "../../Images/articles/The-Art-of-the-Deal-Graph.jpeg";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles13() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The 4 Crucial Advantages of the Inhouse Tenders You Should Know. - Powerabode</title>
                <meta
                    name="description"
                    content="Tendering and outsourcing are where a big impact is created for future performance. Call it the art of the deal. One of our SLA service…"
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles13-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="The Art of the Deal – Tendering and Outsourcing"
                    boxTitleLabel="Article >"
                    title="the-art-of-the-deal-outsourcing-supply-chain"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Tendering mistakes. Cut a corner today, pay for it for the lifetime of the new contract.
                                    </h1>
                                
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={photo}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Even straight contract renewal should be done with diligence. Things evolve.
                                            </h3>
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">

                                                        <p>
                                                            Tendering and Outsourcing activities for large Energy Assets are generally done inhouse. There are
                                                            arguments in favour of such decision. We have taken a closer look to understand the advantages of
                                                            having inhouse Tenders. We selected top 4 criteria that drive performance: Scope of Work, KPI,
                                                            Evaluation and Remuneration models. Following are our observations:
                                                        </p>

                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">30% tenders were freshly made with correct parameters</li>
                                                            <li className="list-item">7% tenders were a one on one copy from the old tender</li>
                                                            <li className="list-item">In 22% cases, old scope of work was used without revision</li>
                                                            <li className="list-item">10% cases, old evaluation models were applied despite changing cost and value drivers</li>
                                                            <li className="list-item">11% of the tenders had old KPIs which conflicted with the delivery requirements</li>
                                                            <li className="list-item">And in 20% tenders, previous remuneration model was applied</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center mt-5">
                                                <Image
                                                    height="auto" width="100%"
                                                    src={photo1}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Let’s briefly touch upon 2 of these:</p>
                                                        <h5 className="heading">
                                                            Evaluation Model:
                                                        </h5>
                                                        <p>
                                                            Evaluation model is one of the key defence barriers for any Project Manager. It helps to objectively
                                                            choose the right Contractor.
                                                        </p>

                                                        <h5 className="heading">
                                                            Remuneration Model:
                                                        </h5>
                                                        <p>
                                                            Remuneration is one of the key tools to drive proper Contract Execution and overall Performance. It
                                                            stimulates contractor behaviour.
                                                        </p>

                                                        <h5 className="heading">
                                                            Solution:
                                                        </h5>
                                                        <p>
                                                            Building Evaluation and Remuneration Model is a craft; Members of our Tender team do nothing
                                                            else but develop these Models. Tendering & Outsourcing is one of the most used products of our
                                                            Supply Chain by SLA Services. We write Scope of Work, design tender models, compose contract
                                                            manuals, build evaluation and remuneration models. Every service line is aimed at delivering optimal
                                                            Contract performance.
                                                        </p>

                                                        <p>
                                                            The <span className="theme-font">powerabode</span> experts would be happy to engage with you to show their solutions. Set up a
                                                            Teams meeting through <span className="text-color-theme">info@powerabode.com</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                    <div className="w-100 mt-5">
                                        <h3 className="section-sub-title mt-1 text-center">
                                            This is what we do. And we do this for a reason
                                        </h3>
                                        <div className="d-flex justify-content-center gap-3 mt-4 flex-wrap">
                                            <ButtonCircle
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircle
                                                url="/tendering-outsourcing"
                                                titleFirst="Tendering & Outsourcing"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircle
                                                url="/category-strategy"
                                                titleFirst="Strategy"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircle
                                                url="/systems-delivery/contractmanagementtool"
                                                DynamicBtnColor="btn-color-5 mb-3"
                                                image={logo5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles13;