
/* eslint-disable eqeqeq */
import { useState } from "react";
import "../Style/supply-chain-slider.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";


import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

const SliderData = [
    {
        id: "1",
        link: '/articles-business-cases/solutions-to-influence-cost',
        image: require('../../Images/articles/single/1.1.jpeg'),
        title: "Hidden plain Sight",
    },
    {
        id: "2",
        link: '/articles-business-cases/key-performance-indicators',
        image: require('../../Images/articles/single/2.1.jpeg'),
        title: "Conflict or Conflicting KPI’s?",
    },
    {
        id: "3",
        link: '/articles-business-cases/project-management-bottle-necks',
        image: require('../../Images/articles/single/3.1.jpeg'),
        title: "Deliver as Planned",
    },
    {
        id: "4",
        link: '/articles-business-cases/budget-constraints-delivery',
        image: require('../../Images/articles/single/4.1.jpeg'),
        title: "Deliver despite budget constraints",
    },
    {
        id: "5",
        link: '/articles-business-cases/focus-on-rates',
        image: require('../../Images/articles/single/5.1.jpeg'),
        title: "Does focus on rates make sense?",
    },

    {
        id: "6",
        link: '/articles-business-cases/discount-price-reduction',
        image: require('../../Images/articles/single/6.1.jpeg'),
        title: "Drilling Cost Transformation",
    },

    {
        id: "7",
        link: '/articles-business-cases/supply-Chain-Procurement-Management-System',
        image: require('../../Images/articles/single/7.1.jpeg'),
        title: "Getting Supply Chain Management and procurement system right",
    },

    {
        id: "8",
        link: '/articles-business-cases/performance-dna-procurement-supply-chain-department',
        image: require('../../Images/articles/single/8.1.jpeg'),
        title: "How the DNA of your Procurement Department Sets its Performance",
        text: "Is your function led by operations? By finance? Projects? Legal? They bring their DNA. We transform it to one driven by commercial DNA, getting cost and value efficiency",
    },
    {
        id: "9",
        link: '/articles-business-cases/materials-management',
        image: require('../../Images/articles/single/9.1.jpeg'),
        title: "Materials Management: A showstopper or Money Maker?",
    },
    {
        id: "10",
        link: '/articles-business-cases/businessplan',
        image: require('../../Images/articles/single/10.1.jpeg'),
        title: "Misalignment with the business plan",
    },
    {
        id: "11",
        link: '/articles-business-cases/unlocking-value-savings-bottom-line',
        image: require('../../Images/articles/single/11.1.jpeg'),
        title: "Unlocking Value to your bottom line",
    },
    {
        id: "12",
        link: '/articles-business-cases/sourcing-strategy',
        image: require('../../Images/articles/single/12.1.jpeg'),
        title: "Strategic Sourcing is not Sourcing Strategy",
    },
    {
        id: "14",
        link: '/articles-business-cases/local-content-development',
        image: require('../../Images/articles/single/14.1.jpeg'),
        title: "The synergy of local content development",
    },
    {
        id: "13",
        link: '/articles-business-cases/the-art-of-the-deal-outsourcing-supply-chain',
        image: require('../../Images/articles/single/13.1.jpeg'),
        title: "The art of the deal",
    },
]
function RelatedArticlesSlider(props) {
    const [swiperText0, setSwiperText0] = useState(false);
    const [swiperText1, setSwiperText1] = useState(false);
    const [swiperText2, setSwiperText2] = useState(false);
    const [swiperText3, setSwiperText3] = useState(false);
    const [swiperText4, setSwiperText4] = useState(false);
    const [swiperText5, setSwiperText5] = useState(false);
    const [swiperText6, setSwiperText6] = useState(false);
    const [swiperText7, setSwiperText7] = useState(false);
    const [swiperText8, setSwiperText8] = useState(false);
    const [swiperText9, setSwiperText9] = useState(false);
    const [swiperText10, setSwiperText10] = useState(false);
    const [swiperText11, setSwiperText11] = useState(false);
    const [swiperText12, setSwiperText12] = useState(false);
    const [swiperText13, setSwiperText13] = useState(false);

    const CheckActiveItemIndex = (e) => {
        if (e.realIndex == '0') { setSwiperText0(true); } else { setSwiperText0(false); }
        if (e.realIndex == '1') { setSwiperText1(true); } else { setSwiperText1(false); }
        if (e.realIndex == '2') { setSwiperText2(true); } else { setSwiperText2(false); }
        if (e.realIndex == '3') { setSwiperText3(true); } else { setSwiperText3(false); }
        if (e.realIndex == '4') { setSwiperText4(true); } else { setSwiperText4(false); }
        if (e.realIndex == '5') { setSwiperText5(true); } else { setSwiperText5(false); }
        if (e.realIndex == '6') { setSwiperText6(true); } else { setSwiperText6(false); }
        if (e.realIndex == '7') { setSwiperText7(true); } else { setSwiperText7(false); }
        if (e.realIndex == '8') { setSwiperText8(true); } else { setSwiperText8(false); }
        if (e.realIndex == '9') { setSwiperText9(true); } else { setSwiperText9(false); }
        if (e.realIndex == '10') { setSwiperText10(true); } else { setSwiperText10(false); }
        if (e.realIndex == '11') { setSwiperText11(true); } else { setSwiperText11(false); }
        if (e.realIndex == '12') { setSwiperText12(true); } else { setSwiperText12(false); }
        if (e.realIndex == '13') { setSwiperText13(true); } else { setSwiperText13(false); }

        // console.log(e.realIndex)
    }
    return (
        <section className="related-articles-slider mt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section-header">
                            <h1 className="section-title-two text-center">
                                More Articles & <span className="text-color-primary">Business Cases?</span>
                            </h1>
                        </div>
                        <div className="section-content mt-5 supply-chain-sla-slider ">
                            <Swiper
                                spaceBetween={15}
                                centeredSlides={true}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={true}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                                breakpoints={{
                                    900: {
                                        slidesPerView: 3,
                                    },
                                    800: {
                                        slidesPerView: 2,
                                    },
                                    500: {
                                        slidesPerView: 1,
                                    }
                                }}
                                onSlideChange={(e) => CheckActiveItemIndex(e)}
                               
                            >
                                {SliderData.map((item, index) => (
                                    <div key={index}>
                                        <SwiperSlide key={index}>
                                            <Link to={item.link} className="w-100">
                                                <Image
                                                    className="w-100"
                                                    src={item.image}
                                                    alt=""
                                                />
                                                <div className="swiper-text">
                                                    <h4>
                                                        {item.title}
                                                    </h4>
                                                </div>
                                            </Link>
                                        </SwiperSlide>
                                    </div>

                                ))}
                            </Swiper>
                            <br />
                            <div className="slider-per-content px-4 text-center">
                                {swiperText0 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title" ><span className="text-color-theme">Hidden in Plain sight </span></h3>
                                        <p>Crazy as it may sound; there’s always an elephant in the room.. Central in our way of work are the users of the contract, the ECMT. They are underestimated as a platform. 9 Quote: Our Experts know your business</p>
                                    </div>
                                )}
                                {swiperText1 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"><span className="text-color-theme">Conflict or Conflicting KPI’s?</span></h3>
                                        <p>Working as a team. That’s what we do. But how do we know we are all aligned? Conflicting KPI’s cause massive inefficiencies. Who needs enemies if you have friends? <span className="theme-font">powerabode</span> can...</p>
                                    </div>
                                )}
                                {swiperText2 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"><span className="text-color-theme">Deliver as Planned</span></h3>
                                        <p>Sometimes ‘surprises’ are actually predictable. The top 5 of ‘predictable’ surprises...</p>
                                    </div>
                                )}
                                {swiperText3 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"><span className="text-color-theme">Deliver despite budget constraints</span></h3>
                                        <p>Every supply chain has waste, we fix it. Complex analyses using our CI Philosophy, double digit improvement to your budget and opex, value optimisation...</p>
                                    </div>
                                )}
                                {swiperText4 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Does focus on rates make sense?</span></h3>
                                        <p>
                                            Cost Leadership will remain one of top 3 focus areas of Energy Industry for next decade. We excel in this area
                                        </p>
                                    </div>
                                )}
                                {swiperText5 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Drilling Cost Transformation</span></h3>
                                        <p>
                                            Strategy and sourcing define the outcome. We advice and built your delivery model, including the tendering model…
                                        </p>
                                    </div>
                                )}
                                {swiperText6 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Getting the supply chain management and procurement system right</span></h3>
                                        <p>
                                            Off the shelf we developed a Codex system to support your delivery. Front line experts will adapt it for you, with a view...
                                        </p>
                                    </div>
                                )}
                                {swiperText7 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">How the DNA of your procurement department sets its performance</span></h3>
                                        <p>
                                            Is your function led by operations? By finance? Projects? Legal? They bring their DNA. We transform it to one driven by commercial DNA, getting cost and value efficiency...
                                        </p>
                                    </div>
                                )}
                                {swiperText8 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Materials Management; a showstopper or a money maker?</span></h3>
                                        <p>
                                            As an asset gets older, how to keep performing? Increased pressure on maintenance, legacy parts.
                                        </p>
                                    </div>
                                )}
                                {swiperText9 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Misalignment with the business plan.</span></h3>
                                        <p>
                                            Not everyone knows the businessplan. This information is confidential or under evaluation. But, in the operations latest information is key for opex and capex decisions. How to deal with misalignment?...
                                        </p>
                                    </div>
                                )}
                                {swiperText10 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Unlocking Value to your bottom line</span></h3>
                                        <p>
                                            Identifying a saving potential is a small step. The bigger thing is to bring it to your bottomline. We use the Integrated Supply Chain, the structure of our Cost Intelligence Philosophy and the people that use the contract to…
                                        </p>
                                    </div>
                                )}
                                {swiperText11 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">Strategic Sourcing is not a Sourcing Strategy</span></h3>
                                        <p>
                                            Digitisation is key, but good old human intelligence is still key in defining the strategy. Artificial Intelligence is quickly gaining pace, but...
                                        </p>
                                    </div>
                                )}

                                {swiperText12 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">The synergy of local content development</span></h3>
                                        <p>
                                            NOC’s have a close connect to the economic development of their country. Our services empower and progress the commercial control on Capex and Opex, but also weave in local content. NOC’s are supported on their journey to become independent operators. We have strong…
                                        </p>
                                    </div>
                                )}
                                {swiperText13 && (
                                    <div className="single-slider-wrap">
                                        <h3 className="section-sub-title"> <span className="text-color-theme">The art of the deal.</span></h3>
                                        <p>
                                            Tendering and outsourcing are where a big impact is created for future performance. Call it the art of the deal. One of our SLA service…
                                        </p>
                                    </div>
                                )}
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default RelatedArticlesSlider;