import { Image } from "react-bootstrap";
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import photo from "../../Images/articles/CommercialAssurance.webp";
import WhyPowerabode from "../../Components/Sections/WhyPowerabode";
import logo4 from "../../Images/Bg/layouts1/cms.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles8() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Contracting & Procurement Issues Solutions by Supply Chain Experts - Powerabode</title>
                <meta
                    name="description"
                    content="Is your function led by operations? By finance? Projects? Legal? They bring their DNA. We transform it to one driven by commercial DNA, getting cost and value efficiency..."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles8-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="How the DNA of your Procurement Department Sets its Performance"
                    boxTitleLabel="Article >"
                    title="Performance-dna-procurement- supplychain-department"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Optimise your Contracting and Procurement using Supply Chain Experts
                                    </h1>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={photo}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Frontline Experts use <span className="theme-font">powerabode</span> CODEX to create your bespoke Supply Chain Management System
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            The Brief
                                                        </h5>
                                                        <p>Contracting & Procurement or Supply Chain can be a separate department or placed under Projects, Asset, Legal or Finance. Each will give a different culture and result in its own challenges and biases. Only in a few cases it is at Executive Board Level – even where spend is in the multi-billion US$ .</p>
                                                        <p>An Asset/ Production C&P department is likely to be heavily focussed on Contract execution, a Finance CP department on cost and compliance, a Projects CP department on sourcing and tendering, Legal on standard Terms and conditions and claims handling. A standalone CP department, suitably lead, will have a broader understanding of Supply Chain – but senior level SCM professionals are hard to find. There are no easy solutions.</p>
                                                        <p>CP Audits or “commercial assurance” looks at the capabilities of the CP/Supply Chain department around the whole procurement cycle from Planning, through establishing contract strategies and bid lists, through tendering to award, mobilisation, contract execution and close out. It will also look at the quality of the key internal relationships. No Company’s CP department will be strong in all areas.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            Common failings are:
                                                        </h5>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">A one size fits all approach increasing complexity for minor spend, and not giving a tailored enough approach for major projects.</li>
                                                            <li className="list-item">Disconnects with finance on planning and budgets.</li>
                                                            <li className="list-item">Poor market knowledge and use of that in determining contract strategies and bid-lists.</li>
                                                            <li className="list-item">Lengthy Procurement times</li>
                                                            <li className="list-item">Complex routine procurement – high numbers of low value transactions, POs and poor stock control</li>
                                                            <li className="list-item">A lack of clarity on volumes and scope</li>
                                                            <li className="list-item">Inadequate challenge and communication with technical departments</li>
                                                            <li className="list-item">Technical and commercial evaluation models are poorly constructed.</li>
                                                            <li className="list-item">Document control and handling of external communication resulting in disputes and claims or poor audit trail for regulators.</li>
                                                            <li className="list-item">Document review processes are lengthy or low quality.</li>
                                                            <li className="list-item">Little or no structured post award contract management.</li>
                                                            <li className="list-item">Work acceptance criteria and close out is not clearly defined.</li>
                                                            <li className="list-item">Non-standard terms and conditions and insufficient expertise to resolve qualifications.</li>
                                                            <li className="list-item">Compliance with required standards and due diligence.</li>
                                                            <li className="list-item">The need to resort to expensive external counsel in claims mitigation.</li>
                                                        </ul>


                                                        <h5 className="heading">
                                                            The Solution
                                                        </h5>
                                                        <p>The full portfolio in <span className="theme-font">powerabode</span> Supply Chain by SLA remedies the typical findings that come out of audits on any procurement department.</p>

                                                        <h5 className="heading">
                                                            Why <span className="theme-font">powerabode</span>?
                                                        </h5>
                                                        <p>Because we focus on specialism. The products are dedicated to achieve cost efficiency, compliance and use frontline experts utilising industry best practice. The productised services allow for quick and cost efficient application.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="w-100 mt-5">
                                        <div className="section-header mb-5">
                                            <h1 className="section-title-two text-center">
                                                Visit these services for more detail:
                                            </h1>
                                        </div>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircle
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircle
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                            <ButtonCircle
                                                url="/category-strategy"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                titleFirst="Contract and Category Strategy"
                                                slaLogoStatus="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <WhyPowerabode />

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles8;