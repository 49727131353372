import { Link } from "react-router-dom";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { Accordion } from "react-bootstrap";
function Footer() {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <>
            <div className="footer-area-wrapper reveal-footer bg-gray d-none d-md-block">
                <div className="footer-area section-space--pt_80 section-space--pb_30">
                    <div className="container">
                        <div className="row footer-widget-wrapper">
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-color-theme">Industries</h6>
                                <ul className="footer-widget__list">
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Gas</li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Oil </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Desalination </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Renewables </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Solar </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Wind </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Hydrogen </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> LNG </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Refineries </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Processing Plants </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Upstream </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Downstream </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Offshore </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Onshore </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-color-theme">Departments</h6>
                                <ul className="footer-widget__list">
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Finance </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> C&P </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Procurement </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Operations </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Maintenance </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Production </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Strategy </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-color-theme">Stakeholders</h6>
                                <ul className="footer-widget__list">
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> CEO </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> CFO </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> COO </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Asset manager </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Project manager </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Project director </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Finance director </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Functional lead </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Operations director </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Maintenance manager </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Production manager </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Procurement manager </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget">
                                <h6 className="footer-widget__title mb-20 text-color-theme">Countries</h6>
                                <ul className="footer-widget__list">
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Kazakhstan </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Netherlands </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> United Kingdom </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Norway </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Angola </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Ghana </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Nigeria </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> United Arab Emirates </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Qatar  </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Bahrain  </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Brunei  </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Malaysia  </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Indonesia  </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Vietnam  </li>
                                    <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight}/> Saudi Arabia  </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area section-space--pb_30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 text-md-start">
                                <span className="copyright-text">
                                    <Link to="/disclaimer">Disclaimer</Link> | <Link to="/cookie-privacy-policy"> Cookie & Privacy Policy</Link> | <Link to="/copyright"> Copyright</Link> |  &copy;
                                    <Link to="/" className="text-color-theme" style={{ color: '#00ABB8'}}>
                                        powerabode.com  
                                    </Link> {year}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer-area-wrapper reveal-footer bg-gray d-block d-md-none">
                <div className="footer-area section-space--pt_80 section-space--pb_30">
                    <Accordion defaultActiveKey="0">
                        <div className="container faq-wrapper">
                            <div className="row footer-widget-wrapper">
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget mb-0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Industries</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="footer-widget__list">
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Gas</li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Oil </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Desalination </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Renewables </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Solar </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Wind </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Hydrogen </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> LNG </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Refineries </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Processing Plants </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Upstream </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Downstream </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Offshore </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Onshore </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget mb-0">
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Departments</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="footer-widget__list">
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Finance </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> C&P </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Procurement </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Operations </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Maintenance </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Production </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Strategy </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget ">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Stakeholders</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="footer-widget__list">
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> CEO </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> CFO </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> COO </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Asset manager </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Project manager </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Project director </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Finance director </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Functional lead </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Operations director </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Maintenance manager </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Production manager </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Procurement manager </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                                <div className="col-lg-3 col-md-4 col-sm-6 col-6 footer-widget">
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header>Countries</Accordion.Header>
                                        <Accordion.Body>
                                            <ul className="footer-widget__list">
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Kazakhstan </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Netherlands </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> United Kingdom </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Norway </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Angola </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Ghana </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Nigeria </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> United Arab Emirates </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Qatar  </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Bahrain  </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Brunei  </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Malaysia  </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Indonesia  </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Vietnam  </li>
                                                <li className="hover-style-link"><FontAwesomeIcon icon={faAngleRight} /> Saudi Arabia  </li>
                                            </ul>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </div>
                            </div>
                        </div>
                    </Accordion>
                </div>
                <div className="footer-copyright-area section-space--pb_30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12 text-md-start">
                                <span className="copyright-text">
                                    <Link to="/disclaimer">Disclaimer</Link> | <Link to="/cookie-privacy-policy"> Cookie & Privacy Policy</Link> | <Link to="/copyright"> Copyright</Link> |  &copy;
                                    <Link to="/" className="text-color-theme" style={{ color: '#00ABB8'}}>
                                        powerabode.com  
                                    </Link> {year}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Footer;