import { Image } from "react-bootstrap";
import "../Style/supply-chain-by-sla.css";
import photo1 from "../Images/Bg/bg-6.png";
import SupplyChainSlider from "../Components/Sections/SupplyChainSlider";
import {useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons";
import IconBoxSla from "../Components/Sections/iconBoxSla";
import HeroSectionLayout1 from "../Components/Sections/HeroSectionLayout1";
import { Helmet } from "react-helmet-async";

function SupplyChainBySla() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Outsource Supply Chain and Procurement to us – Power Abode</title>
                <meta
                    name="description"
                    content="Operations, Maintenance and Production. Supply Chain by SLA offers 6 key areas with dozens of services. The Service Level Agreement ensures the client to get expert services, when they need it. It is cost efficient, but also allows for expertise that is not always available in-house."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSectionLayout1
                    className="overflow-hidden"
                    pageTitleName="Supply Chain by SLA"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="supply-chain-bg"
                    boxTitleValue="Supply Chain by"
                    boxTitleValue2=" SLA"
                    boxDesValue="Always an expert in house. Set excellence for integrated supply chain in over 40 services. All by Service Level Agreement (SLA). Always delivered by experts. When you need it. Driven by energy."
                    title="Supply-chain-by-SLA Outsourcing critical supply chain activities to experts from Shell, TotalEnergies, BP and Exxon IOC background. |Supply Chain by Service Level Agreement. Outsourcing Procurement and Supply Chain."
                />
                
                <IconBoxSla/>

                <section className="export-services-areas mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <div className="row">
                                        <div className="col-md-5">
                                            <h1 className="section-title-two">
                                                <span className="text-color-theme">30+ ex IOC</span> Experts <br />
                                                <span className="text-color-theme">40+</span> Services <br />
                                                <span className="text-color-theme">Delivery </span>Outsourced
                                            </h1>
                                        </div>
                                        <div className="col-md-7">
                                            <h1 className="section-title-two">
                                                <span className="text-color-theme">For</span> Energy Assets <br />
                                                <span className="text-color-theme">Oil Gas </span> Power Water Renewables <br />
                                                <span className="text-color-theme">Remote</span> or on-site
                                            </h1>
                                        </div>
                                    </div>
                                    <h3 className="section-sub-title mt-1">
                                        The advantage of Supply Chain by Service Level Agreement
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="img-wrap">
                                        <Image
                                            height="auto" width="100%"
                                            src={photo1}
                                            alt="Advantage-of-SLA Outsourcing by Service Level Agreement Supply Chain Management for maintenance, or operations"
                                            title="Advantages of Supply Chain by Service Level Agreement for energy production oil/gas/hydrogen and other."
                                        />
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap" style={{ minHeight: '170px' }}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Outsource Supply Chain and Procurement to <span className="theme-font">powerabode</span></h5>

                                                        <p>
                                                            Supply Chain by SLA is the home for category management, contracting & procurement and supply chain challenges.
                                                        </p>
                                                        <p>
                                                            Operations, Maintenance and Production. Supply Chain by SLA offers 6 key areas with dozens of services. The Service Level Agreement ensures the client to get expert services, when they need it. It is cost efficient, but also allows for expertise that is not always available in-house.
                                                        </p>
                                                        <p>
                                                            Cutting edge systems and processes, all built around our Cost Intelligence Philosophy take delivery to the next level.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="about-quote py-3">
                                                <h1 className="aq-text ">
                                                    <FontAwesomeIcon className="quote-icon-left" icon={faQuoteLeft} />
                                                    Every Supply Chain has Waste
                                                </h1>
                                                <h1 className="aq-text quote-two text-right text-theme-blue">
                                                    We Fix It!'
                                                    <FontAwesomeIcon className="quote-icon-right" icon={faQuoteRight} />
                                                </h1>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="deliver-our-services-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <h1 className="section-title-two">
                                        How do We Deliver <span className="text-color-primary">Our Services</span>
                                    </h1>
                                </div>
                                <div className="section-content deliver-services-wrapper">
                                    <div className="row">
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Why external expert?</h5>
                                                        <p>Boost the performance of your supply chain activites. Maybe you just want peak shaving to deal with a temporary increase in workload?
                                                            Driven by energy & Cost leadership. Contributing to your cost effectiveness.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Service Level Agreement Value Proposition</h5>
                                                        <p>The basis is our Cost intelligence philosophy. Outsourcing to ex-ioc experts. Productised solutions allow fast delivery. Our frontline experience ensures quality and accuracy.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Remote?</h5>
                                                        <p>We can deliver from your location, but also remote! Encrypted collaboration platform ‘MyAbode’ is central in our operations. We call it remote shoulder-to-shoulder.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Delivery focussed</h5>
                                                        <p>We deliver. Very straight forward. Assurance sits at the heart of what we do. All our services are productised, so we can hit the ground running. No time to waste!</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Fixed Scope</h5>
                                                        <p>For most services we can deliver a fixed scope, at a fixed price. Simple. Our processes are mature and strong, we follow our codex for delivery.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">Contact us</h5>
                                                        <p>A video call, a presentation or workshop. Interaction with your teams is appreciated. Contact us, so we can introduce you to our portfolio. Within one week we can have an online workshop with your team.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <SupplyChainSlider/>
            </div>
        </>
    );
}

export default SupplyChainBySla;