import React from 'react';
import { Image } from 'react-bootstrap';
import "../Style/iconbox.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
const AllSector = [
    {
        color: 'bg-color-set-1',
        title: 'Upstream Onshore',
        icon: require('../../Images/icons/energy-icons/onshore.png')
    },
    {
        color: 'bg-color-set-2',
        title: 'Upstream Oﬀshore',
        icon: require('../../Images/icons/energy-icons/offshore.png')
    },
    {
        color: 'bg-color-set-3',
        title: 'Downstream Reﬁnery',
        icon: require('../../Images/icons/energy-icons/refinary.png')
    },
    {
        color: 'bg-color-set-4',
        title: 'Power',
        icon: require('../../Images/icons/energy-icons/power.png')
    },
    {
        color: 'bg-color-set-5',
        title: 'Water Desalination',
        icon: require('../../Images/icons/energy-icons/water_desalination.png')
    },
    {
        color: 'bg-color-set-6',
        title: 'Nuclear Energy',
        icon: require('../../Images/icons/energy-icons/neuclear.png')
    },
    {
        color: 'bg-color-set-7',
        title: 'Renewable Solar',
        icon: require('../../Images/icons/energy-icons/solar.png')
    },
    {
        color: 'bg-color-set-8',
        title: 'Renewable Wind',
        icon: require('../../Images/icons/energy-icons/wind.png')
    },
    {
        color: 'bg-color-set-9',
        title: 'Hydrogen',
        icon: require('../../Images/icons/energy-icons/hydrogen.png')
    }
]
function IconBox(props) {
    return (
        <>
            <section className="iconBox-wrapper text-center position-relative overflow-hidden d-none d-md-block">
                <div className="container-fluid p-0">
                    <div className="row service-v2">
                        {AllSector.map((item, index) => (
                            <div className="col- col   p-0" key={index}>
                                <div className="icon-box-wrap service-single text-center with-icon layout2">
                                    <div className="link-icon">
                                        <Image
                                            height={50}
                                            className="w-auto"
                                            src={item.icon}
                                        />
                                    </div>
                                    <div className='icon-box-title'>
                                        <h5>
                                            {item.title}
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    
                    </div>
                </div>
            </section>

            <section className="iconBox-wrapper iconBox-slider-wrapper text-center position-relative overflow-hidden d-block d-md-none">
                <div className="container-fluid p-0">
                    <div className="row service-v2">
                        <div className="col-md-12">
                            <Swiper
                                // slidesPerView={3}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={false}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                                breakpoints={{
                                    900: {
                                        slidesPerView: 1,
                                    },
                                    800: {
                                        slidesPerView: 1,
                                    },
                                    500: {
                                        slidesPerView: 1,
                                    }
                                }}
                            >
                                {AllSector.map((item, index) => (
                                    <SwiperSlide item={item} key={index}>
                                        <div  className="icon-box-wrap service-single text-center with-icon layout2">
                                            <div className="link-icon">
                                                <Image
                                                    height={50}
                                                    className="w-auto"
                                                    src={item.icon}
                                                    alt="icon"
                                                />
                                            </div>
                                            <div className='icon-box-title'>
                                                <h5>
                                                    {item.title}
                                                </h5>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))} 
                            </Swiper>   
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default IconBox;