import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from "react";
import { Image } from 'react-bootstrap';
import HeroSection from '../../Components/Sections/HeroSection';
import SupplyChainSlider from '../../Components/Sections/SupplyChainSlider';
import ResultBg from "../../Images/banner/sla-slider/CostRecovery.webp";
import CoverPhoto1 from "../../Images/Supply-chain-sla/Supply-Chain-by-SLA-Lower.webp";

import Background from "../../Images/sla/2.webp";

// case study assets
import "../../Components/Style/case-studies.css";
import CaseStudiesPhoto1 from "../../Images/articles/single/5.1.jpeg";
import CaseStudiesPhoto2 from "../../Images/articles/single/4.1.jpeg";
import CaseStudiesPhoto3 from "../../Images/articles/single/10.1.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

function CostRecovery() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Cost Recovery - Powerabode</title>
                <meta
                    name="description"
                    content="There are numerous benefits of cost recovery ideas to help experts to know the project, fair challenges, avoid claims, etc. Check how it works."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="cost-recovery-bg"
                    pageTitleValue="Cost Recovery"
                    boxTitleValue="Understanding cost  "
                    boxTitleValue2=" is not only a numbers game."
                    boxDesValue="It always surprises us. Cost recovery is carried out by people that understand numbers, but don’t understand the field and the contracts. Or, even worse, people that understand the contract, but not the numbers. Not with us. We offer a process with high impact. It prevents problems. Delivers projects. Recovers your Cost.      "
                    title="Cost-recovery. Outsource your cost recovery to our cost recovery team. Cost Recovery. Did you know that primary reasons behind failed energy projects are unrecovered Cost?"
                />

                <div className="section sla-service-wrapper overflow-hidden d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6 pr-0">
                                            <div className="sla-img-l-box position-relative">
                                                <Image
                                                    style={{ maxHeight: "500px", objectFit: "cover" }}
                                                    width="100%" height="auto"
                                                    src={ResultBg}
                                                    alt=''
                                                />
                                                <div className="sla-img-absolute-txt">
                                                    <h1 className='section-title-two text-white'>
                                                        The Result
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <div className="sla-txt-r-box bg-dark-400 p-4 h-100 d-flex justify-content-center align-content-center flex-column">
                                                <ul className="check-icon-list">
                                                    <li className="list-item text-white d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Experts in control – understanding the project</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Best practices cost recovery</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Full recovery – fair challenge – avoid claims</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Compliant evidence for recovery</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-about-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div 
                                    className="sla-content-wrap" 
                                    style={{ background: `url("${Background}")` }}
                                    title="Cost is recovered by Commercial Experts, not Accountants"
                                >
                                    <div className="sla-cw-text">
                                        <h1 className='section-title-two text-white'>
                                            The Result
                                        </h1>
                                        <ul className="check-icon-list mt-3">
                                            <li className="list-item text-white d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Experts in control – understanding the project</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Best practices cost recovery</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Full recovery – fair challenge – avoid claims</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Compliant evidence for recovery</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sla-cw-footer-text">
                                        <h1 className="section-title-two text-white">
                                            Cost Recovery
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sla-service-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative ">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            Understanding the Cost.
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="section-title-two">
                                                Our <span className="text-color-primary"> Scope :</span>
                                            </h1>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Expertise</h5>
                                                        <p className='mt-3'>
                                                            In the field, the world looks different.
                                                            The pressure of delivery often takes the eye of the ball for assurance. Name a major energy asset, and its likely our experts interacted with it.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Not an accounting job</h5>
                                                        <p className='mt-3'>
                                                            Surprisingly, people with an accounting background are sourced for this activity. We hold the key expertise that assesses compliant evidence to all your costs.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            The analogy of cost recovery in most cases: <br />
                                            Driving a car forward, just using the rearview mirror.
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Two sides of the same Coin</h5>
                                                        <p className='mt-3'>
                                                            When Energy companies prepare for
                                                            Audit Defence, Governments prepare for Recovery Challenge. They are two sides of the same coin. powerabode can provide a dedicated team for Recovery Challenge or Defence.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6  col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Early involvement</h5>
                                                        <p className='mt-3'>
                                                            Audit defence requires hard evidence.
                                                            There is no alternative to early
                                                            involvement when it comes to cost
                                                            recovery. We have an experienced team ready to start
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            We work for government, NOC"s or energy assets directly.
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SupplyChainSlider />

                <div className="container-fluid mt-5 position-relative">
                    <Image
                        width="100%" height="auto"
                        src={CoverPhoto1}
                        alt=""
                    />
                    <div className="box-txt-top-left-absolute">
                        <h1 className='section-title-two'>
                            Related Articles and Case Studies
                        </h1>
                    </div>
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <div className="case-studies-section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h1 className="section-title-two">
                                            Top <span className="text-color-theme">Articles </span>
                                            &
                                            <span className="text-color-theme"> Case Studies</span>
                                        </h1>
                                    </div>
                                    <div className="section-content mt-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-5"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Does focus on rates make sense?</h5>
                                                            <p className="text-white">Cost Leadership will remain one of top 3 focus areas of Energy Industry for next decade. We excel in this area...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-4"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Deliver despite budget constraints</h5>
                                                            <p className="text-white">Every supply chain has waste, we fix it. Complex analyses using our CI Philosophy, double digit improvement to your budget and opex, value optimisation...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-9"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Misalignment with the businessplan </h5>
                                                            <p className="text-white">Not everyone knows the businessplan. This information is confidential or under evaluation. But, in the operations latest information is key for opex and capex decisions. How to deal with misalignment?</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CostRecovery;