import { Accordion } from "react-bootstrap";
import "../Style/faq.css";
import ButtonCircleSm from "../Widget/ButtonCircle-sm";
import logo1 from "../../Images/Bg/layouts1/CVI-Logo.png";
import logo3 from "../../Images/Bg/layouts1/opex_capex.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import logo4 from "../../Images/Bg/layouts1/cms.png";


function Faq() {
    return (
        <>
            <div className="faq-wrapper  pt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="section-title-two mb-3"><span className="text-color-primary">Frequently</span> Asked <span className="text-color-primary">Questions</span></h1>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>How can the commercial ecosystem benefit energy transition?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            For energy transition new activities require an overnight mature management system. CODEX is a system fit for international standard and pragmatic operations. No implementation risk and focus on cost efficiency. Based on the Cost Intelligence Philosophy.
                                        </p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Our operating cost are high, how can we reduce them sustainably?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            All our products & services will help you. Our site provides tips and cases in the articles section.
                                             Start with approaching cost in the right way; study our Cost and Value Intelligence (CVI) Philosophy. 
                                             If we role out our cost program for you, we change the commercial DNA of your business, And we reduce your 
                                             cost and dramatically increase your value! Check out below links to learn more.
                                        </p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                            <ButtonCircleSm
                                                url="/capex-opex-cost-program" 
                                                DynamicBtnColor="btn-color-2 mb-3"
                                                image={logo3}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>We negotiated with suppliers, but the cost are still high. What to do?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Most likely you negotiated rates, as is; the price of a product or service. This is maximum 20% of a potential saving. We can get you the other 70%. The final 10% of savings potential is always too expensive to obtain. Check out below links to learn more.</p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                            <ButtonCircleSm
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircleSm
                                                url="/capex-opex-cost-program" 
                                                DynamicBtnColor="btn-color-2 mb-3"
                                                image={logo3}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>We lack specialist expertise for building remuneration models, how do we fix this?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            Remuneration is a key driver in preserving long-term value and near-term cost efficiency. Building the right model is a skill,
                                            combined with the right evaluation model. We deliver these as services under Supply Chain by SLA. Check out below links to learn more.
                                        </p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircleSm
                                                url="/tendering-outsourcing"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                titleFirst="Tendering &"
                                                titleSecond="Outsourcing"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircleSm
                                                url="/category-strategy"
                                                titleFirst="Post Award"
                                                titleSecond="Contract Management"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                    <Accordion.Header>How do we get an integrated, commercially driven and pragmatic Supply Chain Management System?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Mistake #1: Start building it from scratch yourself. Mistake #2: Ask an outsider to built it (with no frontline experience in operations of your type of asset). The solution is an integrated system. Inviting to users and aimed at optimisation. The Codex is ‘of the shelf’, reducing development risk to zero! Read more about the integration, how it includes instructions, tips, expert services, all the forms & work instructions. Check out below links to learn more.</p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            {/* <ButtonCircleSm
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircleSm
                                                url="/commercial-assurance"
                                                titleFirst="Commercial"
                                                titleSecond="Assurance"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            /> */}
                                            <ButtonCircleSm
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                    <Accordion.Header>Our Materials Management system is not a reflection of reality. How do we fix this?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Obsolescence. In materials management time always catches up with you. And once it does, it can be costly. We can start with red & blue assessment. Materials Management is a key part of our Codex system. It covers processes, procedures, forms and work instructions. After all, if not in shape it can stop you in your tracks. Check out below links to learn more.</p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircleSm
                                                url="/materials-management"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                titleFirst="Materials"
                                                titleSecond="Management"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircleSm
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                    <Accordion.Header>Can Commercial Assurance help us with compliance and governance issues?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>This is the age of governance and compliance. And it serves a purpose. With our commercial assurance approach, you get better grip on your energy JV. All partners add value this way. And all stakeholders get piece of mind. Check out below links to learn more.</p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/commercial-assurance"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                titleFirst="Commercial"
                                                titleSecond="Assurance"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircleSm
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                            <ButtonCircleSm
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                    <Accordion.Header>How can we do a full cost program?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            When you do, start with our Cost and Value (C&V) Philosophy. Or rather, ask us for discussing our full program.
                                             Disruptive but controlled. Taking your organisation to cost efficiency, creating winners. In-house programs without
                                              our context are often blunt tools, as it gives targets, but never a toolbox and a ‘how to’. We do it for you & with you. 
                                              Check out below links to learn more.
                                        </p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/capex-opex-cost-program" 
                                                DynamicBtnColor="btn-color-2 mb-3"
                                                image={logo3}
                                            />
                                            <ButtonCircleSm
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                    <Accordion.Header> Why is cost recovery always creating so many disputes? How can we remedy this?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>Mostly cost recovery starts over 1 year after cost have been incurred. People have left, corporate memories have limits. Two parties enter discussions and can hardly do more than checking invoices. All not very constructive. Contact us for a conversation on the best way how to do it. Or better, let us do it for you. Check out below links to learn more.</p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/cost-recovery"
                                                titleFirst="Cost Recovery"
                                                DynamicBtnColor="btn-color-8 mb-3"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircleSm
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                    <Accordion.Header>What is the Extended Contract Management Team? Why are they so important in cost efficiency?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            No one reads the contract. A strong statement, but often true. With reason. We have tools for the users of the contract 
                                            (the ECMT we call this group). Around 8-12 people per contract. They are key in our Cost and Value (C&V) philosophy. 
                                            And in CM+. Check out below link to learn more.
                                        </p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                            <ButtonCircleSm
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                    <Accordion.Header>How do we build up cost intelligence for our business?</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            This starts with understanding cost. Embracing the principles of our cost and value philosophy; 
                                            that the users of the contract are key. And that rate (‘the price’) is only 20% of any potential saving. 
                                            Check out below link to learn more.
                                        </p>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircleSm
                                                url="/capex-opex-cost-program" 
                                                DynamicBtnColor="btn-color-2 mb-3"
                                                image={logo3}
                                            />
                                            <ButtonCircleSm
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
}

export default Faq;