import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import { Image } from "react-bootstrap";
import Smart from "../../Images/articles/smart.png";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo1 from "../../Images/Bg/layouts1/CVI-Logo.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles2() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Secrets of Enhancing Performance & inefficiency for SMART KPIs - Powerabode</title>
                <meta
                    name="description"
                    content="Working as a team. That’s what we do. But how do we know we are all aligned? Conflicting KPI’s cause massive inefficiencies. Who needs enemies if you have friends?"
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles2-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Conflict or Conflicting KPI’s?"
                    boxTitleLabel="PHILOSOPHY >"
                    title="key-performance-indicators-kpi"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">KPI’s. They drive performance. But they can also cause inefficiency.</h1>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center  mt-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={Smart}
                                                    alt=""
                                                />
                                            </div>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Conflict is not a very appealing word to start an article. In this case our point is this; sometimes in an operational environment conflicts arise because of conflicting kpi’s. People are set up to perform on drivers that are not in sync with those of their colleagues.</p>
                                                        <p>In the energy supply chain, a Contract is generally created for the supply of goods or execution</p>
                                                        <p>of specific services. In a complex environment of frontline execution, ECMT (Extended Contract</p>
                                                        <p>Management Team) stakeholders from several departments and revelant Contracts need to come together as an eco-system to deliver one specific ‘job’. The ‘job’ may have its own performance indicator, but the individual departments and related Contracts also have their own kpi’s. Once this eco-system loses its balance, Cost inefficiency builds up. Given the complexity this is likely to occur, especially in large Energy Companies.</p>
                                                        <p>As an example; 5 disciplines (electrical, maintenance, production leader, finance) each have their own performance indicators. Also the Asset has kpi’s.</p>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">Some kpi’s in this case:</li>
                                                            <li className="list-item">Maintenance; 85% availability</li>
                                                            <li className="list-item">Electrical; 98% reliability</li>
                                                            <li className="list-item">Budget holder; stay within budget</li>
                                                            <li className="list-item">Then, during the year production targets are changed and not properly communicated.</li>
                                                        </ul>

                                                        <p>For the sake of argument we mention changed production targets. But it could be a changed asset strategy, or something else. Setting up kpi’s is one thing, but they have to be in sync. And when events happen during the year, revisions and updates are necessary.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            Conflicting kpi’s result in conflict. And cost inefficiency.
                                                        </h5>
                                                        <p>We have all been there. When changes were pending, or actually happened. And not everyone knew of them. Or only found out later. The consequence is inefficiency, tension or even conflict. Once one of the parameters was changed, all kpi’s should have been revised.</p>
                                                        <p>As specialist in the integrated supply chain, <span className="theme-font">powerabode</span> fulfils the role of facilitator in setting kpi’s. Interdependencies in the supply chain, between contracts, departments, the business plan.</p>
                                                        <p>The ECMT has to align its kpi’s. This results in greater cost efficiency. It is one of the modules of the Integrated Supply Chain training, as provided by <span className="theme-font">powerabode</span> Academy.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-100 d-flex justify-content-center align-items-center mt-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={Smart}
                                                    alt=""
                                                />
                                                <div className="text-content px-4">
                                                    <h5>Is not enough</h5>
                                                </div>
                                            </div>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>The operations of large energy organisations are complex and refined (no pun intended). Kpi’s are a great tool for improving delivery and monitoring progress. But for true optimisation involvement of a specialist on integrated supply chain brings additional value.</p>
                                                        <p>Smart is the acronym for kpi’s. Specific, Measurable, Assignable, Realistic and Time Bound. Clearly, meeting these parameters is necessary. But how do the kpi’s of one align with those of others. When the organisation has multiple disciplines. Multiple assets. When the operations are dynamic and subject to revisions during the year.</p>
                                                        <p><span className="theme-font">powerabode</span> will conduct regular alignment sessions once we facilitated your program. It reduces conflict and improves your cost efficiency.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    

                                

                                    <div className="w-100 mt-5">
                                        <div className="section-header mb-5">
                                            <h1 className="section-title-two text-center">
                                                Visit these services for more detail:
                                            </h1>
                                        </div>
                                    
                                        <div className="d-flex justify-content-center gap-3 ">
                                            <ButtonCircle
                                                url="/systems-delivery/contractmanagementtool"
                                                DynamicBtnColor="btn-color-5 mb-3"
                                                image={logo5}
                                            />
                                            <ButtonCircle
                                                url="/cost-value-philosophy"
                                                DynamicBtnColor="btn-color-1 mb-3"
                                                image={logo1}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles2;