import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import CostInitRightPhoto from "../Images/Bg/bg-2.webp";
import EcmtPhoto from "../Images/Bg/bg-3.webp"
import "../Style/cost-intelligence-section.css"
import InfographOne from "../Components/Widget/Infograph-one";
import CaseStudies from "../Components/Sections/CaseStudies";
import HorizontalAccordion from "../Components/Sections/HorizontalAccordion";
import {useEffect} from "react";
import CostIntelligenceLogo from "../Images/Bg/CVI-Logo.png";

import SavingPhoto from "../Images/Bg/SAVINGS-01.webp";
import HeroSectionLayout1 from "../Components/Sections/HeroSectionLayout1";
import { Helmet } from "react-helmet-async";

function CostIntelligencePhilosophy() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>What is the Cost and Value Philosophy in Energy Industries? - Powerabode</title>
                <meta
                    name="description"
                    content="Without understanding the cost and value philosophy, it’s hard to reach your goals. Here we talk about the topic of when & how to start."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSectionLayout1
                    className="overflow-hidden"
                    pageTitleName="Cost and Value Philosophy"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="cost-int-philosophy-bg"
                    boxTitleValue="Cost and Value Philosophy"
                    boxDesValue="Understanding cost and value is a science. To really do this, a company must know why they do something and how they do it.
                                Simple questions. However, its complex to get there. Most of the time, negotiating about price is what happens. In our approach that will only give 20% of the savings potential.
                                The Powerabode founding team created the Cost and Value Philosophy."
                    title="How to achieve savings target using Cost Intelligence Philosophy?"
                />

                <section className="cost-init-philosophy-about-wrapper mt-5">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                                <div className="cipaw-left-wrap position-relative">
                                    <h1 className="section-title-two">
                                        Cost and Value Philosophy
                                    </h1>
                                    <h3 className="section-sub-title mt-1">
                                        <span className="text-color-theme">How to deliver cost efficiency and value improvement?</span> <br />
                                        We help you do it.
                                    </h3>
                                    <ul className="check-icon-list mt-3">
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                            <span>All our products and services are built around our cost and value philosophy. It will bring overall improvement (OPEX/CAPEX) by double digit percentage.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Rates are only 20% of any potential saving! Specification focus can deliver 30% of the Savings Potential. Efficiency focus can bring 40% of the Savings Potential.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>We take your organization on the road to cost leadership.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Our analyses of Rates, Specification and Efficiency will bring out the maximum Savings Potential.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>The Opex/Capex Program, our Supply Chain Services by SLA, but also our Codex Management System and CM+ empower your organisation utilising methods based on the Cost and value Philosophy. Consistently and sustainably optimising cost and value!</span>
                                        </li>
                                    </ul>
                                {/* <div className="mt-4 text-left">
                                        <Link
                                            to=""
                                            className="btn btn--gradient-theme text-white py-3 px-5"
                                        >
                                            Learn More <FontAwesomeIcon icon={faChevronRight} />
                                        </Link>
                                </div> */}
                                    <div className="mt-4 text-left cipaw-cost-logo">
                                        <Image
                                            height="160px"
                                            src={CostIntelligenceLogo}
                                            alt="Powerabode-Cost-&-Value-Intelligence-logo"
                                            title="powerabode Cost & Value Intelligence, specification, efficiency, rate"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12">
                                <div className="cipaw-right-wrap text-center">
                                    <Image
                                        src={CostInitRightPhoto}
                                        alt="Deliver-cost-efficiency-and-value-improvement for energy assets"
                                        title="How to deliver cost efficiency and value improvement for NOC’s?"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="embrace-cost-inti-philosophy mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-title">
                                    <h3 className="section-sub-title mt-1">
                                        Why do we need to <span className="text-color-theme">embrace</span> the <span className="text-color-theme">Cost and Value Philosophy?</span>
                                    </h3>
                                    <p>Cost reduction targets come in regular intervals. And often they nothing more than that. A target. Every division,
                                        company or department then starts pushing. Without really ever going to the essence.</p>
                                    <p>The Cost and Value Philosophy does that. It is embedded in all powerabode services and products. A powerful logic
                                        sits behind a system, breaking down cost and value across Specification, Efficiency and Rates. We help getting the
                                        targets delivered. Analysing operations, maintenance and production activities according to this framework
                                        empowers delivery of cost efficiency and value optimisation.</p>
                                </div>

                                <div className="section-content">
                                    <div className="mt-3">
                                        <div className="row">
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div className="ht-box-icon">
                                                    <div className="icon-box-wrap">
                                                        <div className="content text-left">
                                                            <h5 className="heading">Lower rates</h5>
                                                            <p>
                                                                Standard is to negotiate lower prices with
                                                                suppliers and service providers. This is only 20%
                                                                of the savings potential. But this is where it stops
                                                                for most companies. The Cost and Value
                                                                Philosophy shows where additional value
                                                                improvements and cost efficiencies are possible.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div className="ht-box-icon">
                                                    <div className="icon-box-wrap">
                                                        <div className="content text-left">
                                                            <h5 className="heading">Efficiency & Specification</h5>
                                                            <p>
                                                                A main potential of value and savings sit in the
                                                                space of improved efficiency and rationalised
                                                                specification. Together, they deliver an extra 70%
                                                                of the cost saving potential. On top of the 20% in
                                                                rate reductions. The final 10% is too expensive to
                                                                achieve. Can you believe how inefficient the
                                                                traditional approach of value and cost is?
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-lg-4 col-md-6 col-12">
                                                <div className="ht-box-icon">
                                                    <div className="icon-box-wrap">
                                                        <div className="content text-left">
                                                            <h5 className="heading">What else?</h5>
                                                            <p>
                                                                After splitting up the savings potential, we map the organisation into the stakeholders for each part of the potential. Our products and services are all designed to deliver on this logic. It basically takes the contract as a base, and then mobilises the users of a contract in the Extended Contract Management Team (ECMT).
                                                                This ECMT is our first stepping stone into rolling out the philosophy and delivering of savings.
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="ecmt-wrap">
                                        <div className="ecmt-section-header d-block d-md-flex align-items-end">
                                            <div className="esh-img w-50">
                                                <Image
                                                    className="w-100"
                                                    src={EcmtPhoto}
                                                    alt="Cost-and-Value-Intelligence-Philosophy"
                                                    title="What is Cost Intelligence Philosophy and how does it achieve 70% more savings than traditional rate negotiation? "
                                                />
                                            </div>
                                            <div className="esh-text w-50">
                                                <div className="esh-r-img">
                                                    <Image
                                                        className="savingInfo-graph"
                                                        height="auto" width="100%"
                                                        src={SavingPhoto}
                                                        alt="Savings-and-optimization, Demand management, GAP analysis"
                                                        title="Savings-and-optimization, Demand management, GAP analysis"
                                                    />
                                                    <div className="costInfo-logo d-none d-md-block">
                                                        <Image
                                                            className=""
                                                            height="120px" width="auto"
                                                            src={CostIntelligenceLogo}
                                                            alt="Powerabode-Cost-&-Value-Intelligence-logo"
                                                            title="powerabode Cost & Value Intelligence, specification, efficiency, rate"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="esh-r-txt">
                                                    <h1 className="section-title-two">
                                                        <span className="text-color-primary">The ECMT</span>;
                                                    </h1>
                                                    <h3 className="section-sub-title mt-1">
                                                        The users of the contract!
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="ecmt-content mt-3">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="ht-box-icon">
                                                        <div className="icon-box-wrap">
                                                            <div className="content text-left">
                                                                <h5 className="heading">Why the ECMT?</h5>
                                                                <p>The ECMT is in the heart of the integrated supply chain (ISC).They use & influence contracts everyday. Why the ECMT?</p>
                                                                <ul className="circle-list mt-3">
                                                                    <li className="list-item">Create sense of urgency</li>
                                                                    <li className="list-item">Create ownership of savings process</li>
                                                                    <li className="list-item">Has influence & control on commercial performance</li>
                                                                    <li className="list-item">Ideal platform to achieve cost efficiency</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="ht-box-icon" >
                                                        <div className="icon-box-wrap" style={{ height: "258px" }}>
                                                            <div className="content text-left">
                                                                <h5 className="heading">What is the Integrated Supply Chain?</h5>
                                                                <p>Integrated Supply Chain (ISC) is all about interdependence and synergy between Client and its contracts. Recognising and mastering these between multidisciplinary teams, is the key to long-term value preservation and operational effectiveness.</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="commercial-wrap pt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two">How We Deliver The <span className="text-color-theme">Cost and Value Philosophy (C&V)?</span></h1>
                                    <h1 className="section-title-two">The Commercial Ecosystem</h1>
                                    <p>
                                        The C&V Philosophy is the basis for the <span className="theme-font">powerabode</span> Commercial Ecosystem. 
                                        It serves energy clients that want to optimise value and challenge their cost. We built and deliver a resilient Supply Chain 
                                        and Procurement function. It has solutions for energy assets, as well as new energy start ups that require mature systems overnight.
                                        The system is also applicable for NOCs who aspire operatorship.
                                    </p>
                                    <p>
                                        The strength <span className="theme-font">powerabode</span> brings is the integrated system of solutions, based on the C&V Philosophy. 
                                        We introduce one, or more of the services, train the organisation, and work with you in the delivery.
                                    </p>
                                    <p>
                                        Over 30 experts, all with IOC background support the delivery. This makes <span className="theme-font">powerabode</span> the partner for Outsourcing.
                                    </p>
                                    <hr />
                                    
                                    <h3 className="section-sub-title mt-1">
                                        Delivering <span className="text-color-theme">Cost Efficiency and Value improvement</span> -
                                        Dealing with <span className="text-color-theme">Supply Chain Resilience</span> -
                                        Partner in <span className="text-color-theme">Energy Transition</span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 p-0">
                                <InfographOne />
                            </div>
                        </div>
                    </div>
                </section>

                <HorizontalAccordion/>
                
                <CaseStudies/>
            </div>
        </>
    );
}

export default CostIntelligencePhilosophy;