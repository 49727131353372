
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";


function Articles14() {
    return (
        <div className="bg-white">
            <HeroSectionSm
                className="articles15-hero-bg overflow-hidden"
                pageTitleValue="Articles"
                boxTitleValue="Our Fundament is the Philosophy of Cost Intelligence"
                boxTitleLabel="Article >"
            />

            

            <RelatedArticlesSlider />

            <HorizontalAccordion
                className="overflow-hidden"
            />
        </div>
    );
}

export default Articles14;