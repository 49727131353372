import * as React from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "./Components/Style/sla-about.css";
// import Home from "./Pages/Home";
import About from "./Pages/About-us";
import Header from "./Layouts/Navbar";
import Footer from "./Layouts/Footer";

import CallOfAction from "./Components/Sections/CallOfAction";
import ContactFrom from "./Components/Sections/ContactFrom";
import RequestForRfa from "./Components/Sections/RequestForRfq";

import CostIntelligencePhilosophy from "./Pages/Cost-intelligence-philosophy";
import CostProgram from "./Pages/Cost-program";
import DealDesign from "./Pages/Deal-design";
import SupplyChainBySla from "./Pages/Supply-chain-by-sla";

import Codex from "./Pages/Codex";
import Cm from "./Pages/Cm";
import Academy from "./Pages/Academy";

import CategoryStrategy from "./Pages/Supply-Chain/Category-strategy";
import PostAward from "./Pages/Supply-Chain/Post-award";
import CommercialAssurance from "./Pages/Supply-Chain/Commercial-assurance";
import CostRecovery from "./Pages/Supply-Chain/Cost-recovery";
import TenderingOutsourcing from "./Pages/Supply-Chain/Tendering-outsourcing";
import MaterialsManagement from "./Pages/Supply-Chain/Materials-management";

import Articles1 from "./Pages/Case-Studies/articles-1";
import Articles2 from "./Pages/Case-Studies/articles-2";
import Articles3 from "./Pages/Case-Studies/articles-3";
import Articles4 from "./Pages/Case-Studies/articles-4";
import Articles5 from "./Pages/Case-Studies/articles-5";
import Articles6 from "./Pages/Case-Studies/articles-6";
import Articles7 from "./Pages/Case-Studies/articles-7";
import Articles8 from "./Pages/Case-Studies/articles-8";
import Articles9 from "./Pages/Case-Studies/articles-9";
import Articles10 from "./Pages/Case-Studies/articles-10";
import Articles11 from "./Pages/Case-Studies/articles-11";
import Articles12 from "./Pages/Case-Studies/articles-12";
import Articles13 from "./Pages/Case-Studies/articles-13";
import Articles14 from "./Pages/Case-Studies/articles-14";
import Articles15 from "./Pages/Case-Studies/articles-15";
import ArticlesLanding from "./Pages/Case-Studies/articles-landing";
import HomeDemo from "./Pages/Home-demo";
import Copyright from "./Pages/Copyright";
import Policy from "./Pages/Policy";
import Disclaimer from "./Pages/Disclaimer";

import CookieConsent from "react-cookie-consent";


import WhatsAppChat from "./Components/whatsapp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCookie } from "@fortawesome/free-solid-svg-icons";
import "./fonts/abeatbyKaiRegular.otf";

import { HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <HelmetProvider>
      <div className="App">
        <div className="header-area bg-white">
          <Header />
        </div>

        <div id="main-wrapper">
          <div className="site-wrapper-reveal">
              <Routes>
                {/* <Route exact path="/homepage-demo" element={<Home />} /> */}
                <Route exact path="/" element={<HomeDemo />} />
                <Route exact path="/cost-value-philosophy" element={<CostIntelligencePhilosophy />} />
                <Route exact path="/about-us" element={<About />} />
                <Route exact path="/capex-opex-cost-program" element={<CostProgram />} />
                <Route exact path="/deal-design" element={<DealDesign />} />
                <Route exact path="/supply-chain-by-sla" element={<SupplyChainBySla />} />

                <Route exact path="/systems-delivery/codex-supplychainmanagmentsystem" element={<Codex />} />
                <Route exact path="/systems-delivery/contractmanagementtool" element={<Cm />} />
                <Route exact path="/systems-delivery/academy" element={<Academy />} />

                <Route exact path="/category-strategy" element={<CategoryStrategy />} />
                <Route exact path="/post-award-contract-management" element={<PostAward />} />
                <Route exact path="/commercial-assurance" element={<CommercialAssurance />} />
                <Route exact path="/cost-recovery" element={<CostRecovery />} />
                <Route exact path="/tendering-outsourcing" element={<TenderingOutsourcing />} />
                <Route exact path="/materials-management" element={<MaterialsManagement />} />
              
                <Route exact path="/articles-business-cases/solutions-to-influence-cost" element={<Articles1 />} />
                <Route exact path="/articles-business-cases/key-performance-indicators" element={<Articles2 />} />
                <Route exact path="/articles-business-cases/project-management-bottle-necks" element={<Articles3 />} />
                <Route exact path="/articles-business-cases/budget-constraints-delivery" element={<Articles4 />} />
                <Route exact path="/articles-business-cases/focus-on-rates" element={<Articles5 />} />
                <Route exact path="/articles-business-cases/discount-price-reduction" element={<Articles6 />} />
                <Route exact path="/articles-business-cases/supply-Chain-Procurement-Management-System" element={<Articles7 />} />
                <Route exact path="/articles-business-cases/performance-dna-procurement-supply-chain-department" element={<Articles8 />} />
                <Route exact path="/articles-business-cases/materials-management" element={<Articles9 />} />
                <Route exact path="/articles-business-cases/businessplan" element={<Articles10 />} />
                <Route exact path="/articles-business-cases/unlocking-value-savings-bottom-line" element={<Articles11 />} />
                <Route exact path="/articles-business-cases/sourcing-strategy" element={<Articles12 />} />
                <Route exact path="/articles-business-cases/the-art-of-the-deal-outsourcing-supply-chain" element={<Articles13 />} />
                <Route exact path="/articles-business-cases/local-content-development" element={<Articles14 />} />
                <Route exact path="/articles-business-cases/articles-15" element={<Articles15 />} />
                <Route exact path="/blog-articles-on-business-cases-and-backgrounds" element={<ArticlesLanding />} />
                
                
                <Route exact path="/copyright" element={<Copyright />} />
                <Route exact path="/cookie-privacy-policy" element={<Policy />} />
                <Route exact path="/disclaimer" element={<Disclaimer />} />
              </Routes>

            <ContactFrom />

            <RequestForRfa />

            <CallOfAction />
          </div>

          <CookieConsent
            location="bottom"
            buttonText="Allow"
            cookieName="powerabodecookies"
            style={{ background: "#00ABB8", color: '#fff',}}
            buttonStyle={{ color: "#4e503b", fontSize: "15px", borderRadius: "5px", fontWeight: "500"}}
            expires={150}
          >
            <div className="container">
              <div className="d-flex gap-3 justify-content-center align-items-center">
                  <FontAwesomeIcon icon={faCookie}/>
                  <p className="text-white">We use cookies to analyze information about site performance and use, to provide social media features and to customize content and advertising.</p>
              </div>

            </div>
          </CookieConsent>
          <WhatsAppChat/>
          <Footer />
        </div>
      </div>
    </HelmetProvider>
  );
}

export default App;
