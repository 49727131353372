import React from 'react';
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';

function Copyright() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The Cookies Policy & Privacy Policy – Power Abode</title>
                <meta
                    name="description"
                    content="Your privacy is important to us. We handle and use information collected about you. Your privacy is 100% secured with us."
                />
            </Helmet>
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="ht-box-icon copyright-wrapper">
                            <div className="icon-box-wrap">
                                <div className="content text-left">
                                    <h3 className="section-sub-title">Copyright</h3>
                                    <p>This website (  <a href='/' className='text-color-theme'><span className="theme-font" >powerabode.com</span></a> ) and its content is copyright of <span className="theme-font">powerabode</span> DMCC, Dubai, UAE. All rights reserved.</p>
                                    <p>Any redistribution or reproduction of part or all of the contents in any form is prohibited other than the following:</p>
                                    <ul className="circle-list">
                                        <li className="list-item">
                                            <span>You may print or download to a local hard disk extracts for your personal and non-commercial use only</span>
                                        </li>
                                        <li className="list-item">
                                            <span>You may copy the content to individual third parties for their personal use, but only if you acknowledge the website as the source of the material</span>
                                        </li>
                                    </ul>
                                    <p>You may not, except with our express written permission, distribute or commercially exploit the content. Nor may you transmit it or store it in any other website or other form of electronic retrieval system.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Copyright;