import { Image } from "react-bootstrap";
import "../Style/hero-section.css";
const Images = [
    { icon: require('../../Images/icons/blue/group.png') },
    { icon: require('../../Images/icons/blue/group1.png') },
    { icon: require('../../Images/icons/blue/group2.png') },
    { icon: require('../../Images/icons/blue/group3.png') },
    { icon: require('../../Images/icons/blue/group4.png') },
    { icon: require('../../Images/icons/blue/group5.png') },
    { icon: require('../../Images/icons/blue/group6.png') },
    { icon: require('../../Images/icons/blue/group7.png') },
    { icon: require('../../Images/icons/blue/group8.png') }
]
function HeroSectionLayout1(props) {
    return (
        <>
            {/* Hero Section */}
            <section className={`hero-section-wrap section-space--ptb_120 position-relative ${props.HeroBgClassName}`} title={props.title}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {/* top powerabode 8 icon  */}
                            <div className={`hero-info-box py-4 px-4 text-center ${props.TopIconSectionStatus} d-none d-xl-block`}>
                                {props.pageTitleName ?
                                    <h4 className="text-color-theme pb-4">
                                        {props.pageTitleName}
                                    </h4>
                                : null}

                                <ul className="mb-2">
                                    {Images.map((item, index) => (
                                        <li className="d-inline-block" key={index}>
                                            <Image
                                                height="25px" className="px-1"
                                                src={item.icon}
                                                alt=""
                                            />
                                        </li>
                                    ))}
                                </ul>
                                <p className="text-center">Operations | Maintenance | Production </p>
                            </div>
                            {/* END */}
                            {/* Bubbles box Start */}
                            {props.bubblesTitleValue || props.bubbleslogo ? 
                                <div className={`intro-layers-wrapper ${props.bubblesGradientColor}`}>
                                    <div className="intro-layers">
                                        {props.bubbleslogo ?
                                            <Image
                                                className="intro-l-imag w-100"
                                                src={props.bubbleslogo}
                                                alt=''
                                            />
                                        : null}
                                        {props.bubblesTitleValue ? 
                                            <h2> 
                                                {props.bubblesTitleValue} <br />
                                                {props.bubblesTitleValue2 ?
                                                <span>
                                                        {props.bubblesTitleValue2}
                                                </span>
                                                : null}
                                            </h2>
                                        : null}
                                        <h5 class=" mt-1 mb-1 text-white boxFocosTitle">
                                            {props.boxFocosTitle}
                                        </h5>
                                        {props.bubblesDesValue ?
                                            <p className="mt-3">
                                                {props.brandName ?
                                                    <span className="theme-font text-white ">{props.brandName}</span>
                                                    : null}
                                                {props.bubblesDesValue}
                                            </p>
                                        : null}
                                        <p className="mt-1">{props.bubblesDesValue1}</p>
                                    </div>
                                </div>
                            : null} 
                            {/* End - Bubbles */}
                            {props.boxTitleValue ? 
                                <div className="hero-content py-4 px-4" data-aos="fade-left" data-aos-easing="ease" data-aos-duration="1000">
                                    <h2>
                                        {props.boxTitleValue} 
                                        {props.boxTitleValue2 ? 
                                            <span className="text-color-primary">
                                                {props.boxTitleValue2}
                                            </span>
                                        : null}
                                    </h2>
                                    <p>
                                        {props.brandName ? 
                                            <span className="text-color-theme mr-5 font-weight--regular">{props.brandName}</span>
                                        : null}
                                        {props.boxDesValue}
                                    </p>
                                </div>
                            : null} 
                           
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HeroSectionLayout1;