import "../Style/case-studies.css";
import { Image } from "react-bootstrap";
import CaseStudiesPhoto1 from "../../Images/banner/case-studies-1.png";
import CaseStudiesPhoto2 from "../../Images/banner/sla-slider/CategoryStrategy.webp";
import CaseStudiesPhoto3 from "../../Images/banner/case-studies-3.png";
import { Link } from "react-router-dom";
function CaseStudies(props) {
    return (
        <section className="case-studies-section mt-5">
            <div className="container">
                <div className="row ">
                    <div className="col-md-12">
                        <div className="section-title">
                            <h1 className="section-title-two text-white">
                                Top <span className="text-color-theme text-white">Articles </span> 
                                & 
                                <span className="text-color-theme text-white"> Case Studies</span>
                            </h1>
                        </div>
                        <div className="section-content mt-4">
                            <div className="row">
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className="animation-box-wrap">
                                        <Link 
                                            to="/articles-business-cases/articles-11"
                                            className="w-100"
                                        >
                                            <div className="abw-img">
                                                <Image
                                                    width="100%"
                                                    src={CaseStudiesPhoto1}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="abw-text p-3">
                                                {/* <span className="collapse-top">
                                                    <FontAwesomeIcon icon={faAngleUp} className="text-white"/>
                                                </span> */}
                                                <h5 className="text-white heading">Unlocking value to your bottom line</h5>
                                                <p className="text-white">Dentifying a saving potential is a small step. The bigger thing is to bring it to your bottomline. We use the Integrated Supply Chain, the structure of our Cost Intelligence Philosophy and the people that use the contract to…</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className="animation-box-wrap">
                                        <Link
                                            to="/articles-business-cases/articles-12"
                                            className="w-100"
                                        >
                                            <div className="abw-img">
                                                <Image
                                                    width="100%"
                                                    src={CaseStudiesPhoto2}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="abw-text p-3">
                                                <h5 className="text-white heading">Strategic Sourcing is not Sourcing Strategy</h5>
                                                <p className="text-white">Digitisation is key, but good old human intelligence is still key in defining the strategy. Artificial Intelligence is quickly gaining pace, but...</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
                                    <div className="animation-box-wrap">
                                        <Link 
                                            to="/articles-business-cases/articles-1"
                                            className="w-100"
                                        >
                                            <div className="abw-img">
                                                <Image
                                                    width="100%"
                                                    src={CaseStudiesPhoto3}
                                                    alt=""
                                                />
                                            </div>
                                            <div className="abw-text p-3">
                                                <h5 className="text-white heading">Hidden in Plain Sight</h5>
                                                <p className="text-white">Crazy as it may sound; there’s always an elephant in the room.. Central in our way of work are the users of the contract, the ECMT. They are underestimated as a platform. 9 Quote: Our Experts know your business</p>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default CaseStudies;