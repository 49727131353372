import React, { useEffect, useState } from 'react';
import "./MobileNav.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';
import logo from "../Images/logo/powerabode-logo.svg";

function MobileNav() {
    const { toggleSidebar } = useProSidebar();

    const [isOpen, setIsOpen] = useState(false)
    useEffect(() => {
        document.body.classList.toggle('open-sidebar', isOpen);
        console.log('new one')
    }, [isOpen])

    const toggleSidebarHandler = () => {
        toggleSidebar();
        setIsOpen(!isOpen)
    }

    return (
        <div style={{ display: 'flex', height: '100%' }} className="d-block d-xl-none">
            <main>
                <button className='asideBar-toggle' onClick={toggleSidebarHandler}>
                    <FontAwesomeIcon icon={faBars} />
                </button>
            </main>
            <div className="asideBar-wrapper">
                <div className="sidebar-backdrop" onClick={toggleSidebarHandler}></div>
                <Sidebar className='asideBar-theme'>
                
                    <div className="asideBar-header d-flex justify-content-between mb-3">
                        <div className="asideBar-logo">
                            <Link to="/">
                                <img src={logo} className="img-fluid" alt="" />
                            </Link>
                        </div>
                        <div className="asideBar-close" onClick={toggleSidebarHandler}>
                            <FontAwesomeIcon
                                icon={faXmark}
                            />
                        </div>
                    </div>
                    
                    <Menu>
                        <SubMenu label="Cost and Value Philosophy">
                            <MenuItem routerLink={<Link to="/cost-value-philosophy" />} onClick={toggleSidebarHandler}> Cost and Value <br /> Philosophy </MenuItem>
                            <MenuItem routerLink={<Link to="/blog-articles-on-business-cases-and-backgrounds" />} onClick={toggleSidebarHandler}> Articles & Business <br /> Cases </MenuItem>
                        </SubMenu>
                        <MenuItem routerLink={<Link to="/about-us" />} onClick={toggleSidebarHandler}> About Us & Themes </MenuItem>
                        <MenuItem routerLink={<Link to="/capex-opex-cost-program"  />} onClick={toggleSidebarHandler}> Capex / Opex <br/> Program </MenuItem>
                        <SubMenu label="Supply Chain by SLA">
                            <MenuItem routerLink={<Link to="/supply-chain-by-sla" />} onClick={toggleSidebarHandler}> Supply Chain by SLA </MenuItem>
                            <MenuItem routerLink={<Link to="/category-strategy" />} onClick={toggleSidebarHandler}> Category Strategy & Contract Strategy </MenuItem>
                            <MenuItem routerLink={<Link to="/post-award-contract-management" />} onClick={toggleSidebarHandler}> Post Award Contract Management </MenuItem>
                            <MenuItem routerLink={<Link to="/commercial-assurance" />} onClick={toggleSidebarHandler}> Commercial Assurance </MenuItem>
                            <MenuItem routerLink={<Link to="/cost-recovery" />} onClick={toggleSidebarHandler}> Cost Recovery </MenuItem>
                            <MenuItem routerLink={<Link to="/tendering-outsourcing" />} onClick={toggleSidebarHandler}> Tendering & Outsourcing </MenuItem>
                            <MenuItem routerLink={<Link to="/materials-management" />} onClick={toggleSidebarHandler}> Materials Management </MenuItem>
                        </SubMenu>
                        <SubMenu label="Systems & Delivery">
                            <MenuItem routerLink={<Link to="/systems-delivery/codex-supplychainmanagmentsystem" />} onClick={toggleSidebarHandler}> Codex </MenuItem>
                            <MenuItem routerLink={<Link to="/systems-delivery/contractmanagementtool" />} onClick={toggleSidebarHandler}> cm+ </MenuItem>
                            <MenuItem routerLink={<Link to="/systems-delivery/academy"  />} onClick={toggleSidebarHandler}> Academy </MenuItem>
                            
                        </SubMenu>
                        <MenuItem routerLink={<Link to="/deal-design" />} onClick={toggleSidebarHandler}> Deal Design </MenuItem>
                    </Menu>
                </Sidebar>
            </div>
        </div>
    );
}

export default MobileNav;