import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect } from "react";
import { Image } from 'react-bootstrap';
import HeroSection from '../../Components/Sections/HeroSection';
import SupplyChainSlider from '../../Components/Sections/SupplyChainSlider';
import ResultBg from "../../Images/banner/sla-slider/TenderingandOutsourcing.webp";
import CoverPhoto1 from "../../Images/Supply-chain-sla/Supply-Chain-by-SLA-Lower.webp";
import BoxImage from '../../Images/Supply-chain-sla/OUTSOURCINGINFORecovered-01.png'

import Background from "../../Images/sla/6.webp";

// case study assets
import "../../Components/Style/case-studies.css";
import CaseStudiesPhoto1 from "../../Images/articles/single/14.1.jpeg";
import CaseStudiesPhoto2 from "../../Images/articles/single/13.1.jpeg";
import CaseStudiesPhoto3 from "../../Images/articles/single/8.1.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

function TenderingOutsourcing() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Tendering Outsourcing - Power Abode</title>
                <meta
                    name="description"
                    content="Our techniques for tender outsourcing make a tender valued. In each step, we research and develop until finding cost-effective possibilities."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="tendering-bg"
                    pageTitleValue="Tendering & Outsourcing"
                    boxTitleValue="Tendering   "
                    boxTitleValue2=" & Outsourcing"
                    boxDesValue="Procurement is done in-house by most of our Clients. With involvement of our experts, tools and systems the results improve significantly. Also beyond any tender we offer services that help making things ‘work’. Our focus is always to progress on the Road to Cost Leadership."
                    title="Tendering-outsourcing. Experts building your evaluation model. Optimised remuneration models. Various tender models. What are the Key Success Factors of Tendering & Outsourcing?  Why it’s best to outsource this to experts?"
                />

                <div className="section sla-service-wrapper overflow-hidden d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6 pr-0">
                                            <div className="sla-img-l-box position-relative">
                                                <Image
                                                    style={{ maxHeight: "500px", objectFit: "cover" }}
                                                    width="100%" height="auto"
                                                    src={ResultBg}
                                                    alt=''
                                                />
                                                <div className="sla-img-absolute-txt">
                                                    <h1 className='section-title-two text-white'>
                                                        The Result
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <div className="sla-txt-r-box bg-dark-400 p-4 h-100 d-flex justify-content-center align-content-center flex-column">
                                                <ul className="check-icon-list">
                                                    <li className="list-item text-white d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Match</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Savings by applying our Cost Intelligence philosophy</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Remuneration model stimulating business delivery & pay for performance</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Effective tools to drive performance & bank savings</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-about-wrapper mt-5 tendering-outsourcing-sla-sig">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div 
                                    className="sla-content-wrap" 
                                    style={{ background: `url("${Background}")` }}
                                    title="Strategy-to-delivery. High Value and Competitive pricing. Quality implementation. What are the key success factors of a Winning Strategy?"
                                >
                                    <div className="sla-cw-text">
                                        <h1 className='section-title-two text-white'>
                                            The Result
                                        </h1>
                                        <ul className="check-icon-list mt-3">
                                            <li className="list-item text-white d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Evaluation Models designed for best match</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Savings by applying our Cost Intelligence philosophy</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Remuneration model stimulating business delivery & pay for performance</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Effective tools to drive performance & bank savings</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sla-cw-footer-text">
                                        <h1 className="section-title-two text-white">
                                            Tendering & Outsourcing
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sla-service-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative ">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            From a winning strategy to delivery
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="section-title-two">
                                                Our <span className="text-color-primary"> Scope :</span>
                                            </h1>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Contract Manual</h5>
                                                        <p className='mt-3'>
                                                            Many are unaware of this tool. We
                                                            compose a contract manual for key
                                                            stakeholders who use the contract. A
                                                            Contract manual gives them key
                                                            information fit for their purpose. And you keep certain elements confidential.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Tender models</h5>
                                                        <p className='mt-3'>
                                                            Depending on your business objective,
                                                            the right choice of tender model gives
                                                            you maximum value; it unlock market
                                                            collaboration, a low price, and introduce latest technology.
                                                            We rejuvenate your outsourcing using
                                                            novel models.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Evaluation models</h5>
                                                        <p className='mt-3'>
                                                            Success or failure of a contract can often be traced back to the evaluation model. Well-defined it can select the right contractor to deliver key business
                                                            performance. We built your evaluation
                                                            models.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            What drives the right behaviour?
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Scope of Work</h5>
                                                        <p className='mt-3'>
                                                            A new tender with an old scope. This
                                                            often happens. Let our experts write your scope. Our strict parameters guarantee a compliant scope aligned with your business plan.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <Image
                                                    width="100%" height="auto"
                                                    src={BoxImage}
                                                    alt="powerabode Outsourcing Models, ITB, ITT, ITN, RFQ, RFI, RFP"
                                                    title="Top 8 outsourcing models for the energy industry ITB, ITT, ITN, RFQ, RFI, RFP"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-4  col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Remuneration models</h5>
                                                        <p className='mt-3'>
                                                            One of our key capabilities is to pair the right remuneration to drive contract performance. A remuneration model based on wrong drivers makes a contract ill perform. Invite our expertise, boost your contract performance!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-2">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            In 22% of contract renewals, the old scope of work is used!
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SupplyChainSlider />

                <div className="container-fluid mt-5 position-relative">
                    <Image
                        width="100%" height="auto"
                        src={CoverPhoto1}
                        alt=""
                    />
                    <div className="box-txt-top-left-absolute">
                        <h1 className='section-title-two'>
                            Related Articles and Case Studies
                        </h1>
                    </div>
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <div className="case-studies-section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h1 className="section-title-two">
                                            Top <span className="text-color-theme">Articles </span>
                                            &
                                            <span className="text-color-theme"> Case Studies</span>
                                        </h1>
                                    </div>
                                    <div className="section-content mt-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-14"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">The synergy of local content development</h5>
                                                            <p className="text-white">NOC’s have a close connect to the economic development of their country. Our services empower and progress the commercial control on Capex and Opex, but also weave in local content. NOC’s are supported on their journey to become independent operators. We have strong...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-13"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">The art of the deal</h5>
                                                            <p className="text-white">Tendering and outsourcing are where a big impact is created for future performance. Call it the art of the deal. One of our SLA service...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-8"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">How the DNA of your procurement department sets its performance</h5>
                                                            <p className="text-white">Is your function led by operations? By finance? Projects? Legal? They bring their DNA. We transform it to one driven by commercial DNA, getting cost and value efficiency...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TenderingOutsourcing;