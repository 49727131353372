import "../Style/commercial-ecosystem.css";
import { Image } from "react-bootstrap";
import { HashLink } from 'react-router-hash-link';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import "../Widget/button.css"
import { Link } from "react-router-dom";
import logo1 from "../../Images/Bg/layouts1/CVI-Logo.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import logo3 from "../../Images/Bg/layouts1/opex_capex.png";
import logo4 from "../../Images/Bg/layouts1/cms.png";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo6 from "../../Images/Bg/layouts1/academy.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

import image1 from "../../Images/commercial-btn-img/cost-efficiency.webp";
import image2 from "../../Images/commercial-btn-img/energy-transition.webp";
import image3 from "../../Images/commercial-btn-img/supply-chain-left.webp";

function CommercialEcosystemLayout1(props) {
    return (
        <>
            <section className="commercial-wrap pt-5 commercial-home-wrap d-none d-md-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-header position-relative">
                                <h1 className="section-title-two">How we help Clients?</h1>
                                <h1 className="section-title-two"> <span className="text-color-theme" >Commercial Ecosystem</span> with Products and Services</h1>
                                <h3 className="section-sub-title mt-1">
                                    Delivering <span className="text-color-theme">Cost Efficiency and Value improvement</span> - 
                                    Dealing with <span className="text-color-theme">Supply Chain Resilience</span> - 
                                    Partner in <span className="text-color-theme">Energy Transition</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="content mt-5 commercial-wrapper-desktop">
                        <div className="row">
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="box-circle-animation-wrap position-relative">
                                    <div className="box-caw-img position-relative">
                                        <Link to="/cost-value-philosophy">
                                            <div className="box-img">
                                                <Image
                                                    className="clicker w-100 h-auto"
                                                    src={logo1}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <div className="circle color-5"></div>
                                    </div>
                                    <div className="box-caw-text mt-5 text-center">
                                        <h1 className="section-title-two text-center my-3">
                                            Cost and Value Intelligence
                                        </h1>
                                        <h3 className="section-sub-title text-justify mt-1">
                                            Cost and Value Philosophy (C&V). A unique way of understanding cost, the potential savings and value optimisation. 
                                            C&V Intelligence recognises different cost categories and links them to specific contract users. 
                                            Creating synergy between these users, optimises the delivery of the savings and realising long-term value potential.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="box-circle-animation-wrap position-relative">
                                    <div className="box-caw-img position-relative">
                                        <Link to="/supply-chain-by-sla">
                                            <div className="box-img p-1">
                                                <Image
                                                    className="clicker w-100 h-auto"
                                                    src={logo2}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <div className="circle color-3"></div>
                                    </div>
                                    <div className="box-caw-text mt-5 text-center">
                                        <h1 className="section-title-two text-center my-3">
                                            Supply chain by SLA
                                        </h1>
                                        <h3 className="section-sub-title text-justify mt-1">
                                            Supply Chain by SLA. Top class experts and services to deliver your Supply
                                            Chain Challenges. Optimising cost and improving delivery.
                                        </h3>
                                        <ul className="circle-list text-left info-ul-style">
                                            <li className="list-item">Category & Contract Strategy</li>
                                            <li className="list-item">Tendering & Outsourcing</li>
                                            <li className="list-item">Post Award Contract</li>
                                            <li className="list-item">Management</li>
                                            <li className="list-item">Materials Management</li>
                                            <li className="list-item">Commercial Assurance</li>
                                            <li className="list-item">Cost Recovery</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="box-circle-animation-wrap position-relative">
                                    <div className="box-caw-img position-relative">
                                        <Link to="/capex-opex-cost-program" >
                                            <div className="box-img p-1">
                                                <Image
                                                    className="clicker w-100 h-auto"
                                                    src={logo3}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <div className="circle color-4"></div>
                                    </div>
                                    <div className="box-caw-text mt-5 text-center">
                                        <h1 className="section-title-two text-center my-3">
                                            OPEX/CAPEX Program
                                        </h1>
                                        <h3 className="section-sub-title text-justify mt-1">
                                            Improving cost and value by double digits. Award winning team. This
                                            unique program delivers you double digit cost reduction. Disruptive and
                                            controlled delivery by three tier program.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="box-circle-animation-wrap position-relative">
                                    <div className="box-caw-img position-relative">
                                        <Link to="/systems-delivery/codex-supplychainmanagmentsystem">
                                            <div className="box-img">
                                                <Image
                                                    className="clicker w-100 h-auto"
                                                    src={logo4}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <div className="circle color-1"></div>
                                    </div>
                                    <div className="box-caw-text mt-5 text-center">
                                        <h1 className="section-title-two text-center my-3">
                                            Codex
                                        </h1>
                                        <h3 className="section-sub-title text-justify mt-1">
                                            Management System, complete and off the shelf, including
                                            implementation and training. No development risk, adapted to your
                                            organisation. For
                                        </h3>
                                        <ul className="circle-list text-left info-ul-style">
                                            <li className="list-item">Contracting & Procurement</li>
                                            <li className="list-item">Pre-award</li>
                                            <li className="list-item">Post-award</li>
                                            <li className="list-item">Materials Management</li>
                                            <li className="list-item">Commercial Assurance</li>
                                            <li className="list-item">Cost Recovery</li>
                                            
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="box-circle-animation-wrap position-relative">
                                    <div className="box-caw-img position-relative">
                                        <Link to="/systems-delivery/contractmanagementtool">
                                            <div className="box-img">
                                                <Image
                                                    className="clicker w-100 h-auto"
                                                    src={logo5}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <div className="circle color-2"></div>
                                    </div>
                                    <div className="box-caw-text mt-5 text-center">
                                        <h1 className="section-title-two text-center my-3">
                                            CM+
                                        </h1>
                                        <h3 className="section-sub-title text-justify mt-1">
                                            Contract + Context. People don’t read the contract. Still, they are expected
                                            to deliver. This solution enables all contract users to be first time right.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-6 col-md-12">
                                <div className="box-circle-animation-wrap position-relative">
                                    <div className="box-caw-img position-relative">
                                        <Link to="/systems-delivery/academy" >
                                            <div className="box-img">
                                                <Image
                                                    className="clicker w-100 h-auto"
                                                    src={logo6}
                                                    alt=""
                                                />
                                            </div>
                                        </Link>
                                        <div className="circle color-6"></div>
                                    </div>
                                    <div className="box-caw-text mt-5 text-center">
                                        <h1 className="section-title-two text-center my-3">
                                            Academy
                                        </h1>
                                        <h3 className="section-sub-title text-justify mt-1">
                                            8 specific Coaching and trainings, focussed on developing commercial
                                            skillset. Integrated Supply Chain (ISC) training for teams of contract users.
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12 p-0">
                            
                            <br />
                            <div className="commercial-ecosystem-bottom-wrap text-center ">
                                <h3 className="section-sub-title mb-3 d-block font-30" >
                                    Select your <span className="text-color-theme">Challenge</span> & 
                                    find your <span className="text-color-theme">Solutions</span>
                                </h3>

                                {/* <h5 className="mt-4">3 challenges that currently drive our industry:</h5> */}

                                <div className="button-action mt-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="commercial-btn-wrap">
                                                <Image
                                                    src={image1}
                                                    alt="Cost reduction and Value optimisation for NOC’s and IOC’s, by experts with operations, maintenance and production background."
                                                    title="Improve maintenance cost, Apply Cost Intelligence Philosophy. Rates, Specification and Efficiency are critical."
                                                    className="cw-photo"
                                                />
                                                <HashLink to="/about-us#section-cost" className="px-4 py-4 badge rounded-pill bg-primary-blue"
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                                    <span>Cost Efficiency</span>
                                                    <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
                                                </HashLink>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="commercial-btn-wrap">
                                                <Image
                                                    src={image2}
                                                    alt="EU Energy-Transition and outsourcing supply chain"
                                                    title="EU Energy transition and outsourcing supply chain"
                                                    className="cw-photo"
                                                />
                                                <HashLink to="/about-us#section-energy" className="px-4 py-4 badge rounded-pill bg-primary-blue"
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                                    <span>Energy Transition</span>
                                                    <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
                                                </HashLink>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="commercial-btn-wrap">
                                                <Image
                                                    src={image3}
                                                    alt="Supply-Chain-Resilience-Management"
                                                    title="How make your supply chain Resilient?"
                                                    className="cw-photo"
                                                />
                                                <HashLink to="/about-us#section-supply-chain" className="px-4 py-4 badge rounded-pill bg-primary-blue"
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                                    <span>Supply Chain Resilience</span>
                                                    <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
                                                </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="commercial-wrap pt-5 commercial-home-wrap d-block d-md-none">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-header position-relative">
                                <h1 className="section-title-two">How we help Clients?</h1>
                                <h1 className="section-title-two"> <span className="text-color-theme" >Commercial Ecosystem</span> with Products and Services</h1>
                                <h3 className="section-sub-title mt-1">
                                    Delivering <span className="text-color-theme">Cost Efficiency and Value improvement</span> -
                                    Dealing with <span className="text-color-theme">Supply Chain Resilience</span> -
                                    Partner in <span className="text-color-theme">Energy Transition</span>
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="content my-5">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cel-wrapper cel-slider-wrapper d-flex flex-wrap">
                                    <Swiper
                                        slidesPerView={'auto'}
                                        spaceBetween={0}
                                        centeredSlides={true}
                                        loop={true}
                                        loopFillGroupWithBlank={true}
                                        pagination={{
                                            clickable: true,
                                        }}
                                        navigation={false}
                                        autoplay={{
                                            delay: 2500,
                                            disableOnInteraction: false,
                                        }}
                                        modules={[Autoplay, Pagination, Navigation]}
                                        className="mySwiper"
                                    >
                                        <SwiperSlide >
                                            <div className="cel-box-wrap cel-bg-1">
                                                <div className="theme-circle-btn">
                                                    <Link to="/cost-value-philosophy">
                                                        <Image
                                                            className="w-auto h-50"
                                                            src={logo1}
                                                        />
                                                    </Link>
                                                </div>

                                                <div className="cel-text-wrap">
                                                    <h5>
                                                        Cost and Value Intelligence
                                                    </h5>
                                                    <p>
                                                        Cost and Value Philosophy (C&V). A unique way of understanding cost, the potential savings and value optimisation.
                                                        C&V Intelligence recognises different cost categories and links them to specific contract users.
                                                        Creating synergy between these users, optimises the delivery of the savings and realising long-term value potential.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <div className="cel-box-wrap cel-bg-2">
                                                <div className="theme-circle-btn">
                                                    <Link to="/supply-chain-by-sla">
                                                        <Image
                                                            className="w-100 h-auto"
                                                            src={logo2}
                                                        />
                                                    </Link>
                                                </div>

                                                <div className="cel-text-wrap">
                                                    <h5>
                                                        Supply chain by SLA
                                                    </h5>
                                                    <p>
                                                        Supply Chain by SLA. Top class experts and services to deliver your Supply Chain
                                                        Challenges. Optimising cost and improving delivery. Category and Contract Strategy,
                                                        Tendering & Outsourcing, Post Award Contract Management, Materials Management,
                                                        Commercial Assurance and Cost Recovery.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide >
                                            <div className="cel-box-wrap cel-bg-3">
                                                <div className="theme-circle-btn">
                                                    <Link to="/capex-opex-cost-program" >
                                                        <Image
                                                            className="w-100 h-auto"
                                                            src={logo3}
                                                        />
                                                    </Link>
                                                </div>

                                                <div className="cel-text-wrap">
                                                    <h5>
                                                        OPEX/CAPEX Program
                                                    </h5>
                                                    <p>
                                                        Improving cost and value by double digits. Award winning team. This unique program
                                                        delivers you double digit cost reduction. Disruptive and controlled delivery by three
                                                        tier program.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <div className="cel-box-wrap cel-bg-4">
                                                <div className="theme-circle-btn">
                                                    <Link to="/systems-delivery/codex-supplychainmanagmentsystem">
                                                        <Image
                                                            className="w-100 h-auto"
                                                            src={logo4}
                                                        />
                                                    </Link>
                                                </div>

                                                <div className="cel-text-wrap">
                                                    <h5>
                                                        Codex
                                                    </h5>
                                                    <p>
                                                        Management System, complete and off the shelf, including implementation and
                                                        training. No development risk, adapted to your organisation. For Contracting &
                                                        Procurement, Pre-award, Post-award, Materials Management, Commercial Assurance
                                                        and Cost Recovery.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <div className="cel-box-wrap cel-bg-5">
                                                <div className="theme-circle-btn">
                                                    <Link to="/systems-delivery/contractmanagementtool">
                                                        <Image
                                                            className="w-100 h-auto"
                                                            src={logo5}
                                                        />
                                                    </Link>
                                                </div>

                                                <div className="cel-text-wrap">
                                                    <h5>
                                                        CM+
                                                    </h5>
                                                    <p>
                                                        Contract + Context. People don’t read the contract. Still, they are expected to deliver.
                                                        This solution enables all contract users to be first time right.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide >
                                            <div className="cel-box-wrap cel-bg-6">
                                                <div className="theme-circle-btn">
                                                    <Link to="/systems-delivery/academy" >
                                                        <Image
                                                            className="w-100 h-auto"
                                                            src={logo6}
                                                        />
                                                    </Link>
                                                </div>

                                                <div className="cel-text-wrap">
                                                    <h5>
                                                        Academy
                                                    </h5>
                                                    <p>
                                                        8 specific Coaching and trainings, focussed on developing commercial skillset.
                                                        Integrated Supply Chain (ISC) training for teams of contract users.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    </Swiper>  
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col-md-12">
                            
                            <br />
                            <div className="commercial-ecosystem-bottom-wrap text-center ">
                                <h3 className="section-sub-title mb-3 d-block font-30" >
                                    Select your <span className="text-color-theme">Challenge</span> & 
                                    find your <span className="text-color-theme">Solutions</span>
                                </h3>

                                {/* <h5 className="mt-4">3 challenges that currently drive our industry:</h5> */}
                                
                                <div className="button-action mt-5">
                                    <div className="row">
                                        <div className="col-sm-12 col-12">
                                            <div className="commercial-btn-wrap mb-3">
                                                <Image
                                                    src={image1}
                                                    alt=""
                                                    className="cw-photo"
                                                />
                                                <HashLink to="/about-us#section-cost" className="px-4 py-4 badge rounded-pill bg-primary-blue"
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                                    <span>Cost Efficiency</span>
                                                    <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
                                                </HashLink>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12">
                                            <div className="commercial-btn-wrap mb-3">
                                                <Image
                                                    src={image2}
                                                    alt=""
                                                    className="cw-photo"
                                                />
                                                <HashLink to="/about-us#section-energy" className="px-4 py-4 badge rounded-pill bg-primary-blue"
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                                    <span>Energy Transition</span>
                                                    <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
                                                </HashLink>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-12">
                                            <div className="commercial-btn-wrap">
                                                <Image
                                                    src={image3}
                                                    alt=""
                                                    className="cw-photo"
                                                />
                                                <HashLink to="/about-us#section-supply-chain" className="px-4 py-4 badge rounded-pill bg-primary-blue"
                                                    scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'center' })}>
                                                    <span>Supply Chain Resilience</span>
                                                    <FontAwesomeIcon icon={faAngleRight} className="ml-1" />
                                                </HashLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default CommercialEcosystemLayout1;