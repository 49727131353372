import React from 'react';
// swiper slider 
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../Style/hero-slider.css";
import HeroSectionLayout1 from './HeroSectionLayout1';
import { Image } from 'react-bootstrap';
const sliderData = [
    {
        id: '1',
        backgroundImage: 'home-new-bg-1',
        bubblesGradientColor: 'shape-bg-color-1',
        bubblesTitleValue: 'Driven',
        bubblesTitleValue2: 'by energy',
        brandName: 'powerabode ',
        boxFocosTitle: 'Delivering value improvement & cost optimisation',
        bubblesDesValue: 'is an expert in the supply chain and procurement space of large energy assets. ',
        bubblesDesValue1: 'Consulting, Outsourcing and Specialist Systems by frontline experts ready for implementation.',
        alt: "Offshore-drilling, maintenance, OPEX reduction",
        title: "How to reduce Offshore OPEX for drilling, maintenance"
    },
    {
        id: '2',
        backgroundImage: 'home-new-bg-2',
        bubblesGradientColor: 'shape-bg-color-2',
        bubblesTitleValue: 'Driven',
        bubblesTitleValue2: 'by energy',
        brandName: 'powerabode ',
        boxFocosTitle: 'Delivering value improvement & cost optimisation',
        bubblesDesValue: 'is an expert in the supply chain and procurement space of large energy assets. ',
        bubblesDesValue1: 'Consulting, Outsourcing and Specialist Systems by frontline experts ready for implementation.',
        alt: "Onshore-drilling, smart solutions, outsourcing supply chain",
        title: "How to build smart supply chain, Onshore OPEX"
    },
    {
        id: '3',
        backgroundImage: 'home-new-bg-3',
        bubblesGradientColor: 'shape-bg-color-3',
        bubblesTitleValue: 'Driven',
        bubblesTitleValue2: 'by energy',
        brandName: 'powerabode ',
        boxFocosTitle: 'Delivering value improvement & cost optimisation',
        bubblesDesValue: 'is an expert in the supply chain and procurement space of large energy assets. ',
        bubblesDesValue1: 'Consulting, Outsourcing and Specialist Systems by frontline experts ready for implementation.',
        alt: "Processing-Plant-Refinery, innovative tender models",
        title: "What are the innovative Tender models for Processing Plants and Refineries "
    },
    {
        id: '4',
        backgroundImage: 'home-new-bg-4',
        bubblesGradientColor: 'shape-bg-color-4',
        bubblesTitleValue: 'Driven',
        bubblesTitleValue2: 'by energy',
        brandName: 'powerabode ',
        boxFocosTitle: 'Delivering value improvement & cost optimisation',
        bubblesDesValue: 'is an expert in the supply chain and procurement space of large energy assets. ',
        bubblesDesValue1: 'Consulting, Outsourcing and Specialist Systems by frontline experts ready for implementation.',
        alt: "Hydrogen-h2, Supply Chain Management System",
        title: "The best Supply Chain Management System for Hydrogen and new energy?"
    }
]
const Images = [
    { icon: require('../../Images/icons/blue/group.png') },
    { icon: require('../../Images/icons/blue/group1.png') },
    { icon: require('../../Images/icons/blue/group2.png') },
    { icon: require('../../Images/icons/blue/group3.png') },
    { icon: require('../../Images/icons/blue/group4.png') },
    { icon: require('../../Images/icons/blue/group5.png') },
    { icon: require('../../Images/icons/blue/group6.png') },
    { icon: require('../../Images/icons/blue/group7.png') },
    { icon: require('../../Images/icons/blue/group8.png') }
]
function HeroSlider() {
    return (
        <section className='hero-slider-wrapper'>
            <div className="container">
                <div className="hero-info-box py-4 px-4 text-center d-none d-xl-block" style={{ zIndex: '12'}}>
                    <ul className="mb-2">
                        {Images.map((item, index) => (
                            <li className="d-inline-block" key={index}>
                                <Image
                                    height="25px" className="px-1"
                                    src={item.icon}
                                    alt=""
                                    title=""
                                />
                            </li>
                        ))}
                    </ul>
                    <p className="text-center">Operations | Maintenance | Production </p>
                </div>
            </div>
            <Swiper
                slidesPerView={1}
                spaceBetween={0}
                centeredSlides={true}
                loop={true}
                loopFillGroupWithBlank={true}
                pagination={{
                    clickable: true,
                }}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                className="mySwiper"

            >
                {sliderData.map((item, index) => (
                    <SwiperSlide key={index}>
                        <HeroSectionLayout1
                            TopIconSectionStatus='d-none'
                            HeroBgClassName={item.backgroundImage}
                            bubblesGradientColor={item.bubblesGradientColor}
                            bubblesTitleValue={item.bubblesTitleValue}
                            bubblesTitleValue2={item.bubblesTitleValue2}
                            brandName={item.brandName}
                            bubblesDesValue={item.bubblesDesValue}
                            bubblesDesValue1={item.bubblesDesValue1}
                            boxFocosTitle={item.boxFocosTitle}
                            alt={item.alt}
                            title={item.title}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </section>
    );
}

export default HeroSlider;