import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CostInitRightPhoto from "../Images/Bg/bg-4.webp";
import CostIntelligenceLogo from "../Images/Bg/CVI-Logo.png";
import {Image} from "react-bootstrap";
import SectorBox from "../Components/Widget/SectorBox";
import SupplyChainLeftPhoto from "../Images/banner/about-us/supply-chain-left.webp";
import CostEfficiencyLeftPhoto from "../Images/banner/about-us/cost-efficiency.webp";
import EnergyTransitionLeftPhoto from "../Images/banner/about-us/energy-transition.webp";
import "../Style/about.css";

import CodexIcon from "../Images/Bg/layouts1/cms.png";
import cmIcon from "../Images/Bg/layouts1/cm+.png";
import supplyChainIcon from "../Images/Bg/layouts1/supplychain.png";
import ProgramIcon from "../Images/Bg/layouts1/opex_capex.png";

import {useEffect} from "react";
import WhyPowerabode from "../Components/Sections/WhyPowerabode";
import {useLocation} from "react-router-dom";
import { Link } from "react-router-dom";
import HeroSectionLayout1 from "../Components/Sections/HeroSectionLayout1";
import { Helmet } from "react-helmet-async";

const AllSector = [
    {
        photo: require('../Images/banner/sector/sector-1.jpg'),
        color: 'bg-color-set-1',
        title: 'Upstream Onshore',
        icon: require('../Images/icons/energy-icons/onshore.png'),
        alt: "Upstream-Onshore by middle east and african NOC’s. Iraq, Erbil, Abu Dhabi, Libya, Egypt, Qatar, are just some locations.",
        bgTitle: "Upstream Onshore by middle east and African NOC’s"
    },
    {
        photo: require('../Images/banner/sector/sector-2.png'),
        color: 'bg-color-set-2',
        title: 'Upstream Oﬀshore',
        icon: require('../Images/icons/energy-icons/offshore.png'),
        alt: "Upstream-Oﬀshore LNG Angola LNG Mozambique, Chad, Cameroon, Offshore Nigeria, Brunei,",
        bgTitle: "Upstream Oﬀshore LNG Angola LNG Mozambique, Offshore Nigeria, Brunei"
    },
    {
        photo: require('../Images/banner/sector/sector-3.png'),
        color: 'bg-color-set-3',
        title: 'Downstream Reﬁnery',
        icon: require('../Images/icons/energy-icons/refinary.png'),
        alt: "Downstream-Reﬁnery Abu Dhabi, Saudi Arabia, Aramco, ADNOC, Ghana GNPC",
        bgTitle: "Downstream Reﬁnery Aramco, ADNOC"
    },
    {
        photo: require('../Images/banner/sector/sector-4.png'),
        color: 'bg-color-set-4',
        title: 'Power',
        icon: require('../Images/icons/energy-icons/power.png'),
        alt: "Power and Electrification Malaysia, Petronas, Indonesia PT Pertamina, Ghana GNPC",
        bgTitle: "Power Public private partnership. Ideal to outsource Supply Chain by Service Level Agreement. "
    },
    {
        photo: require('../Images/banner/sector/sector-5.png'),
        color: 'bg-color-set-5',
        title: 'Water Desalination',
        icon: require('../Images/icons/energy-icons/water_desalination.png'),
        alt: "Water-Desalination",
        bgTitle: "Water Desalination ACWA public private partnership"
    },
    {
        photo: require('../Images/banner/sector/sector-6.png'),
        color: 'bg-color-set-6',
        title: 'Nuclear Energy',
        icon: require('../Images/icons/energy-icons/neuclear.png'),
        alt: "Nuclear-Energy",
        bgTitle: "Nuclear Energy Mubadala"
    },
    {
        photo: require('../Images/banner/sector/sector-7.png'),
        color: 'bg-color-set-7',
        title: 'Renewable Solar',
        icon: require('../Images/icons/energy-icons/solar.png'),
        alt: "Renewable-Solar",
        bgTitle: "Renewable Solar ACWA public private partnership"
    },
    {
        photo: require('../Images/banner/sector/sector-8.png'),
        color: 'bg-color-set-8',
        title: 'Renewable Wind',
        icon: require('../Images/icons/energy-icons/wind.png'),
        alt: "Renewable-Wind-Mubadala-Vestas, Tender Public Private Partnership",
        bgTitle: "Renewable-Wind-Mubadala-Vestas, Tender Public Private Partnership"
    },
    {
        photo: require('../Images/banner/sector/sector-9.png'),
        color: 'bg-color-set-9',
        title: 'Hydrogen',
        icon: require('../Images/icons/energy-icons/hydrogen.png'),
        alt: "Hydrogen Public Private Partnership",
        bgTitle: "Hydrogen. How to set up a new Supply Chain and Procurement activity? Public Private Partnership"
    }
]

function About() {
    const {pathname, hash, key} = useLocation();

    useEffect(() => {
        if (hash === '') {
            window.scrollTo(0, 0)
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView();
                }
            }, 0);
        }
    }, [pathname, hash, key]);

    return (
        <>
            <Helmet>
                <title>About Us - Power Abode</title>
                <meta
                    name="description"
                    content="We work with companies that want cost-efficient delivery in the energy industry. We follow effective strategies to lessen the cost and ensure profits."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSectionLayout1
                    className="overflow-hidden"
                    title="About powerabode and themes. A story about energy."
                    pageTitleName="About Us & Themes"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="about-hero-bg"
                    boxTitleValue="The story of powerabode"
                    boxTitleValue2=" is a story of Energy."
                    boxDesValue="Energy assets have complex supply chains and are very comparable, regardless of the type of energy.
                                Our solutions touch on themes like energy transition, Value improvement, cost efficiency and supply chain Resilience.
                                The commercial ecosystem we developed has dozens of services that are delivered to keep our clients cost efficient."
                />

                <section className="theme-about-us-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two">Who do we serve?</h1>
                                    <h3 className="section-sub-title mt-1"><span className="theme-font">powerabode</span> is the platform for a large part of the energy industry to get to cost
                                        efficient delivery. The integrated supply chain sits at the core of this. For us,
                                        the development of the commercial ecosystem was a logical consequence of seeing how
                                        cost efficiency will play a critical role in the changing energy landscape.</h3>
                                    <h3 className="section-sub-title mt-1">National Oil Companies (NOC's) will become operators due to the energy transition.
                                        They are part of our main clientele. But also start ups in Hydrogen require robust
                                        sytems 'overnight'. Our Codex delivers that.</h3>
                                </div>

                                <div className="section-content mt-3">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap" style={{height: '306px'}}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Projects, Operations and Maintenance are all
                                                            part of our scope.</h5>
                                                        <p>We bring them to asset owners through Supply Chain by SLA
                                                            Services, our Cost Program 'The Road to Cost Leadership' and off
                                                            the shelf products like our Codex Management System for Supply
                                                            Chain and Procurement. Everything is supported by front line
                                                            experts.</p>
                                                        <p><span className="theme-font">powerabode</span> Academy is there to implement our products and
                                                            programs, and develop local content.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap" style={{height: '306px'}}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Every Supply Chain has Waste. We Fix
                                                            It.</h5>
                                                        <p>All our experts are experienced in front line delivery. They
                                                            complement our systems and methods with hands-on experience.
                                                            Driven by Energy We Deliver.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="mt-3">
                                                <h3 className="section-sub-title mt-1">We understand the commercials of these supply chains better then anyone.
                                                    Our philosophy of Cost and Value intelligence delivers cost efficiency and specification will bring 
                                                    long-term value improvement.
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="cost-init-philosophy-about-wrapper mt-5">
                    <div className="container">
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-xl-4 col-lg-5 col-md-12 col-sm-12 col-12">
                                <div className="cipaw-left-wrap position-relative">
                                    <h1 className="section-title-two">
                                        Cost and Value Intelligence Philosophy
                                    </h1>
                                    <h3 className="section-sub-title mt-1">
                                        <span className="text-color-theme">How to deliver cost efficiency and Value improvement?</span> <br/>
                                        We help you do it.
                                    </h3>
                                    <ul className="check-icon-list mt-3">
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                            <span>All our products and services are built around our cost and value intelligence philosophy. It will improve cost (OPEX/CAPEX) by double digit percentage.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                            <span>Rates are only 20% of any potential saving! Specification focus can deliver 30% of the Savings Potential. Efficiency focus can bring 40% of the Savings Potential.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                            <span>We take your organization on the road to cost leadership.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                            <span>Our analyses of Rates, Specification and Efficiency will bring out the maximum Savings Potential.</span>
                                        </li>
                                        <li className="d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck}/>
                                            <span>The Cost Program, our Services by SLA, but also our Codex System and CM+ empower your organisation utilising methods based on 
                                                the Cost and Value Intelligence Philosophy. Consistently and sustainably optimising cost and improving value!</span>
                                        </li>
                                    </ul>
                                    <div className="mt-4 text-left cost-logo">
                                        <Image
                                            height="160px"
                                            src={CostIntelligenceLogo}
                                            alt="Powerabode-Cost-&-Value-Intelligence-logo"
                                            title="powerabode Cost & Value Intelligence, specification, efficiency, rate"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-7 col-md-12 col-sm-12 col-12">
                                <div className="cipaw-right-wrap text-center">
                                    <Image
                                        src={CostInitRightPhoto}
                                        alt="Deliver-cost-efficiency-and-value-improvement. More competitive operations, maintenance and production."
                                        title="How to deliver cost efficiency and value improvement? How to use Cost Intelligence?"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="our-section-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <h1 className="section-title-two text-center">
                                        Our Sectors
                                    </h1>
                                </div>

                                <div className="section-content">
                                    <div className="all-services-wrap">
                                        <div className="row">
                                            {AllSector.map((item, index) => (
                                                <div className="col-lg-4 col-md-6 col-sm-6 col-6" key={index}>
                                                    <SectorBox
                                                        backgroundImage={item.photo}
                                                        backgroundColor={item.color}
                                                        boxTitle={item.title}
                                                        boxIcon={item.icon}
                                                        alt={item.alt}
                                                        bgTitle={item.bgTitle}
                                                    />
                                                </div>
                                            ))}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <br/>
                <section className="supply-chain-disruption-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="section-title-two">
                                    Supply Chain <span className="text-color-primary">Resilience</span>
                                </h1>
                            </div>
                            <div className="col-md-12">
                                <div className="supply-cd-wrap">
                                    <div className="scw-image float-end">
                                        <Image
                                            className="w-100"
                                            height="auto"
                                            src={SupplyChainLeftPhoto}
                                            alt="About-Supply-Chain-Resilience"
                                            title="About-Supply-Chain-Resilience"
                                        />
                                    </div>

                                    <div className="scw-content">
                                        <p>
                                            The black swan events triggered by COVID have put our awareness of the
                                            importance of understanding our supply chain & procurement processes under our
                                            full attention.
                                        </p>
                                        <p>Companies have always been aware, however, this time ‘small risks’ turned into
                                            high impact events. Cost price impact, but also availability of materials.</p>
                                        <p>For the coming years challenges will remain with us, as the transition process,
                                            and the industrial dynamics triggered by energy transition will prove to be game
                                            changers.</p>
                                        <p>What are the actual risks in your supply chain? We can map them for you, and
                                            present mitigations.</p>
                                        <p>More importantly, to have our experts involved making your Supply Chain and
                                            Procurement more robust
                                            or cost effective; it is something to consider. Not the blue suits that advice,
                                            but the agile team that takes
                                            work packages and delivers them for you.</p>
                                        <p>Supply Chain disruption and subsequent challenges can have deep implications on
                                            your operations.</p>
                                        <p>Our product CM+ can help you manage your contracts and supply chain better then
                                            ever before. As we focus on the contract users & reach out to each individual
                                            stakeholder we deliver cost efficiency.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product-services-wrapper mt-5" id="section-supply-chain">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <h1 className="section-title-two">
                                        <span className="text-color-primary">Products & Services</span> for Supply Chain
                                        Resilience
                                    </h1>
                                </div>
                                <div className="section-content mt-5">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/systems-delivery/codex-supplychainmanagmentsystem">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker"
                                                                height="100px"
                                                                src={CodexIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-1"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Codex</b>
                                                    </h3>
                                                    <p>The unique system for energy transition</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/systems-delivery/contractmanagementtool">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={cmIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-2"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>CM+</b>
                                                    </h3>
                                                    <p>
                                                        Hands on Tool <br/>
                                                        - Supported by cost experts <br/>
                                                        - For Cost efficiency in transtion
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/supply-chain-by-sla">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={supplyChainIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-3"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Supply Chain by SLA</b>
                                                    </h3>
                                                    <p>Mature organization to run your SC & cost efficiency </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br/>
                <section className="cost-efficiency-wrapper mt-5" id="section-cost">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h1 className="section-title-two">
                                        Cost <span className="text-color-primary">Efficiency</span>
                                    </h1>
                                </div>
                                <div className="cost-e-wrap">
                                    <div className="cew-image float-end">
                                        <Image
                                            className="w-100"
                                            height="auto"
                                            src={CostEfficiencyLeftPhoto}
                                            alt="About-Cost-Efficiency; for operations, maintenance and production activities."
                                            title="How to achieve Cost Efficiency and how to Improvement value?"
                                        />
                                    </div>
                                    <div className="cew-content">
                                        <p>
                                            Any energy producer has to compete on electricity pricing. This is what it all
                                            comes to with the electrification of our world. Transport will move to EV's, and
                                            virtually all appliances are electric, communication digital - all battery
                                            powered.
                                        </p>
                                        <p>Clearly cost is one of the key drivers.</p>
                                        <p>Our Cost Intelligence Philosophy sets the basis for assessing your operations. In
                                            general the focus is on rates; but our approach is based on the premise that
                                            only 20% of a saving comes from sharper rates. The majority of savings comes
                                            from efficiency and specification. Check our Cost Intelligence Philosophy for
                                            better understanding. The site also holds articles and case studies in the
                                            'thinking cost intelligence' section.</p>
                                        <p>All our services will contribute to Cost Efficiency. Foremost because they are
                                            all based on the Cost Intelligence Philosophy.</p>
                                        <p>Our flagship product is the Third Party Cost Program: The Road to Cost
                                            Leadership. This delivers savings, but also sets the right culture in terms of
                                            awareness of Cost Efficiency. The program is supported by a culture module and
                                            training, so it leaves a Commercial DNA within the client organisation.</p>
                                        <p>CM+ is our product that provides users of a contract with context. In the real
                                            world hardly any contract users reads the contract (for various reasons, for
                                            example commercial confidentiality). CM+ gives each stakeholder the necessary
                                            information to handle their part of the process in optimised fashion. Visit our
                                            CM+ section on the site for detailed explanation. </p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product-services-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <h1 className="section-title-two">
                                        <span className="text-color-primary">Products & Services</span> for Cost Efficiency
                                    </h1>
                                </div>
                                <div className="section-content mt-5">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/systems-delivery/codex-supplychainmanagmentsystem">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={CodexIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-1"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Codex</b>
                                                    </h3>
                                                    <p>The unique system for energy transition</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/systems-delivery/contractmanagementtool">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={cmIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-2"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>CM+</b>
                                                    </h3>
                                                    <p>
                                                        Hands on Tool <br/>
                                                        - Supported by cost experts <br/>
                                                        - For Cost efficiency in transtion
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/supply-chain-by-sla">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={supplyChainIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-3"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Supply Chain by SLA</b>
                                                    </h3>
                                                    <p>Mature organization to run your SC & cost efficiency </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/capex-opex-cost-program" >
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={ProgramIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-4"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Program</b>
                                                    </h3>
                                                    <p>
                                                        20%+ Commercial improvement. <br/>
                                                        A disruptive & controlled process focused on delivery
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br/>

                <section className="energy-transition-wrapper mt-4" id="section-energy">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="col-md-12">
                                    <h1 className="section-title-two">
                                        Energy <span className="text-color-primary">Transition</span>
                                    </h1>
                                </div>
                                <div className="cost-e-wrap">
                                    <div className="cew-image float-end">
                                        <Image
                                            className="w-100"
                                            height="auto"
                                            src={EnergyTransitionLeftPhoto}
                                            alt="About-Energy-Transition"
                                            title="Supply Chain Management systems to facilitate Energy Transition. Integrated supply chain management system used by NOC’s"
                                        />
                                    </div>
                                    <div className="cew-content">
                                        <p>
                                            Across the globe we are moving towards an energy transition. Where energy demand
                                            will grow over 25% over the next 20 years, an enormous task lies ahead of us.
                                            Firstly, to transit to a more sustainable energy mix, but secondly to keep up
                                            with future demand.
                                        </p>
                                        <p>During this transition systems will co-exist in parallel. Old and New. To an
                                            extend they will also compete, on cost level. Therefore, cost efficiency is
                                            critical.</p>
                                        <p>New energies will be produced by new industries. Fragmented, local and often on a
                                            relatively ‘small’ scale. </p>
                                        <p>Gas is a key source of the current mix, but also for decades to come. Hydrogen
                                            (grey, blue and green) is likely to take a large percentage as proxy for
                                            storage. Its true source will often be renewable energy. This transition will
                                            put massive pressure on industry. Resources will be scarce, specialist expertise
                                            in high demand, contractors and production capacity; it is all part of what will
                                            drive the transition.</p>
                                        <p><span className="theme-font">powerabode</span> has defined a set of services that support this process and the
                                            activities. We offer Supply Chain by SLA, as a common denominator for the
                                            transition is a high level of Supply Chain and procurement activities. The
                                            perfect set of delivery services is available, and delivered by front line
                                            experts.</p>
                                        <p>Our Codex Management system is a platform to managing and controlling everything
                                            around the Supply Chain. Not just pre- and post-award; also materials management
                                            or commercial assurance. In its standard format is ‘off the shelf’, but will be
                                            adapted to your organisation. After this we supply a range of services to
                                            guarantee compliance and governance standards. The transition will see many new
                                            start up energy assets and suppliers. We create their mature supply chain
                                            ‘overnight’. What took us years is available.</p>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="product-services-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <h1 className="section-title-two">
                                        <span className="text-color-primary">Products & Services</span> for Energy
                                        Transition
                                    </h1>
                                </div>
                                <div className="section-content mt-5">
                                    <div className="row d-flex justify-content-center">
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/systems-delivery/codex-supplychainmanagmentsystem">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={CodexIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-1"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Codex</b>
                                                    </h3>
                                                    <p>The unique system for energy transition</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/systems-delivery/contractmanagementtool">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={cmIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-2"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>CM+</b>
                                                    </h3>
                                                    <p>
                                                        Hands on Tool <br/>
                                                        - Supported by cost experts <br/>
                                                        - For Cost efficiency in transtion
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="box-circle-animation-wrap position-relative">
                                                <div className="box-caw-img position-relative">
                                                    <Link to="/supply-chain-by-sla">
                                                        <div className="box-img">
                                                            <Image
                                                                className="clicker w-100 h-auto"
                                                                src={supplyChainIcon}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </Link>
                                                    <div className="circle color-3"></div>
                                                </div>
                                                <div className="box-caw-text mt-5 text-center">
                                                    <h3 className="section-sub-title mt-1">
                                                        <b>Supply Chain by SLA</b>
                                                    </h3>
                                                    <p>Mature organization to run your SC & cost efficiency </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br/>

                <WhyPowerabode/>

            </div>
        </>
    );
}

export default About;