import React from 'react';
import { useEffect } from "react";
import HeroSection from '../../Components/Sections/HeroSection';
import CoverPhoto1 from "../../Images/Supply-chain-sla/Supply-Chain-by-SLA-Lower.webp";
import ResultBg from "../../Images/Supply-chain-sla/bird.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { Image } from 'react-bootstrap';
import SupplyChainSlider from '../../Components/Sections/SupplyChainSlider';

import Background from "../../Images/sla/4.webp";

// case study assets
import "../../Components/Style/case-studies.css";
import CaseStudiesPhoto1 from "../../Images/articles/single/7.1.jpeg";
import CaseStudiesPhoto2 from "../../Images/articles/single/3.1.jpeg";
import CaseStudiesPhoto3 from "../../Images/articles/single/1.1.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

function CommercialAssurance() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Commercial Assurance -  Powerabode</title>
                <meta
                    name="description"
                    content="We are very much an assurance company. powerabode can compose and improve your Supply Chain systems and procedures."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="commercial-bg"
                    pageTitleValue="Commercial Assurance"
                    boxTitleValue="Commercial "
                    boxTitleValue2=" Assurance"
                    boxDesValue="We identify a wide range of commercial risks and assess & control those out of your contracts.
                        It increases transparency, enhances performance and increases overall control.
                        We are very much an assurance company. powerabode can compose and improve your Supply Chain systems and procedures."
                    title=" Unique Commercial-assurance team at work. National Oil Companies are focused on compliance and governance. How to execute fully compliant deliveries using Commercial Assurance?"
                />

                <div className="section sla-service-wrapper overflow-hidden d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6 pr-0">
                                            <div className="sla-img-l-box position-relative">
                                                <Image
                                                    style={{ maxHeight: "500px", objectFit: "cover" }}
                                                    width="100%" height="auto"
                                                    src={ResultBg}
                                                    alt=''
                                                />
                                                <div className="sla-img-absolute-txt">
                                                    <h1 className='section-title-two text-white'>
                                                        The Result
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <div className="sla-txt-r-box bg-dark-400 p-4 h-100 d-flex justify-content-center align-content-center flex-column">
                                                <ul className="check-icon-list">
                                                    <li className="list-item text-white d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Compliant systems for your end-to-end Supply Chain</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Bespoke wireframe for your Supply chain</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Improved compliance using Assurance process</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span><span className="theme-font">powerabode</span> Codex basis for client requirements</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-about-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div 
                                    className="sla-content-wrap" 
                                    style={{ background: `url("${Background}")` }}
                                    title="Powerabode-compliance-governance. Why is Compliance & Governance so important for National Oil Companies?"
                                >
                                    <div className="sla-cw-text">
                                        <h1 className='section-title-two text-white'>
                                            The Result
                                        </h1>
                                        <ul className="check-icon-list mt-3">
                                            <li className="list-item text-white d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Compliant systems for your end-to-end Supply Chain</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Bespoke wireframe for your Supply chain</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Improved compliance using Assurance process</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>powerabode Codex basis for client requirements</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sla-cw-footer-text">
                                        <h1 className="section-title-two text-theme-blue">
                                            Commercial Assurance
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="sla-service-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative ">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            Compliance and Governance are key for our clients
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="section-title-two">
                                                Our <span className="text-color-primary"> Scope :</span>
                                            </h1>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Commercial Compliance</h5>
                                                        <p className='mt-3'>
                                                            Procurement of goods and services,
                                                            running operations and projects are
                                                            sensitive commercial processes. We bring Compliant Delivery.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Reporting & Monitoring</h5>
                                                        <p className='mt-3'>
                                                            Setting up various reporting and
                                                            monitoring tools, creating commercial
                                                            transparency and up to date information.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">ICV Assurance</h5>
                                                        <p className='mt-3'>
                                                            In-Country-Value is key for NOC’s. How to assure your ICV program works?
                                                            We provide auditing and assurance of
                                                            your existing systems and implementation plans.
                                                            Our input & mitigation plus corrective
                                                            actions make your ICV work!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            Governments and NOC's embrace our approach.
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Complete System Set up & Transformation</h5>
                                                        <p className='mt-3'>
                                                            <span className="theme-font">powerabode</span> created a ‘best practice’ wireframe for Category Management,
                                                            Contracting & Procurement, Logistics and Materials Management. Unique; people with field experience leading the way to an efficient supply chain.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Claims Prevention</h5>
                                                        <p className='mt-3'>
                                                            A team of Claims experts for international complex projects is available to work with you and key stakeholders.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-274">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Codex</h5>
                                                        <p className='mt-3'>
                                                            <span className="theme-font">powerabode</span> Category Management,
                                                            Contracting & Procurement and Materials Management procedure for Pre- and Post-Award. The supply chain mapped in industry standard processes with best practice. It is available for you.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            Energy assets need highest compliance and governance standards!
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SupplyChainSlider />

                <div className="container-fluid mt-5 position-relative">
                    <Image
                        width="100%" height="auto"
                        src={CoverPhoto1}
                        alt=""
                    />
                    <div className="box-txt-top-left-absolute">
                        <h1 className='section-title-two'>
                            Related Articles and Case Studies
                        </h1>
                    </div>
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <div className="case-studies-section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h1 className="section-title-two">
                                            Top <span className="text-color-theme">Articles </span>
                                            &
                                            <span className="text-color-theme"> Case Studies</span>
                                        </h1>
                                    </div>
                                    <div className="section-content mt-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-7"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Getting the supply chain management and procurement system right</h5>
                                                            <p className="text-white">Off the shelf we developed a Codex system to support your delivery. Front line experts will adapt it for you, with a view...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-3"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Deliver as Planned</h5>
                                                            <p className="text-white">Sometimes ‘surprises’ are actually predictable. The top 5 of ‘predictable’ surprises...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-1"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Hidden in Plain sight </h5>
                                                            <p className="text-white">Crazy as it may sound; there’s always an elephant in the room.. Central in our way of work are the users of the contract, the ECMT. They are underestimated as a platform. 9 Quote: Our Experts know your business...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CommercialAssurance;