import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../Style/horizontal-accordion.css";
function HorizontalAccordion() {
    const [isShown1, setIsShown1] = useState(true);
    const [isShown2, setIsShown2] = useState(false);
    const [isShown3, setIsShown3] = useState(false);
    const [isShown4, setIsShown4] = useState(false);
    const [isShown5, setIsShown5] = useState(false);

    const handleMouseEnter = (event) => {
        if (event.target.classList.contains('btn-2')) {
            setIsShown2(true); setIsShown1(false);
        }
        else if (event.target.classList.contains('btn-3')) {
            setIsShown3(true); setIsShown1(false);
        }
        else if (event.target.classList.contains('btn-4')) {
            setIsShown4(true); setIsShown1(false);
        }
        else if (event.target.classList.contains('btn-5')) {
            setIsShown5(true); setIsShown1(false);
        }
    }
    const handleMouseLeave = (event) => {
        if (event.target.classList.contains('btn-2')) {
            setIsShown2(false); setIsShown1(true);
        }
        else if (event.target.classList.contains('btn-3')) {
            setIsShown3(false); setIsShown1(true);
        }
        else if (event.target.classList.contains('btn-4')) {
            setIsShown4(false); setIsShown1(true);
        }
        else if (event.target.classList.contains('btn-5')) {
            setIsShown5(false); setIsShown1(true);
        }
    }
    return (
        <section className="services-section pt-5">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="acc-header-wrapper d-md-flex d-block">
                            <Link 
                                to="/supply-chain-by-sla" 
                                className={`acc-single-wrap services-h-bg btn-1 ${isShown1 ? 'collapse-box' : ''}`}
                                onMouseEnter={event => handleMouseEnter(event)}
                                onMouseLeave={event => handleMouseLeave(event)}
                                title="Logistics with supply ship and platform"
                            >
                                <div>
                                        <div className="text-less service-color-1">
                                            <h2>Services</h2>
                                        </div>
                                        <div className="text-more p-3">
                                            <h4 className='text-white'>
                                                Services
                                            </h4>
                                            <p className='text-white'><strong>Supply Chain by SLA.</strong> Home for Category Management and Supply Chain Challenges. Delivered by our experts. Optimising cost and adding value through 6 areas of delivery.</p>
                                        </div>
                                </div>
                            </Link>
                            <Link
                                to="/capex-opex-cost-program" 
                                className={`acc-single-wrap program-h-bg btn-2 ${isShown2 ? 'collapse-box' : ''}`}
                                onMouseEnter={event => handleMouseEnter(event)}
                                onMouseLeave={event => handleMouseLeave(event)}
                                title="Cost-program-slider"
                            >
                                <div>
                                    <div className="text-less service-color-2">
                                        <h2>Program</h2>
                                    </div>
                                    <div className="text-more p-3">
                                        <h4 className='text-white'>Program</h4>
                                        <p className='text-white'>Reducing and optimising opex/capex. By double digits. A unique three tier program, with Cost & Value Intelligence at its heart. Savings delivered by experts, creating a fabric of commercial DNA in your organisation.</p>
                                    </div>
                                </div>
                            </Link>
                            <Link
                                to="/systems-delivery/academy" 
                                className={`acc-single-wrap academy-h-bg btn-3 ${isShown3 ? 'collapse-box' : ''}`}
                                onMouseEnter={event => handleMouseEnter(event)}
                                onMouseLeave={event => handleMouseLeave(event)}
                                title="Commercial Skill set training and coaching"
                            >
                                <div>
                                    <div className="text-less service-color-3">
                                        <h2>Academy</h2>
                                    </div>
                                    <div className="text-more p-3">
                                        <h4 className='text-white'>Academy</h4>
                                        <p className='text-white'>Training modules around the Integrated Supply Chain and Cost Optimisation. All centered around the Cost and Value Philosophy.</p>
                                    </div>
                                </div>
                            </Link>
                            <Link
                                to="/articles-business-cases"
                                className={`acc-single-wrap thinking-h-bg btn-4 ${isShown4 ? 'collapse-box' : ''}`}
                                onMouseEnter={event => handleMouseEnter(event)}
                                onMouseLeave={event => handleMouseLeave(event)}
                                title="Thinking over strategy"
                            >
                                <div>
                                    <div className="text-less service-color-4">
                                        <h2>Thinking</h2>
                                    </div>
                                    <div className="text-more p-3">
                                        <h2 className='text-white'>Thinking</h2>
                                        <p className='text-white'>Our fundament is the Philosophy of Cost and Value. The articles, business cases and publications below provide context to this.</p>
                                    </div>
                                </div>
                            </Link>
                            <Link
                                to="/systems-delivery/codex-supplychainmanagmentsystem"
                                className={`acc-single-wrap codex-h-bg btn-5 ${isShown5 ? 'collapse-box' : ''}`}
                                onMouseEnter={event => handleMouseEnter(event)}
                                onMouseLeave={event => handleMouseLeave(event)}
                                title="Codex-slider"
                            >
                                <div >
                                    <div className="text-less service-color-5">
                                        <h2>Codex</h2>
                                    </div>
                                    <div className="text-more p-3">
                                        <h2 className='text-white'>Codex</h2>
                                        <p className='text-white'>The pragmatic Contracting, Procurement and Supply Chain Management System. Off-the-shelf, and adapted for each Client.</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="acc-content-wrapper mt-4">
                            {isShown1 && (
                                <div className="acc-content-wrap text-center">
                                    <p>We carry out and deliver; for operations, maintenance, projects and production. Delivery is underpinned by our <span className="theme-font">powerabode</span> Codex, our productised services, training, and off course the experts who all had mileage with IOC’s working in the field. In everything <span className="theme-font">powerabode</span> does, the focus is on Cost Leadership for you.</p>
                                </div>
                            )}
                            {isShown2 && (
                                <div className="acc-content-wrap text-center">
                                    <p>We are organised around three activities, that support and complement each other: Supply Chain by SLA; for all your Supply Chain/Procurement activities Programs; Secondly, the third party cost Program, 'The Road to Cost Leadership'. And, lastly the support by our training section; <span className="theme-font">powerabode</span> Academy.</p>
                                </div>
                            )}
                            {isShown3 && (
                                <div className="acc-content-wrap text-center">
                                    <p>We understand the commercials of energy supply chains better then anyone. Our philosophy of cost intelligence delivers cost efficiency. It’s not just about reducing rates, efficiency and specification will bring savings as well. </p>
                                </div>
                            )}
                            {isShown4 && (
                                <div className="acc-content-wrap text-center">
                                    <p>Off the Shelf. Comprehensive management system. Adapted to your organisation. For better Compliance and Governance. Including procedures, forms, work instructions and cost efficiency tips. This is a great system for new energy assets and NOC’s.</p>
                                </div>
                            )}
                            {isShown5 && (
                                <div className="acc-content-wrap text-center">
                                    <p></p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default HorizontalAccordion;