import { Image } from "react-bootstrap";
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import photo from "../../Images/articles/CommercialAssurance.webp";
import WhyPowerabode from "../../Components/Sections/WhyPowerabode";
import logo4 from "../../Images/Bg/layouts1/cms.png";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles7() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Brand new Supply Chain Management System to support integration - Powerabode</title>
                <meta
                    name="description"
                    content="Off the shelf we developed a Codex system to support your delivery. Front line experts will adapt it for you, with a view..."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles7-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Getting Supply Chain Management and procurement system right"
                    boxTitleLabel="Article >"
                    title="Supply-Chain-Procurement-Management-System"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Introducing a Supply Chain Management System that helps you deliver.
                                    </h1>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={photo}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Frontline Experts use <span className="theme-font">powerabode</span> CODEX to create your bespoke Supply Chain Management System
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            The Brief
                                                        </h5>
                                                        <p>The client had recently been acquired by an IOC and needed to transform its legacy procurement system into a complete end to end Supply Chain Management (SCM) System. This included a rejuvenation of Procurement Planning and Post-Award Contract Management.</p>
                                                        <h5 className="heading">
                                                            Kick off
                                                        </h5>
                                                        <p>Our team co-developed with the Client, a complete wireframe of Supply Chain Management and Procurement that fitted the requirement of the Client’s organization. The wireframe also identified the key areas for transformation in the Procurement Planning and Post Award Contract Management, which were additional focus areas of the Client.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            The Solution
                                                        </h5>
                                                        <p>The wireframe was transformed into a bespoke end to end Supply Chain Management System using <span className="theme-font">powerabode</span> CODEX. The CODEX is an ISO certified industry standard SCM Management System, that is equipped with framework, guidelines, processes, procedures, templates, work instructions, manuals and checklists.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Procedures were developed to cover the full procurement cycle from planning to closeout. Specific Local Content requirements for Tendering and prequalification were combined with the IOC’s Global Policies and Directives.</p>
                                                        <p>Combined with our CODEX, international best practice and first hand experience of ex-IOC frontline experts, the final product was immidiately usable in for all stakeholders in the Project, Asset, C&P, Finance, Legal, QAQC and HSSE departments.</p>
                                                        <p>Each manual was fitted clearly into the new “Procurement Framework”. A new system of Risk Categorisation for Contracts was developed and Purchase Order processing work flows were rejuvinated. All procedures were underpinned by work instructions with practical examples and guidance.</p>


                                                        <h5 className="heading">
                                                            The Result
                                                        </h5>
                                                        <p>The Client has now rolled out the new Supply Chain Management System. The collaborative approach in developing the procedures has ensured a high degree of ownership and understanding of changes from day one.</p>
                                                        <p>The final stage of the project was delivered remotely using point to point encrypted myAbode. It is our Remote Shoulder to Shoulder platform which is fully EC GDPR compliant and cloud based.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="w-100 mt-5">
                                                <div className="section-header mb-5">
                                                    <h1 className="section-title-two text-center">
                                                        Visit these services for more detail:
                                                    </h1>
                                                </div>
                                                <div className="d-flex justify-content-center gap-3 flex-wrap">
                                                    <ButtonCircle
                                                        url="/supply-chain-by-sla"
                                                        DynamicBtnColor="btn-color-3 mb-3"
                                                        image={logo2}
                                                    />
                                                    <ButtonCircle
                                                        url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                        DynamicBtnColor="btn-color-6 mb-3"
                                                        image={logo4}
                                                    />
                                                    <ButtonCircle
                                                        url="/systems-delivery/contractmanagementtool"
                                                        DynamicBtnColor="btn-color-5 mb-3"
                                                        image={logo5}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <WhyPowerabode />

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles7;