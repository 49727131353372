import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import { Image } from "react-bootstrap";
import DeliverasPlanned from "../../Images/articles/DeliverasPlanned.png";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import logo3 from "../../Images/Bg/layouts1/opex_capex.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles3() {

    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Top 5 Post Award Predictable Surprises in Power Sector - Powerabode</title>
                <meta
                    name="description"
                    content="Sometimes ‘surprises’ are actually predictable. The top 5 of ‘predictable’ surprises..."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles3-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Deliver as Planned"
                    boxTitleLabel="Article >"
                    title="Project-management-bottle-necks"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Some surprises are actually predictable. Proper Post Award Contract Management prevents the expected!
                                    </h1>
                                    
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={DeliverasPlanned}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Top 5 Post Award Predictable Surprises!
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Post Award Contract Management, or to deliver as planned. The definition is simple, but at the same time delivery always poses major challenges. Translating the planning stage to real life. Transforming from paper to something tangible.</p>
                                                        <p>Generally, we define top 5 areas where we see bottlenecks in the delivery. An early focus on Post Award Contract Management can prevent or at least mitigate these issues.</p>
                                                        <p>We tend to digitise everything these days. Supply Chain, Procurement. As organisations rationalise their staff during a crisis the push is for ever more digitisation and automated solutions. But to deliver projects and contracts requires real people. Preferably the kind that knows what they are doing. Hence our mission as Supply Chain Expert to establish the connect between systems and the digitised context to experts with frontline experience. They will deliver for you and with you.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">The top 5 bottle necks in Post Award Contract Management:</h5>
                                                        <p> <b>1. Project to Operations handover  – </b> What is the leading indicator during this phase? Often conflicting KPI’s contribute to an inefficient handover, impacting negatively on the time to get to full operation.</p>
                                                        <p> <b>2. Pre award to Post award handover – </b> Two different teams at work. For the right reasons. But to what extend do they align their objectives.</p>
                                                        <p> <b>3. Lack of alignment between business plan and the contracting strategy – </b> The contracting strategy is often based on historic information. An easy corner to cut. But, it impacts the outcome and operations in a profound way. To what extend does the contract strategy align with the business plan. Of the asset. Or the corporation. Contract strategy requires thorough piece of work that should be done with focus and diligence. It is a forward looking exercise with leading indicators.</p>
                                                        <p> <b>4. Inadequate scope of work – </b> The old scope can be recognised in the new tender. As it was copied. This is a major flaw and a loss of opportunity. First of all, scope evolves. It always requires updating. Analyse the previous spend. Interview stakeholders. Then write a new scope.</p>
                                                        <p> <b>5. PO’s linked the wrong contract, but the right contractor – </b>  Innocent mistakes, with serious but unintended consequences. No administration is flawless, but structural mistakes on this level create chaos in the field that continues to repeat itself. Most likely for the duration of the contract.</p>
                                                        <p>Post Award Contract Management is one of the top 3 products of our of our Supply Chain by SLA. We typically provide an interface manager, conduct TCO/ TVO improvement programs, build and maintain Contract Management App that keeps a grip on your contract performance.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    

                                    <div className="w-100 mt-5">
                                        <div className="section-header mb-5">
                                            <h1 className="section-title-two text-center">
                                                Visit these services for more detail:
                                            </h1>
                                        </div>
                                        <div className="d-flex justify-content-center gap-3 flex-wrap">
                                            <ButtonCircle
                                                url="/supply-chain-by-sla"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                image={logo2}
                                            />
                                            <ButtonCircle
                                                url="/post-award-contract-management"
                                                titleFirst="Post Award Contract Management"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            />
                                            <ButtonCircle
                                                url="/capex-opex-cost-program" 
                                                DynamicBtnColor="btn-color-2 mb-3"
                                                image={logo3}
                                            />
                                            <ButtonCircle
                                                url="/systems-delivery/contractmanagementtool"
                                                DynamicBtnColor="btn-color-5 mb-3"
                                                image={logo5}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles3;