import { faCircleCheck, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeroSection from "../Components/Sections/HeroSection";
import "../Style/codex-delivery.css";
import { Image } from "react-bootstrap";
import ExpertServiceLogo from "../Images/banner/codex-and-delivery/Picture1.png"
import CodexBottomCover from "../Images/articles/single/articles-bottom-cover-img.webp"
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const CodexElement =[
    {
        id: "1",
        volume: 'Volume 1',
        image: require('../Images/banner/codex-and-delivery/codex-1.webp'),
        title: "CODEX",
        text: "Procurement & Supply Chain Management System.",
        alt: "Powerabode-codex Contracting, Procurement and Supply Chain Management System",
        imgTitle: "The Management System-Vol 1 Contracting, Procurement and Supply Chain Management System"
    },
    {
        id: "2",
        volume: 'Volume 2',
        image: require('../Images/banner/codex-and-delivery/preawardcontrolprocedures.webp'),
        title: "PRE-AWARD CONTROL PROCEDURES",
        text: "Process, Procedures, Controls and templates from demand identification until approval of award",
        alt: "powerabode-pre-award-control-procedures for , ITB, ITT, ITN, RFQ, RFI, RFP, tender evaluation, remuneration",
        imgTitle: "Pre-Award Control Procedures – Vol 2 for , ITB, ITT, ITN, RFQ, RFI, RFP, tender evaluation, remuneration"
    },
    {
        id: "3",
        volume: 'Volume 3',
        image: require('../Images/banner/codex-and-delivery/postawardcontrolprocedures.webp'),
        title: "POST-AWARD CONTROL PROCEDURES",
        text: "Always underestimated. Structures proper implementation, Avoids loss of intent..",
        alt: "Powerabode Post-award-control-procedures for post tender contract delivery and contract management",
        imgTitle: "Post-Award Control Procedures – Vol 3 for post tender contract delivery and contract management"
    },
    {
        id: "4",
        volume: 'Volume 4',
        image: require('../Images/banner/codex-and-delivery/materialsmanagementvol4.webp'),
        title: "MATERIALS MANAGEMENT",
        text: "Part 1, 2 and 3. It will help you get the right materials at the right place. Now and in 5 years.",
        icon: require('../Images/banner/codex-and-delivery/Picture1.png'),
        alt: "Powerabode-materials-management",
        imgTitle: "Materials Management – Vol 4 Forms, guidelines, procedures, templates, work instruction and forms. 3 volumes and supported by experts with IOC background"
    },
    {
        id: "5",
        volume: 'Volume 5',
        image: require('../Images/banner/codex-and-delivery/commercialassurancevol5.webp'),
        title: "COMMERCIAL ASSURANCE",
        text: "Framework for compliant delivery. Assurance provided by our experts as a service.",
        icon: require('../Images/banner/codex-and-delivery/Picture1.png'),
        alt: "Powerabode-commercial-assurance",
        imgTitle: "Commercial Assurance – Vol 5 Governance and compliance for commercial matters in and around the supply chain of energy assets"
    },
    {
        id: "6",
        volume: 'Volume 6',
        image: require('../Images/banner/codex-and-delivery/commercialtrainingvol6.webp'),
        title: "COMMERCIAL TRAINING",
        text: "This service module delivers 8 commercial skillset trainings and the Integrated Supply Chain training.",
        icon: require('../Images/banner/codex-and-delivery/Picture1.png'),
        alt: "Powerabode-commercial-training",
        imgTitle: "Commercial Training – Vol 6"
    },
    {
        id: "7",
        volume: 'Volume 7',
        image: require('../Images/banner/codex-and-delivery/costrecoveryvol7.webp'),
        title: "COST RECOVERY",
        text: "Process and experience to achieve maximum recovery. Delivered for you and with you, as a service.",
        icon: require('../Images/banner/codex-and-delivery/Picture1.png'),
        alt: "Powerabode-cost-recovery",
        imgTitle: "Cost Recovery – Vol 7 A team of experts delivers cost recovery, compliant and according to governance standards"
    },
]
function Codex() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>CODEX Management System - Powerabode</title>
                <meta
                    name="description"
                    content="A team of PowerAbode Experts have developed the ideal Procurement and Supply Chain Management System. Focussed on Cost Efficiency and compliant delivery. Creating the optimum ways of work. Adaptable to individual standards and thresholds of any energy asset."
                />
            </Helmet>
            <div>
            {/* // <div> */}
                <HeroSection
                    className="codex-bg"
                    title="Industry standard and pragmatic Contracting, Procurement and Supply Chain Management System. "
                    pageTitleValue="Codex"
                    boxTitleValue="CODEX "
                    boxTitleValue2="Supply Chain Management System"
                    boxDesValue="Procurement and Supply Chain Management System for Energy Assets
                                In 7 volumes. A team of PowerAbode Experts have developed the ideal Procurement and Supply Chain Management System. Focussed on Cost Efficiency and compliant delivery.
                                Creating the optimum ways of work. 
                                Adaptable to individual standards and thresholds of any energy asset. The volumes are delivered through digital portal ‘MyAbode’. Commercial Assurance, Cost Recovery and Training are provided as a Service by PowerAbode."
                />

                <section className="codex-about-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-12">
                                <div className="sla-cw-text">
                                    <h1 className='section-title-two text-white'>
                                        Complete system from day one
                                    </h1>
                                    <ul className="check-icon-list mt-3">
                                        <li className="list-item text-white d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Zero development risk</span>
                                        </li>
                                        <li className="list-item text-white d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Made for energy assets</span>
                                        </li>
                                        <li className="list-item text-white d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Supported by services & frontline Experts</span>
                                        </li>
                                        <li className="list-item text-white d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>With tools & strategies for Cost Efficiency</span>
                                        </li>
                                        <li className="list-item text-white d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Delivered through MyAbode platform</span>
                                        </li>
                                        <li className="list-item text-white d-flex items-center">
                                            <FontAwesomeIcon icon={faCircleCheck} />
                                            <span>Per client: adaptation and implementation program</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-12">
                                <div className="caw-r-wrap d-flex justify-content-center align-content-center flex-column h-100 test-center">
                                    <h1 className="section-title-two text-center">
                                        Various Volumes Supported By:
                                    </h1>
                                    <Image
                                        className="mx-auto mt-3"
                                        height="auto" width="150px"
                                        src={ExpertServiceLogo}
                                        alt="Powerabode-expert-service-logo"
                                        title="powerabode Expert Service delivered with Service Level Agreements."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="bg-theme-blue position-relative py-5 mt-5 text-center">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <h1 className="section-title-two">Codex Management System</h1>
                                <h3 className="section-sub-title mt-1">
                                    System | Processes | Controls | Forms | Work Instructions
                                </h3>
                            </div>
                        </div>
                    </div>
                </section>

                

                <div className="section codex-list-wrapper ">
                    <div className="container">
                        <div className="row">
                            {CodexElement.map((item, index) => (
                                <div className="col-md-6 col-12" key={index}>
                                    <div className="single-codex-list pt-5" key={index}>
                                        <h5 className="heading">{item.volume}</h5>
                                        <div className="scl-img-wrap">
                                            <Image
                                                height="auto" width="100%"
                                                src={item.image}
                                                alt={item.alt}
                                                title={item.imgTitle}
                                            />
                                        </div>
                                        <div className={`single-cl-txt h-100 px-lg-3 ${item.icon ? 'sct-img-wrap' : ''}`}>
                                            {item.icon && (
                                                <div className="mb-4">
                                                    <Image
                                                        className="mx-auto"
                                                        height="auto" width="100px"
                                                        src={item.icon}
                                                        
                                                    />
                                                </div>
                                            )}
                                            <div className={`${item.icon ? 'p-3 pt-0' : ''}`}>
                                                <h1 className="section-title-two">{item.title}</h1>
                                                <p>{item.text}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="codex-table-section mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header">
                                    <h1 className="section-title-two text-center">
                                        Table of Content
                                        <span className="text-color-primary"> Codex System</span>
                                    </h1>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="codex-table-wrapper table-responsive mt-4">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th  className="th-primary-color">Volume</th>
                                                <th  className="th-primary-color">Codex Volumes</th>
                                                <th colSpan={3}  className="th-primary-color">Delivery Method</th>
                                            </tr>
                                            <tr>
                                                <th colSpan={2} ></th>
                                                <th  className="th-secondary-color text-color-primary">Books</th>
                                                <th  className="th-secondary-color text-color-primary">Portal</th>
                                                <th  className="th-secondary-color text-color-primary">Expert Services</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th  className="th-primary-color">Volume 1</th>
                                                <th  className="th-primary-color">The Contracting, Procurement and Supply Chain Management System</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color">-</td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 2</th>
                                                <th  className="th-primary-color">Pre-Award Control Procedure</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color">-</td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 3</th>
                                                <th  className="th-primary-color">Post-Award Control Procedure</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color">-</td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 4</th>
                                                <th  className="th-primary-color">Materials Management: MM Guideline</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 4.1</th>
                                                <th  className="th-primary-color">MM Control Procedures</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 4.2</th>
                                                <th  className="th-primary-color">MM Templates</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 5</th>
                                                <th  className="th-primary-color">Commercial Assurance</th>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 5.1</th>
                                                <th  className="th-primary-color">Assurance Questionnaires</th>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 5.2</th>
                                                <th  className="th-primary-color">Assurance: Diagnostics</th>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 6</th>
                                                <th  className="th-primary-color">Cost Recovery</th>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                            <tr>
                                                <th  className="th-primary-color">Volume 7</th>
                                                <th className="th-primary-color">Coaching by <span className="theme-font text-white">powerabode</span> Academy</th>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color">-</td>
                                                <td  className="th-secondary-color"><FontAwesomeIcon icon={faCheck} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container-fluid mt-5">
                    <Image
                        width="100%" height="auto"
                        src={CodexBottomCover}
                        alt="powerabode-codex-management-system"
                        title="myAbode – Portal Delivered  Management System and control procedures"
                    />
                </div>

                
            </div>
        </>
    );
}

export default Codex;