import React from 'react';
import { useEffect } from "react";
import { Helmet } from 'react-helmet-async';


function Disclaimer() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Copyright 2023 – Power Abode</title>
                <meta
                    name="description"
                    content="This website ( powerabode.com ) and its content is copyright of powerabode DMCC, Dubai, UAE. All rights reserved."
                />
            </Helmet>
            <div className='container'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="ht-box-icon">
                            <div className="icon-box-wrap">
                                <div className="content text-left">
                                    <h3 className="section-sub-title">Disclaimer</h3>
                                    <p>The information contained on this website ( <a href='/' className='text-color-theme'>www.powerabode.com</a> ) is for general information purposes only and is provided on an “as is,” “as available” basis. Every effort is made to keep the website up and running smoothly. However, <span className="theme-font">powerabode</span> DMCC takes no responsibility for and will not be liable for the website being temporarily unavailable due to technical issues beyond our control.</p>
                                    <p>The information is provided by <span className="theme-font">powerabode</span> and whilst we endeavour to keep the information up-to-date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose. Any reliance you place on such information is strictly at your own risk.</p>
                                    <p>We accept no responsibility for any loss, of whatever nature, that may arise from use of this website or your reliance on any of the information contained in this site and in no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of or in connection with the use of this website. We cannot accept any responsibility for any damages, loss, or viruses arising directly or indirectly from the use of this website.</p>
                                    <p>The views and opinions of authors expressed on this website do not necessarily reflect or state those of <span className="theme-font">powerabode</span> or Shareholders or Directors.</p>
                                    <p>We reserve the right to change the information contained within this website (including these terms and conditions) at any time.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Disclaimer;