import "../Style/cost-program.css";
import { Image } from 'react-bootstrap';
// import RoadMap1 from "../Images/Bg/road-map-1.png";
// import RoadMap2 from "../Images/Bg/road-map-2.png";
// import RoadMap3 from "../Images/Bg/road-map-3.png";
// import RoadMap4 from "../Images/Bg/road-map-4.png";
import {useEffect} from "react";

// import photo1 from "../Images/cost-program/shutterStock.png";
// import photo2 from "../Images/cost-program/MiningDrillBit.G141.png";
// import photo3 from "../Images/Bg/open-capex/oil-and-gas-refinery-plant-form-industry-zone-aer-2022-06-16-18-32-56-utc.png";
// import photo4 from "../Images/cost-program/photo4.png";
// import photo5 from "../Images/cost-program/photo5.png";
// import photo6 from "../Images/Bg/open-capex/hydrogen-renewable-metal-fuel-tank-green-energy.png";

// import dnaPhoto from "../Images/Bg/open-capex/introducing-01.png"


import CostIntelligenceLogo from "../Images/Bg/CVI-Logo.png";
import HeroSectionLayout1 from '../Components/Sections/HeroSectionLayout1';


import image1 from "../Images/cost-program/new/1.png";
import image2 from "../Images/cost-program/new/2.webp";
import image3 from "../Images/cost-program/new/3.webp";
import image4 from "../Images/cost-program/new/4.webp";
import image5 from "../Images/cost-program/new/5.webp";

import opexLogo from "../Images/cost-program/new/opex_capex_big_logo.png";
import costProgramLogo from "../Images/Bg/layouts1/CostProgramLogo.png";
import { Helmet } from "react-helmet-async";

function CostProgram() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Optimising CAPEX and OPEX for maintenance, operations and production - Powerabode</title>
                <meta
                    name="description"
                    content="Reducing Opex by double digit percentage. Optimising Capex and Opex. Involving cost experts with frontline experience to optimise value."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSectionLayout1
                    className="overflow-hidden"
                    pageTitleName="Capex / Opex Program"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="cost-program-bg"
                    bubblesGradientColor="shape-bg-color-1"
                    bubblesTitleValue="Delivering value improvement & cost reduction of "
                    bubblesTitleValue2="20-30%"
                    boxTitleValue="Success Formula!"
                    boxTitleValue2="Disruptive and Controlled."
                    // bubbleslogo={opexLogo}
                    title="Top 5 focus for competitive energy assets: 1. Lowest OPEX 2. Lowest TCO 3. Best CAPEX for a project lifecycle 4. Optimized CO2 footprint 5. Highest uptime"
                />

                <section className="theme-about-us-wrapper mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative" data-aos="fade-up" data-aos-easing="ease">
                                    <Image
                                        style={{width: '50%'}}
                                        className="mb-3"
                                        src={opexLogo}
                                        alt=""
                                    />
                                    {/* <h1 className="section-title-two">Cost Program: The Road to Cost Leadership</h1> */}
                                    {/* <p>Reducing Opex by double digit percentage. Optimising Capex and Opex. Involving cost experts with frontline experience to optimise value.</p> */}
                                    <h3 className="section-sub-title mt-1">
                                        Reducing Opex by double digit percentage. Optimising Capex and Opex. Involving cost experts with frontline experience to optimise value.
                                    </h3>
                                </div>

                                <div className="section-content mt-3">
                                    <div className="row">
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                                                <div className="icon-box-wrap" style={{ height: '265px' }}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Why a program?</h5>
                                                        <p>Cost efficiency is a science. Organisations often see cost as static; Switch on / switch off. Cost Efficiency however is something else. Our Cost leadership Program analyses, gives tools, breaks existing conventions. It is critical to have a program in times where the revenue base is under pressure in every market. Cost inflation and Covid have also created a new reality that needs attention to stay competitive.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                                                <div className="icon-box-wrap tier-structure-bg" style={{ height: '265px' }}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">3-Tier structure</h5>
                                                        <p>We deliver savings. Also, we take your teams with us on that journey. Analysis, Delivery and optimise value Training are the three pillars of our Cost Leadership Program.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                                                <div className="icon-box-wrap" style={{ height: '255px' }}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Cost and Value intelligence</h5>
                                                        <p>The basis of this philosophy is that cost is not about rates only. The majority of the savings potential sits in Specification and Efficiency. We use the Extended Contract Management Team (ECMT) to deliver the full savings potential.</p>
                                                        <Image
                                                            className='float-end cost-init-logo-style'
                                                            height="auto" width="120px"
                                                            src={CostIntelligenceLogo}
                                                            alt="Powerabode-Cost-&-Value-Intelligence-logo"
                                                            title="powerabode Cost & Value Intelligence, specification, efficiency, rate"
                                                        />
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                                                <div className="icon-box-wrap" style={{ height: '255px' }}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Bespoke</h5>
                                                        <p>We will adapt the program to your organisation. The targets, training modules, structure to make it inclusive. We will work with you to define the success.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="400">
                                                <div className="icon-box-wrap opacity-wrap" style={{ height: '255px' }}>
                                                    <div className="content text-left">
                                                        <h5 className="heading">Distruptive & Controlled</h5>
                                                        <p>The impact will be deep, as the targets are not modest. We control the impact in our way of work with focus on success, not on blame, Training, Teams celebrate savings. All ingredients of the program.</p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-12">
                                            <div className="ht-box-icon" data-aos="fade-up" data-aos-easing="ease" data-aos-delay="500">
                                                <div 
                                                    className="icon-box-wrap winner-bg" 
                                                    style={{ height: '255px' }}
                                                    title="Cost-leaders. Experts from Shell, BP, Total and Exxon | Become the Cost Leader in your industry."
                                                >
                                                    <div className="content text-left">
                                                        <Image
                                                            className='float-end cost-program-logo-style'
                                                            height="auto" width="250px"
                                                            src={costProgramLogo}
                                                            alt='photo'
                                                        />
                                                        <h5 className="heading">Winners!</h5>
                                                        <p>A successful cost saving program is in the end delivered by your own people. We will equip them, involving them in our methods & providing training. Your cost Leadership will make you future proof in the turbulent energy markets</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <section className="theme-about-us-wrapper mt-5 d-none ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two"><span className="text-color-primary">Key Elements</span> for 20-30% Opex and Capex reduction</h1>

                                    <br />

                                    <h1 className="section-title-two"><span className="text-color-primary">The Program</span></h1>
                                    <h3 className="section-sub-title mt-1">The Road to Cost Leadership</h3>
                                </div>

                                <div className="section-content mt-5">
                                    <div className="single-road-map-wrap">
                                        <div className="srmw-text">
                                            <h1 className="section-title-two">Cost Intelligence Philosophy</h1>
                                            <p>The program is built on the Cost Intelligence Philosophy. This unlocks value and savings, over and above any traditional approach.</p>
                                        </div>
                                        <div className="srmw-img">
                                            <Image
                                                className='w-100'
                                                src={RoadMap1}
                                                alt='photo'
                                            />
                                        </div>
                                    </div>
                                    <div className="single-road-map-wrap">
                                        <div className="srmw-text">
                                            <h1 className="section-title-two">Building Savings Business Case</h1>
                                            <p>We use an extensive and unique system of analyses to identify savings.Dozens of business cases all Greater than 20% will be created for approval to the client. Then the implementation will be done by our integrated team with the client.</p>
                                        </div>
                                        <div className="srmw-img">
                                            <Image
                                                className='w-100'
                                                src={RoadMap2}
                                                alt='photo'
                                            />
                                        </div>
                                    </div>
                                    
                                    <div className="single-road-map-wrap">
                                        <div className="srmw-text">
                                            <h1 className="section-title-two">Delivery</h1>
                                            <p>We deliver the savings with you. Using our services, but also by your teams directly. The Savings Business Cases give the implementation scenario, and are signed of by the Client.</p>
                                        </div>
                                        <div className="srmw-img">
                                            <Image
                                                className='w-100'
                                                src={RoadMap3}
                                                alt='photo'
                                            />
                                        </div>
                                    </div>
                                    <div className="single-road-map-wrap">
                                        <div className="srmw-text">
                                            <h1 className="section-title-two">Culture & Coaching. Creating Winning Commercial DNA</h1>
                                            <p>The processes in our program are focussed on creating winners. A no blame culture is key in finding and delivering savings. We create new commercial DNA in your organisation.</p>
                                        </div>
                                        <div className="srmw-img">
                                            <Image
                                                className='w-100'
                                                src={RoadMap4}
                                                alt='photo'
                                            />
                                        </div>

                                        <div className="mt-3 text-center">
                                            <p>For more information on the Cost Program contact us for a presentation / workshop.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="dna-wrapper d-none" data-aos="fade-up" data-aos-easing="ease">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="dna-wrap">
                                    <Image
                                        className='w-100 h-auto'
                                        src={dnaPhoto}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="cost-program-wrapper position-relative py-5 wrapper d-none" data-aos="fade-up" data-aos-easing="ease">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="cost-program-wrap" >
                                    <h1 className='section-title-two text-center'>
                                        Award Winning Team
                                    </h1>
                                    <div className="cost-program-b d-flex justify-content-center align-items-center">
                                        <div>
                                            <Image
                                                className='w-100 MoveUpDown'
                                                src={photo1}
                                                alt='photo'
                                            />
                                        </div>
                                        <div >
                                            <Image
                                                className='w-100 MoveUpDownReturn'
                                                src={photo2}
                                                alt='photo'
                                            />
                                        </div>
                                    </div>
                                    <h3 className='section-sub-title mt-1 text-center' >
                                        Combining frontine experience  <br />
                                        with unique CI-Data assessment
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ship-image w-100 mt-3 mb-4" >
                        <Image
                            className='w-100 MoveLeftRight'
                            src={photo3}
                            alt='text'
                        />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="cost-photo-4 w-100 d-flex justify-content-end" >
                                    <Image
                                        className='w-auto  MoveRightLeft'
                                        src={photo4}
                                        alt='photo'
                                    />
                                </div>
                                <div className="cost-photo-5 w-100" >
                                    <Image
                                        className='w-auto MoveLeftRight'
                                        src={photo5}
                                        alt='photo'
                                    />
                                </div>
                                <h1 className='section-title-two text-center'>
                                    Opex <br />
                                    Optimisation {'>'} 20%
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="cost-photo-6 w-100">
                        <Image
                            className='w-100'
                            src={photo6}
                            alt='photo'
                        />
                    </div>
                </div> */}
                

                <div className="opex-capex-wrapper dna-img" title="Cost-intelligence-DNA | Commercial DNA for your team. Focused on operations, maintenance and production of oil, gas and other energies.">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="op-wrap">
                                    <div className="op-image-wrapper">
                                        <Image
                                            className='op-image-style mb-4 w-100'
                                            src={image1}
                                            alt='Powerabode-team. Background with IOC’s like Shell, TotalEnergies, BP and others. Working on African, Asian and Middle East oil and gas projects, and energy transition in Europe.'
                                            title="International Oil Company IOC Award Winning Team"
                                        />
                                        <Image
                                            className='op-image-style mb-4 w-100'
                                            src={image2}
                                            alt='Tier-1-cost-intelligence-philosophy'
                                            title="Cost Program Tier 1 Cost Intelligence Philosophy offshore oil gas energy "
                                        />
                                        <Image
                                            className='op-image-style mb-4 w-100'
                                            src={image3}
                                            alt='Tier-2-Delivery-and-implementation'
                                            title="Cost Program Tier 2 Delivery and Implementation Onshore oil gas energy"
                                        />
                                        <Image
                                            className='op-image-style mb-4 w-100'
                                            src={image4}
                                            alt='Tier-3-culture-and-training'
                                            title="Cost Program Tier 3 Culture and Training oil gas energy"
                                        />
                                        <Image
                                            className='op-image-style w-100'
                                            src={image5}
                                            alt='Powerabode-evi, gap analysis,  Standing on platform, overlooking logistics'
                                            title="Enhanced Value Improvement for energy assets."
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CostProgram;