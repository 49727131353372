import { Image } from 'react-bootstrap';
import DealPhoto1 from '../Images/deal-page/deal-img-1.jpg';
import DealPhoto2 from '../Images/deal-page/deal-img-2.jpg';
import DealPhoto3 from '../Images/deal-page/deal-img-3.jpg';
import {useEffect} from "react";
import HeroSectionLayout1 from '../Components/Sections/HeroSectionLayout1';
import { Helmet } from 'react-helmet-async';
function DealDesign(props) {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The Perfect Deal Design Strategy Against Each Challenge - Powerabode</title>
                <meta
                    name="description"
                    content="Our strategy called “Deal Design” covers sourcing plans, contracts supported by SBLC,  implementation finance and assets, and more. Let’s know how."
                />
            </Helmet>
            <div className='bg-white'>
            
                <HeroSectionLayout1
                    className="overflow-hidden"
                    title="Deal-design Business Development for energy assets"
                    pageTitleName="Deal Design"
                    TopIconSectionStatus='d-block'
                    HeroBgClassName="deal-design-bg"
                />

                <section className="theme-deal-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two deal-title">Deal Design</h1>
                                    <h3 className="section-sub-title mt-1">Bespoke. It sometimes takes an outside view, or an independent position to resolve a situation.</h3>
                                    <h3 className="section-sub-title mt-1">Supply Chain Disruption & the Energy Transition bring new situations about. We have already been involved in some of the following challenges. We call this activity “Deal Design”.</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="deal-content-wrapper mt-5">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 col-12">
                                <div className="half-wrapper d-md-flex justify-content-center align-items-center">
                                    <div className="half-wrap-text w-50 pr-md-20">
                                        <div className="ht-box-icon">
                                            <div className="icon-box-wrap  mt-0">
                                                <div className="content text-left">
                                                    <h5 className="heading">Sourcing Strategy</h5>
                                                    <p>Supply Chain Disruption created a new awareness after COVID. Any Supply Chain can encounter a Black Swan Event. The impact of this is one thing, the assumption that everything will go back again to ‘normal’, is another.</p>
                                                    <p>We set up your Sourcing Strategy and establish alternative solutions. Risk assessed and robust.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half-wrap-image w-50">
                                        <Image
                                            width="100%"
                                            src={DealPhoto1}
                                            alt='sourcing-strategy'
                                            title="Sourcing Strategy"
                                        />
                                    </div>
                                </div>
                                <div className="half-wrapper d-md-flex justify-content-center align-items-center">
                                    <div className="half-wrap-image w-50">
                                        <Image
                                            width="100%"
                                            src={DealPhoto2}
                                            alt='oil-gas-sourcing-finance'
                                            title="Petroleum Product Sourcing & Finance"
                                        />
                                    </div>
                                    <div className="half-wrap-text w-50 pl-md-20">
                                        <div className="ht-box-icon">
                                            <div className="icon-box-wrap  mt-0">
                                                <div className="content text-left">
                                                    <h5 className="heading">Petroleum Product Sourcing & Finance</h5>
                                                    <p>Both on deal and process level. Deal structuring, including finance options. We have direct involvement in consortia to bring the most favourable options to our clients regrading fuel supply. Diesel, Petrol and LNG are key products where we consult clients.</p>
                                                    <ul className="disclosure-closed-list">
                                                        <li className="list-item">Yearly contracts, supported by SBLC, are subject to discount structures.</li>
                                                        <li className="list-item">For governments and their NOC’s finance options are available. </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="half-wrapper d-md-flex justify-content-center align-items-center">
                                    <div className="half-wrap-text w-50 pr-md-20">
                                        <div className="ht-box-icon">
                                            <div className="icon-box-wrap  mt-0">
                                                <div className="content text-left">
                                                    <h5 className="heading">Asset & Operations Modelling</h5>
                                                    <ul className="disclosure-closed-list">
                                                        <li className="list-item">Set up of structure for operations of energy assets for National Oil Companies (NOC’s)</li>
                                                        <li className="list-item">Finance of new assets</li>
                                                        <li className="list-item">Program to develop NOC’s towards becoming an Operator</li>
                                                        <li className="list-item">Handover-model and Handover-program for assets. For example IOC stepping out, and handing over to the National Oil Company.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="half-wrap-image w-50">
                                        <Image
                                            width="100%"
                                            src={DealPhoto3}
                                            alt='asset-operations-modeling'
                                            title="asset-operations-modeling"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default DealDesign;