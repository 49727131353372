import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import { Image } from "react-bootstrap";
import { useEffect } from "react";
import Ecmt from "../../Images/articles/ecmt.png";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo1 from "../../Images/Bg/layouts1/CVI-Logo.png";
import { Helmet } from "react-helmet-async";



function Articles1() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>What is the Extended CMT Tool for Influencing Cost? - Powerabode</title>
                <meta
                    name="description"
                    content="Crazy as it may sound; there’s always an elephant in the room.. Central in our way of work are the users of the contract, the ECMT. They are underestimated as a platform."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles1-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Hidden in plain Sight"
                    boxTitleLabel ="PHILOSOPHY >"
                    title="Solutions-to-influence-cost"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">What is the best team to manage a contract? How Does It Work?</h1>
                                
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={Ecmt}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                A forgotten gem.
                                            </h3>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                <b className="text-color-theme">The Extended Contract Management Team (ECMT)</b> <br />
                                                as a tool to influence cost. After all, they use the Contract.
                                            </h3>
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>There is always an elephant in the room. In our philosophy of Cost Intelligence we acknowledge that the ECMT is an ideal platform to obtain cost efficiency. The ECMT is the wider team that use the contract day in, day out. They are in direct contact with all major stakeholders. Let us elaborate; A contract is collection of work following standards and specification of a Client, delivered as per commercial and contractual Terms and Conditions.</p>
                                                        <p>Typically, a small team of 2-3 people are directly responsible for the delivery and day to day management of a contract: (1) Contract Holder/ Project Manager, (2) Contract Specialist/ Contract Engineer and (3) Contract Owner/ Budget Owner/Division Head. They are known as Contract Management Team (CMT). The composition and job title may slightly differ from Client to Client. But the principle is the same.</p>
                                                        <p>When it comes to execution, the Contract becomes part of an Eco System of other Contracts, departments, and processes. As many as 7-10 stakeholders from various department may use and influence the contract on a regular basis. These 7-10 stakeholders + the initial 3, formulates what <span className="theme-font">powerabode</span> call an Extended Contract Management Team (ECMT).</p>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>ECMT is on the top of the list when it comes to influencing cost. They create demand, coordinate, synchronise work and plan for optimised execution which drives the Contracts in many ways. Each stakeholder has their own performance criteria, scorecards, and processes. The practical challenges of frontline execution introduce inefficiencies. An initial estimate of a ‘work’ on contract will differ quite a bit after it becomes part of the Eco System. ECMT is the key to unlocking sustainable Cost Efficiency.</p>
                                                        <p>Despite digitisation, a significant share of information from the frontline remains compartmentalised (‘out of reach’). This uncaptured and unmonetized information is generated by members of the ECMT throughout the length of Supply Chain. It starts from ‘identifying demand & need’ and ends with ‘commitment, reconcile & close out’. It is the end to end Integrated Supply Chain (ISC); and members of ECMT occupy various links of this chain.</p>
                                                        <p>Our uniquely designed Integrated Supply Chain (ISC) training program is exemplary in this space. The target group is the ECMT and their role in the ISC. The Blended Learning course boosts awareness of how members of ECMT influence cost, why, and how to get Cost Efficiency. This training is designed to foster curiosity and commercial competence so that the members of the ECMT can identify savings opportunities, own them, implement, and sustain them throughout the Integrated Supply Chain. After all, it is the ECMT members who own and operate the full chain.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100 mt-5">
                                                <div className="section-header mb-5">
                                                    <h1 className="section-title-two text-center">
                                                        Visit these services for more detail:
                                                    </h1>
                                                </div>
                                                <div className="d-flex justify-content-center gap-3">
                                                    <ButtonCircle
                                                        url="/systems-delivery/contractmanagementtool"
                                                        DynamicBtnColor="btn-color-5 mb-3"
                                                        image={logo5}
                                                    />
                                                    <ButtonCircle
                                                        url="/cost-value-philosophy"
                                                        DynamicBtnColor="btn-color-1 mb-3"
                                                        image={logo1}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />
                
                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles1;