import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./button.css"
function ButtonCircle({ num, titleFirst, titleSecond, url, image, DynamicBtnColor, slaLogoStatus }) {
    return (
        <div className={`theme-circle-btn ${DynamicBtnColor} button-height-after-190  ${slaLogoStatus === 'true' ? 'sla-signature' : null}`}>
            <Link to={url ? url : "/"}>
                {num ? <h3 className="text-theme-blue">{num}</h3> : null}
                {titleFirst ? <p className="screen-text text-theme-blue font-weight-500">{titleFirst} <br /> {titleSecond}</p> : null}
                {image ?
                    <Image
                        className="w-100 h-auto"
                        src={image}
                    />
                : null}
            </Link>
        </div>
    );
}

export default ButtonCircle;