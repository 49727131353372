import React from 'react';
import { useEffect } from "react";
import { Image } from 'react-bootstrap';
import HeroSection from '../../Components/Sections/HeroSection';
import SupplyChainSlider from '../../Components/Sections/SupplyChainSlider';
import CoverPhoto1 from "../../Images/Supply-chain-sla/Supply-Chain-by-SLA-Lower.webp";
import ResultBg from "../../Images/banner/sector/sector-2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import Background from "../../Images/sla/3.webp";

// case study assets
import "../../Components/Style/case-studies.css";
import CaseStudiesPhoto1 from "../../Images/articles/single/2.1.jpeg";
import CaseStudiesPhoto2 from "../../Images/articles/single/3.1.jpeg";
import CaseStudiesPhoto3 from "../../Images/articles/single/1.1.jpeg";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

function PostAward() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The Post Award Contract Management – Power Abode</title>
                <meta
                    name="description"
                    content="There are many facts in the post-award section. It’s supportive to achieve ECMT with ISC skillsets, cost efficiency, bank savings, and more."
                />;
            </Helmet>
            <div className="bg-white">
                <HeroSection
                    className="post-award-bg"
                    pageTitleValue="Post Award Contract Management"
                    boxTitleValue="Contract Management; "
                    boxTitleValue2=" high impact area for delivery"
                    boxDesValue="Underrated and little loved. But delivery is heavily impacted by Post-Award Contract Management. Being on top of it. Discipline. Also understanding the scope and the performance drivers. Working with all stakeholders. The powerabode program for PACM brings a solid contribution toward Cost Leadership."
                    title="Post-award-contract-management. Outsourcing / TCO and TVO program and analyses | Can Hands on frontline operational experience prevents cost and value loss during contract delivery stage?"
                />

                <div className="section sla-service-wrapper overflow-hidden d-none">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-6 pr-0">
                                            <div className="sla-img-l-box position-relative">
                                                <Image
                                                    style={{ maxHeight: "500px", objectFit: "cover" }}
                                                    width="100%" height="auto"
                                                    src={ResultBg}
                                                    alt=''
                                                />
                                                <div className="sla-img-absolute-txt">
                                                    <h1 className='section-title-two text-white'>
                                                        The Result
                                                    </h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 pl-0">
                                            <div className="sla-txt-r-box bg-dark-400 p-4 h-100 d-flex justify-content-center align-content-center flex-column">
                                                <ul className="check-icon-list">
                                                    <li className="list-item text-white d-flex items-center">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Highly effective ECMT with ISC skillsets; delivering cost efficiency</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Bespoke tools to drive contract performance and bank savings</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Cost Intelligence delivers savings from efficiency/specification/rates</span>
                                                    </li>
                                                    <li className="list-item text-white d-flex items-center mt-4">
                                                        <FontAwesomeIcon icon={faCircleCheck} />
                                                        <span>Interface manager guarantees cost efficiency</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-about-wrapper mt-5 post-award-sla-sig">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div 
                                    className="sla-content-wrap" 
                                    style={{ background: `url("${Background}")` }}
                                    title="Can Cost Efficiency be delivered through Contract Management?"
                                >
                                    <div className="sla-cw-text">
                                        <h1 className='section-title-two text-white'>
                                            The Result
                                        </h1>
                                        <ul className="check-icon-list mt-3">
                                            <li className="list-item text-white d-flex items-center">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Highly effective ECMT with ISC skillsets; delivering cost efficiency</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Bespoke tools to drive contract performance and bank savings</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Cost Intelligence delivers savings from efficiency/specification/rates</span>
                                            </li>
                                            <li className="list-item text-white d-flex items-center mt-3">
                                                <FontAwesomeIcon icon={faCircleCheck} />
                                                <span>Interface manager guarantees cost efficiency</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="sla-cw-footer-text">
                                        <h1 className="section-title-two text-white">
                                            Post Award Contract Management
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sla-service-wrapper mt-5 position-relative overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative ">
                                    <h3 className="section-sub-title mt-1 text-right">
                                        <span className="text-color-theme ">
                                            High impact area for delivery
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <h1 className="section-title-two">
                                                Our <span className="text-color-primary"> Scope :</span>
                                            </h1>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Interface Manager</h5>
                                                        <p className='mt-3'>
                                                            Brings delivery in sync with planning.
                                                            Teams change, but is the intend still
                                                            clear? What is the delivery strategy? Our program aligns and connects the
                                                            stakeholders. It is a key enabler for
                                                            contract performance.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Output Analyses</h5>
                                                        <p className='mt-3'>
                                                            Did you get what you intended? We map the performance against plan.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250" style={{ marginTop: '0' }}>
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">ISC Training</h5>
                                                        <p className='mt-3'>
                                                            the Integrated Supply Chain training. The Extended Contract Management Team (ECMT) will participate. They will deliver!
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title text-right">
                                        <span className="text-color-theme ">
                                            Did you ever check if there are conflicting KPI's?
                                        </span>
                                    </h3>
                                </div>
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Frontline experts</h5>
                                                        <p className='mt-3'>
                                                            Mileage. Decades of field experience in using, influencing and managing
                                                            contracts. In difficult environments. With top companies.
                                                            Our focus is on delivery, not on anything else
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">Set up & improve PACM</h5>
                                                        <p className='mt-3'>
                                                            Translation from the tender stage to the contract delivery. We set up and
                                                            implement your Contract Management Plan, KPIs, Cost, commitment & Budget
                                                            Control, dashboards, performance
                                                            reviews, Assurance & Audit preparation
                                                            and execution.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-sm-6 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap min-h-250">
                                                    <div className="content text-left text-center">
                                                        <h5 className="heading">TCO/TVO program</h5>
                                                        <p className='mt-3'>
                                                            Complex. Valuable. Necessary. It provides you insight. Applied properly during Post Award stage, significant value can be developed. Perfect tools to improve delivery and commercial performance during Post Award stage.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="section-header position-relative mt-5">
                                    <h3 className="section-sub-title  text-right">
                                        <span className="text-color-theme ">
                                            'What gets measured, gets managed' <br />
                                            <b>-Peter Drucker</b>
                                        </span>
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <SupplyChainSlider />

                <div className="container-fluid mt-5 position-relative">
                    <Image
                        width="100%" height="auto"
                        src={CoverPhoto1}
                        alt=""
                    />
                    <div className="box-txt-top-left-absolute">
                        <h1 className='section-title-two'>
                            Related Articles and Case Studies
                        </h1>
                    </div>
                </div>

                <div className="case-studies-AcademyWrapper position-relative">
                    <div className="case-studies-section mt-5">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="section-title">
                                        <h1 className="section-title-two">
                                            Top <span className="text-color-theme">Articles </span>
                                            &
                                            <span className="text-color-theme"> Case Studies</span>
                                        </h1>
                                    </div>
                                    <div className="section-content mt-4">
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-2"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto1}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Conflict or Conflicting KPI’s?</h5>
                                                            <p className="text-white">Working as a team. That’s what we do. But how do we know we are all aligned? Conflicting KPI’s cause massive inefficiencies. Who needs enemies if you have friends? <span className="theme-font">powerabode</span> can...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-3"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Deliver as Planned</h5>
                                                            <p className="text-white">Sometimes ‘surprises’ are actually predictable. The top 5 of ‘predictable’ surprises...</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="animation-box-wrap">
                                                    <Link
                                                        to="/articles-business-cases/articles-1"
                                                        className="w-100"
                                                    >
                                                        <div className="abw-img">
                                                            <Image
                                                                width="100%"
                                                                src={CaseStudiesPhoto3}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className="abw-text p-3">
                                                            <h5 className="text-white heading">Hidden in Plain sight </h5>
                                                            <p className="text-white">Crazy as it may sound; there’s always an elephant in the room.. Central in our way of work are the users of the contract, the ECMT. They are underestimated as a platform. 9 Quote: Our Experts know your business.</p>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default PostAward;