import "../Style/request-for-rfq.css";

function RequestForRfa() {
    return (
        <div className="request-rfa-wrapper pt-5 position-relative">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="cfw-wrap">
                            <div className="section-header position-relative">
                                <h1 className="section-title-two">“Request for RFQ"</h1>
                                <h3 className="section-sub-title mt-1">
                                    <span className="text-color-theme" style={{ wordBreak: 'break-all' }}>Submit a Request For Information/Proposal/Quotation.</span>
                                </h3>
                                <h6 className="section-sub-title-two">We’ll provide detailed information about how <span className="theme-font">powerabode</span> can be of help:</h6>
                            </div>

                            <div className="section-content-wrap mt-3">
                                <ul className="circle-list">
                                    <li className="list-item">Streamline and optimise your contracting and procurement</li>
                                    <li className="list-item">Reduce cost and Improve value</li>
                                    <li className="list-item">Third party cost reduction presentation</li>
                                    <li className="list-item">Get introduced to the Academy skillset trainings</li>
                                    <li className="list-item">Manage all your contracts with fine-grained control</li>
                                    <li className="list-item">Outsource some , or all of your Supply Chain and Procurement activities.</li>
                                    <li className="list-item">Introduce governance and systems fit for best practice delivery</li>
                                </ul>
                            </div>
                        </div>
                        <h3 className="section-sub-title mt-3 text-center">
                            Cost Efficiency Experts.
                            <span className="text-color-theme"> Driven By Energy.</span>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RequestForRfa;