import { Image } from "react-bootstrap";
import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import photo from "../../Images/articles/MaterialsManagement.webp";
import logo4 from "../../Images/Bg/layouts1/cms.png";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles9() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Is the Material Management an Ugly Ducking in the Supply Chain Sector? - Powerabode</title>
                <meta
                    name="description"
                    content="As an asset gets older, how to keep performing? Increased pressure on maintenance, legacy parts."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles9-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Materials Management: A showstopper or Money Maker?"
                    boxTitleLabel="Article >"
                    title="Materials-Management"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Materials Management: perceived as the ugly duckling of the Supply Chain family; is it really?
                                    </h1>
                                    
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5">
                                                <Image
                                                    height="150px" width="auto"
                                                    src={photo}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                The right goods at the right place at the right time; today, tomorrow and 5 years from now. That is the ultimate outcome of reliable Materials Management.
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">

                                                        <p>Materials Management is perhaps the ugly duckling of the Supply Chain family. Why? Because it is hands on, detailed and requires precision. It takes focus, continuous update, discipline and optimisation. It demands a multi-disciplinary relationship and deep connect with the frontline. Gratitude, or appreciation does not come with a job well done. But you will know when it goes wrong.</p>
                                                        <h5 className="heading">
                                                            Non-performance is likely to cause:
                                                        </h5>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">Delayed repairs</li>
                                                            <li className="list-item">Prolonged shutdown and turnarounds </li>
                                                            <li className="list-item">Deferred production</li>
                                                            <li className="list-item">Equipment damage</li>
                                                            <li className="list-item">Frozen cash and working capital</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <p>Therefore, start by getting the basics right. Ensure that the ‘Red and Blue’ schematics are up to date. The entire flow of material throughout the organisation depends on this humble starting point. Follow that with inventory analysis and planning assessment. This is the discovery point where a substantial data clean-up will be required.</p>
                                                        <p>Ensure that coding systems are clear and understood – police and check new Material Codes and ensure suppliers include the required codes on their deliveries. Periodically check compliance.</p>
                                                        <p>We have seen major IOCs with 4 -10% BOM (bill of materials) coverage and sitting with millions US$ of non-moving stock. Improvement on BOM must be an ambition on its own.</p>
                                                        <p>Over time, as assets do their mileage, legacy parts ‘disappear’, modifications happen, suppliers change and corporate memory fades. Therefore, one can only rely on an effective MM system and structure that is kept alive and prioritised. Our <span className="theme-font">powerabode</span> 7-step complete Materials Management program offers a structured approach for ensuring the basics are working and managing your Materials.</p>
                                                        <p>Poor Material Management is not just something for the warehouse, it can become a show-stopper in Business Improvement. Legacy and poorly defined stock items clog the arteries of business. Shorter turnaround, business continuity, lower operating cost result from good control on inventory and material planning. That is real impact on production and result.</p>
                                                        <p>Building a BOM catalogue, establishing guidelines, systems, processes and procedures, conducting Inventory Optimisation, rejuvenating entire Materials Management; This is what we do. And we do this for a reason</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="w-100 mt-5">
                                        <h3 className="section-sub-title mt-1 text-center">
                                            This is what we do. And we do this for a reason
                                        </h3>
                                        <div className="d-flex justify-content-center gap-3 mt-4">
                                            <ButtonCircle
                                                url="/systems-delivery/codex-supplychainmanagmentsystem"
                                                DynamicBtnColor="btn-color-6 mb-3"
                                                image={logo4}
                                            />
                                            <ButtonCircle
                                                url="/materials-management"
                                                titleFirst="Material Management"
                                                DynamicBtnColor="btn-color-3 mb-3"
                                                slaLogoStatus="true"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles9;