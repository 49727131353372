import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import { Image } from "react-bootstrap";
import Photo from "../../Images/articles/Deliverdespitebudgetconstraints.png";
import Photo1 from "../../Images/articles/DeliverdespiteTable.png";
import Photo2 from "../../Images/articles/evi.png";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles4() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>The Waste of Every Supply Chain & How Do We Fix it with Solutions? - Powerabode</title>
                <meta
                    name="description"
                    content="Every supply chain has waste, we fix it. Complex analyses using our CI Philosophy, double digit improvement to your budget and opex, value optimisation..."
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles4-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Deliver despite budget constraints "
                    boxTitleLabel="Article >"
                    title="budget-constraints-delivery Gap analyses"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Every Supply Chain has waste. We fix it!
                                    </h1>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="mx-auto w-100 text-center my-5 ">
                                                <Image
                                                    className="h-sm-auto"
                                                    height="150px" width="100%"
                                                    src={Photo}
                                                    alt=""
                                                />
                                            </div>
                                            <h3 className="section-sub-title mt-1 text-center">
                                                Find the gap!
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            Enhanced Value Improvement
                                                        </h5>
                                                        <p>Our method of evi is the result of business understanding, accumulated field experience, the involvement of stakeholders through the ECMT, a deep dive in data and in the end, a lot of common sense. It has delivered double digit savings every time. The full version of evi can be embedded in your organisation through the Cost Leadership Program; ‘The Road to Cost Leadership’. Our program provides context with training, assesses your full portfolio and through building savings business cases also delivers implementation through our ‘One Stop Shop’ services to your bottom line.</p>
                                                        <p>In this case study we just show the element of evi. The fundamental point is this; Every Supply Chain has Waste. We Fix It.</p>
                                                        <h5 className="heading">
                                                            The Brief
                                                        </h5>
                                                        <p>Due to budget constraints, the Client wanted to assess a contract category to find savings.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <div className="mx-auto w-100 text-center mb-4">
                                                            <Image
                                                                height="auto" width="50%"
                                                                src={Photo2}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <h5 className="heading">
                                                            The Solution
                                                        </h5>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">Apply our evi program</li>
                                                            <li className="list-item">20 in-depth analysis submitted; each one is a step on the road to Cost Leadership.</li>
                                                            <li className="list-item">Extensive mind-map method applied to assess the delivery process of the contract vs intent.</li>
                                                            <li className="list-item">Advanced modelling tool shaped the gap to potential.</li>
                                                            <li className="list-item">Stakeholder alignment sought through scenario selection.</li>
                                                            <li className="list-item">Compounded overview of Value and Savings submitted through business case.</li>
                                                            <li className="list-item">Stakeholder alignment sought through scenario selection. The Result in Savings</li>
                                                            <li className="list-item">Purchase order analysis: 22%</li>
                                                            <li className="list-item">Non productive time: 17%</li>
                                                            <li className="list-item">Time gap analysis using technical boundary: 36% graterthen  Stock, storage & transportation: 15%</li>
                                                            <li className="list-item">Rates and benchmark: 10%</li>
                                                        </ul>
                                                        <div className="mx-auto w-100 text-center">
                                                            <Image
                                                                height="auto" width="50%"
                                                                src={Photo1}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles4;