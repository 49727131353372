// import ButtonCircle from "./ButtonCircle";
import "../Style/commercial-ecosystem.css";
import logo1 from "../../Images/Bg/layouts1/CVI-Logo.png";
import logo2 from "../../Images/Bg/layouts1/supplychain.png";
import logo3 from "../../Images/Bg/layouts1/opex_capex.png";
import logo4 from "../../Images/Bg/layouts1/cms.png";
import logo5 from "../../Images/Bg/layouts1/cm+.png";
import logo6 from "../../Images/Bg/layouts1/academy.png";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";

function InfographOne(props) {
    return (
        <>
            <div className="content mt-5 commercial-wrapper-desktop d-none d-md-block">
                <div className="row">
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="box-circle-animation-wrap position-relative">
                            <div className="box-caw-img position-relative">
                                <Link to="/cost-value-philosophy">
                                    <div className="box-img">
                                        <Image
                                            className="clicker w-100 h-auto"
                                            src={logo1}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <div className="circle color-5"></div>
                            </div>
                            <div className="box-caw-text mt-5 text-center">
                                <h1 className="section-title-two text-center my-3">
                                    Cost and Value Intelligence
                                </h1>
                                <h3 className="section-sub-title text-justify mt-1">
                                    Cost and Value Philosophy. A unique way of understanding the cost, potential savings and value optimisation. CVI recognises various cost categories and links them to specific contract users. Creating synergy between these users, optimises the delivery of saving potential.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="box-circle-animation-wrap position-relative">
                            <div className="box-caw-img position-relative">
                                <Link to="/supply-chain-by-sla">
                                    <div className="box-img p-1">
                                        <Image
                                            className="clicker w-100 h-auto"
                                            src={logo2}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <div className="circle color-3"></div>
                            </div>
                            <div className="box-caw-text mt-5 text-center">
                                <h1 className="section-title-two text-center my-3">
                                    Supply chain by SLA
                                </h1>
                                <h3 className="section-sub-title text-justify mt-1">
                                    Supply Chain by SLA. Top class experts and services to deliver your Supply
                                    Chain Challenges. Optimising cost and improving delivery.
                                </h3>
                                <ul className="circle-list text-left info-ul-style">
                                    <li className="list-item">Category & Contract Strategy</li>
                                    <li className="list-item">Tendering & Outsourcing</li>
                                    <li className="list-item">Post Award Contract</li>
                                    <li className="list-item">Management</li>
                                    <li className="list-item">Materials Management</li>
                                    <li className="list-item">Commercial Assurance</li>
                                    <li className="list-item">Cost Recovery</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="box-circle-animation-wrap position-relative">
                            <div className="box-caw-img position-relative">
                                <Link to="/capex-opex-cost-program" >
                                    <div className="box-img p-1">
                                        <Image
                                            className="clicker w-100 h-auto"
                                            src={logo3}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <div className="circle color-4"></div>
                            </div>
                            <div className="box-caw-text mt-5 text-center">
                                <h1 className="section-title-two text-center my-3">
                                    OPEX/CAPEX Program
                                </h1>
                                <h3 className="section-sub-title text-justify mt-1">
                                    Improving cost and value by double digits. Award winning team. This
                                    unique program delivers you double digit cost reduction. Disruptive and
                                    controlled delivery by three tier program.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="box-circle-animation-wrap position-relative">
                            <div className="box-caw-img position-relative">
                                <Link to="/systems-delivery/codex-supplychainmanagmentsystem">
                                    <div className="box-img">
                                        <Image
                                            className="clicker w-100 h-auto"
                                            src={logo4}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <div className="circle color-1"></div>
                            </div>
                            <div className="box-caw-text mt-5 text-center">
                                <h1 className="section-title-two text-center my-3">
                                    Codex
                                </h1>
                                <h3 className="section-sub-title text-justify mt-1">
                                    Management System, complete and off the shelf, including
                                    implementation and training. No development risk, adapted to your
                                    organisation. For
                                </h3>
                                <ul className="circle-list text-left info-ul-style">
                                    <li className="list-item">Contracting & Procurement</li>
                                    <li className="list-item">Pre-award</li>
                                    <li className="list-item">Post-award</li>
                                    <li className="list-item">Materials Management</li>
                                    <li className="list-item">Commercial Assurance</li>
                                    <li className="list-item">Cost Recovery</li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="box-circle-animation-wrap position-relative">
                            <div className="box-caw-img position-relative">
                                <Link to="/systems-delivery/contractmanagementtool">
                                    <div className="box-img">
                                        <Image
                                            className="clicker w-100 h-auto"
                                            src={logo5}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <div className="circle color-2"></div>
                            </div>
                            <div className="box-caw-text mt-5 text-center">
                                <h1 className="section-title-two text-center my-3">
                                    CM+
                                </h1>
                                <h3 className="section-sub-title text-justify mt-1">
                                    Contract + Context. People don’t read the contract. Still, they are expected
                                    to deliver. This solution enables all contract users to be first time right.
                                </h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-6 col-md-12">
                        <div className="box-circle-animation-wrap position-relative">
                            <div className="box-caw-img position-relative">
                                <Link to="/systems-delivery/academy" >
                                    <div className="box-img">
                                        <Image
                                            className="clicker w-100 h-auto"
                                            src={logo6}
                                            alt=""
                                        />
                                    </div>
                                </Link>
                                <div className="circle color-6"></div>
                            </div>
                            <div className="box-caw-text mt-5 text-center">
                                <h1 className="section-title-two text-center my-3">
                                    Academy
                                </h1>
                                <h3 className="section-sub-title text-justify mt-1">
                                    8 specific Coaching and trainings, focussed on developing commercial
                                    skillset. Integrated Supply Chain (ISC) training for teams of contract users.
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="content my-5 d-block d-md-none">
                <div className="row">
                    <div className="col-md-12">
                        <div className="cel-wrapper cel-slider-wrapper d-flex flex-wrap">
                            <Swiper
                                slidesPerView={'auto'}
                                spaceBetween={0}
                                centeredSlides={true}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={false}
                                autoplay={{
                                    delay: 2500,
                                    disableOnInteraction: false,
                                }}
                                modules={[Autoplay, Pagination, Navigation]}
                                className="mySwiper"
                            >
                                <SwiperSlide >
                                    <div className="cel-box-wrap cel-bg-1">
                                        <div className="theme-circle-btn">
                                            <Link to="">
                                                <Image
                                                    className="w-auto h-50"
                                                    src={logo1}
                                                />
                                            </Link>
                                        </div>

                                        <div className="cel-text-wrap">
                                            <h5>
                                                Cost intelligence
                                            </h5>
                                            <p>
                                                Cost Intelligence Philosophy. A unique way of understanding cost, the potential savings
                                                and optimisation. CI recognises different cost categories and links them to specific
                                                contract users. Creating synergy between these users, optimises the delivery of the
                                                savings potential.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className="cel-box-wrap cel-bg-2">
                                        <div className="theme-circle-btn">
                                            <Link to="">
                                                <Image
                                                    className="w-100 h-auto"
                                                    src={logo2}
                                                />
                                            </Link>
                                        </div>

                                        <div className="cel-text-wrap">
                                            <h5>
                                                Supply chain by SLA
                                            </h5>
                                            <p>
                                                Supply Chain by SLA. Top class experts and services to deliver your Supply Chain
                                                Challenges. Optimising cost and improving delivery. Category and Contract Strategy,
                                                Tendering & Outsourcing, Post Award Contract Management, Materials Management,
                                                Commercial Assurance and Cost Recovery.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide >
                                    <div className="cel-box-wrap cel-bg-3">
                                        <div className="theme-circle-btn">
                                            <Link to="">
                                                <Image
                                                    className="w-100 h-auto"
                                                    src={logo3}
                                                />
                                            </Link>
                                        </div>

                                        <div className="cel-text-wrap">
                                            <h5>
                                                Savings program
                                            </h5>
                                            <p>
                                                Improving cost and value by double digits. Award winning team. This unique program
                                                delivers you double digit cost reduction. Disruptive and controlled delivery by three
                                                tier program.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className="cel-box-wrap cel-bg-4">
                                        <div className="theme-circle-btn">
                                            <Link to="">
                                                <Image
                                                    className="w-100 h-auto"
                                                    src={logo4}
                                                />
                                            </Link>
                                        </div>

                                        <div className="cel-text-wrap">
                                            <h5>
                                                Codex
                                            </h5>
                                            <p>
                                                Management System, complete and off the shelf, including implementation and
                                                training. No development risk, adapted to your organisation. For Contracting &
                                                Procurement, Pre-award, Post-award, Materials Management, Commercial Assurance
                                                and Cost Recovery.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className="cel-box-wrap cel-bg-5">
                                        <div className="theme-circle-btn">
                                            <Link to="">
                                                <Image
                                                    className="w-100 h-auto"
                                                    src={logo5}
                                                />
                                            </Link>
                                        </div>

                                        <div className="cel-text-wrap">
                                            <h5>
                                                CM+
                                            </h5>
                                            <p>
                                                Contract + Context. People don’t read the contract. Still, they are expected to deliver.
                                                This solution enables all contract users to be first time right.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide >
                                    <div className="cel-box-wrap cel-bg-6">
                                        <div className="theme-circle-btn">
                                            <Link to="">
                                                <Image
                                                    className="w-100 h-auto"
                                                    src={logo6}
                                                />
                                            </Link>
                                        </div>

                                        <div className="cel-text-wrap">
                                            <h5>
                                                Academy
                                            </h5>
                                            <p>
                                                8 specific Coaching and trainings, focussed on developing commercial skillset.
                                                Integrated Supply Chain (ISC) training for teams of contract users.
                                            </p>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
}

export default InfographOne;