import HeroSectionSm from "../../Components/Sections/HeroSection-sm";
import HorizontalAccordion from "../../Components/Sections/HorizontalAccordion";
import RelatedArticlesSlider from "../../Components/Sections/RelatedArticlesSlider";
import ButtonCircle from "../../Components/Widget/ButtonCircle";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function Articles6() {
    useEffect(() => {
        window.scrollTo(0, 0)
    });
    return (
        <>
            <Helmet>
                <title>Most Powerful Strategies of Contract & Sourcing to Reduce cost - Powerabode</title>
                <meta
                    name="description"
                    content="Strategy and sourcing define the outcome. We advice and built your delivery model, including the tendering model…"
                />
            </Helmet>
            <div className="bg-white">
                <HeroSectionSm
                    className="articles6-hero-bg overflow-hidden"
                    pageTitleValue="Articles"
                    boxTitleValue="Drilling Cost Transformation "
                    boxTitleLabel="Article >"
                    title="discount-price-reduction"
                />

                <div className="articles-about-wrap mt-5 overflow-hidden">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="section-header position-relative">
                                    <h1 className="section-title-two text-center">
                                        Specialised Contract Strategy and sourcing approach can reduce your well cost by 50%
                                    </h1>
                                </div>
                                <div className="section-wrap">
                                    <div className="row">
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <br />
                                            <h3 className="section-sub-title mt-5 text-center">
                                                We were asked to deliver a contract strategy and sourcing approach that would support reducing well costs by 50%
                                            </h3>

                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            The Brief Services Program Academy
                                                        </h5>
                                                        <p>With low oil price the cost of drilling and workover had become unaffordable at a major onshore field in this region. Rates in the market had fallen but they needed to drastically reduce well delivery time and ensure quality to expand production by 35% per day.</p>
                                                        <h5 className="heading">
                                                            The Solution
                                                        </h5>
                                                        <p>Through Strategic dialogue various options were looked at; extending, retendering, move to day-rate, de-bundling services and rig, and splitting out the hoist/coiled tubing spread.</p>
                                                        <p>Production drilling was still the bulk of the cost. Therefore it was decided to have an evaluation model that built in all the benefits of drilling time, to assess technical expertise in depth and to should-cost model the wells in detail to set an aggressive but achievable cost target.</p>
                                                        <p>Strategy was agreed to split up the existing scope in two separate tenders; (a) production drilling, (b) hoist/ coiled tubing. Two separate remuneration models were chosen to drive performance of these two contracts. Only a single element in both remuneration model stimulated both contractors to drive each others performance. If one succeeded, both succeeded and if one failed, both failed.</p>
                                                        <p>The bids came in and none of the tenderers met the aspired cost level. Therefore approval was sought to negotiate with the leading bidder with the best TCO (total cost of ownership) and TVO (total value of ownership) in an “open book” manner. Target was reduce inefficiencies in the supply chain and to reach the aspired drilling performance; rate was not important.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-sm-12 col-12">
                                            <div className="ht-box-icon">
                                                <div className="icon-box-wrap">
                                                    <div className="content text-left">
                                                        <h5 className="heading">
                                                            Outcome Of The Negotiation
                                                        </h5>
                                                        <p>The aspired cost level was met through a combination of factors:</p>
                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">Eliminating waste in the supply chain – through better planning and revised areas of Company and Contractor responsibility. i.e. joint planning of rig moves, and better provision of subsurface planning data.</li>
                                                            <li className="list-item"> Building in the learning curve – through agreeing aggressive well delivery times and agreeing how operational issues would be handled, in advance, and through a joint commitment to trial and introduce new technology.</li>
                                                            <li className="list-item">Goal alignment and joint well reviews with the ECMT ensuring that the goal of fast, safe delivery was visible and shared at the rig-site and in the support offices.</li>
                                                            <li className="list-item">Clear Senior leadership sponsorship and reward to rig crew and engineers.</li>
                                                        </ul>

                                                        <h5 className="heading">
                                                            The Result
                                                        </h5>

                                                        <ul className="circle-list mb-3">
                                                            <li className="list-item">Cost impact was in excess of 60%, with a 50% faster delivery.</li>
                                                            <li className="list-item">Non productive time drop substantially.</li>
                                                            <li className="list-item">Drilling time reduced significantly.</li>
                                                            <li className="list-item"> Cementing and well completion performance had a steep learning curve. Started to deliver perfect completion from the 3rd well.</li>
                                                        </ul>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="w-100 mt-5">
                                                <div className="section-header mb-5">
                                                    <h1 className="section-title-two text-center">
                                                        Visit these services for more detail:
                                                    </h1>
                                                </div>
                                                <div className="d-flex justify-content-center gap-3">
                                                    <ButtonCircle
                                                        url="/tendering-outsourcing"
                                                        titleFirst="Tendering & Outsourcing"
                                                        DynamicBtnColor="btn-color-3 mb-3"
                                                        slaLogoStatus="true"
                                                    />
                                                    <ButtonCircle
                                                        url="/category-strategy"
                                                        titleFirst="Contract and Category Strategy"
                                                        DynamicBtnColor="btn-color-3 mb-3"
                                                        slaLogoStatus="true"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <RelatedArticlesSlider />

                <HorizontalAccordion
                    className="overflow-hidden"
                />
            </div>
        </>
    );
}

export default Articles6;